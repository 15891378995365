import { useCallback } from "react";
import { GContext, IStoreState } from "../utils/store";
import { API_ACTION, IAPIAction } from "./store";

export interface BackendResponse<T> {
  data?: T;
  error?: string;
  message?: string;
  cursor?: string;
}

export const ERROR_SERVER_FAILURE = "InternalServerError";
const serverFailureMessage =
  "there was a problem with the server. Please try again later";

// const USE_BACKEND = process.env.REACT_APP_USE_BACKEND !== undefined
const host: string =
  process.env.REACT_APP_BACKEND_HOST || "http://localhost:8080";
const version = "v5";

export const generateApiUrl = (endpoint: string) =>
  `${host}/${version}/${endpoint}`;

export const apiFetch: (
  url: string,
  payload: FormData | string,
) => Promise<Response> = async (url: string, payload: FormData | string) => {
  const formattedUrl = `${host}/${version}/${url}`;
  return await fetch(formattedUrl, {
    method: "POST",
    body: payload,
    credentials: "include",
  });
};

export const useAPI: <A, B>(
  url: string,
  fallbackData: B,
  useStore?: () => GContext<IStoreState>,
  dataHook?: (d: B) => void,
) => [
  query: (a: A) => Promise<BackendResponse<B>>,
  state: B,
  dispatch: (b: B) => void,
] = (url, fallbackData, useStore, dataHook) => {
  let state: IStoreState = {};
  let dispatch: (b: IAPIAction) => void;
  if (useStore) {
    [state, dispatch] = useStore();
  }
  if (state[url] === undefined) {
    state[url] = fallbackData;
  }
  return [
    useCallback(async (a) => {
      try {
        const resp = await apiFetch(url, JSON.stringify(a));
        const j = await resp.json();
        let data: any;
        if (!j.error) {
          if (j.data) {
            data = j.data;
          } else {
            data = j;
          }
        }
        if (!data && fallbackData != undefined) {
          data = fallbackData;
        }
        if (dataHook) {
          dataHook(data);
        }
        if (dispatch) {
          dispatch({ type: API_ACTION.SET_DATA, url, data });
        }
        return j;
      } catch (e) {
        return {
          error: ERROR_SERVER_FAILURE,
          message: serverFailureMessage,
        } as BackendResponse<void>;
      }
    }, []),
    state[url] as typeof fallbackData,
    (data) => dispatch({ type: "API", url, data }),
  ];
};
