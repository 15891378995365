const OutstationActionTypes = {
  GET_OUTSTATION_OPTIONS: "GET_OUTSTATION_OPTIONS",
  GET_OUTSTATION_OPTIONS_SUCCESS: "GET_OUTSTATION_OPTIONS_SUCCESS",
  GET_OUTSTATION_OPTIONS_FAILURE: "GET_OUTSTATION_OPTIONS_FAILURE",
  SUBMIT_OUTSTATION: "SUBMIT_OUTSTATION",
  SUBMIT_OUTSTATION_SUCCESS: "SUBMIT_OUTSTATION_SUCCESS",
  SUBMIT_OUTSTATION_FAILURE: "SUBMIT_OUTSTATION_FAILURE",
};

export default OutstationActionTypes;
