import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  AnchorButton,
  Button,
  Dialog,
  Icon,
  Intent,
  Slider,
  Spinner,
} from "@blueprintjs/core";
import { Link, useHistory } from "react-router-dom";

import { PageComponent } from "../../lib";
import "./styles.scss";
import {
  usePayrollEmployeePayslipsReadAllQuery,
  useCompanyEmployeeRetrieveQuery,
} from "../../../../backend/app";
import { useLogin } from "../../../../data/login/api";
import { rupiahFormatter } from "../../../../utils/formatters";

export const Payslip: PageComponent = () => {
  const [, loginAuth] = useLogin();
  const { data: companyProfileData, error: companyProfileError } =
    useCompanyEmployeeRetrieveQuery({
      variables: {
        userID: loginAuth.userID,
        companyID: loginAuth.companyID,
      },
    });
  const { data: slipList, loading: isLoading } =
    usePayrollEmployeePayslipsReadAllQuery({
      variables: {
        companyID: companyProfileData?.CompanyEmployeeRetrieve.companyID || "",
        memberID: companyProfileData?.CompanyEmployeeRetrieve.memberID || "",
      },
      fetchPolicy: "no-cache",
    });
  const [dateRange, setDateRange] = useState<any>();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [dataView, setDataView] = useState<any[]>([]);

  useEffect(() => {
    setDataView(slipList?.PayrollEmployeePayslipsReadAll ?? []);
  }, [slipList, isLoading, dateRange]);

  const history = useHistory();

  const handleRangeChange = (e: any) => {
    setDateRange((item: any) => ({
      ...item,
      [e.target.name]: e.target.value,
    }));
  };

  const submitFilter = () => {
    const data = dataView.filter((item) => {
      const rangeStart = Date.parse(dateRange?.start_date);
      const rangeEnd = Date.parse(dateRange?.end_date);
      return (
        (Date.parse(item.startTime.split("T")[0]) >= rangeStart &&
          Date.parse(item.startTime.split("T")[0]) <= rangeEnd) ||
        (Date.parse(item.startTime.split("T")[0]) <= rangeStart &&
          Date.parse(item.startTime.split("T")[0]) >= rangeEnd) ||
        (Date.parse(item.startTime.split("T")[0]) >= rangeStart &&
          Date.parse(item.endTime.split("T")[0]) <= rangeEnd) ||
        (Date.parse(item.startTime.split("T")[0]) <= rangeStart &&
          Date.parse(item.endTime.split("T")[0]) >= rangeEnd) ||
        (Date.parse(item.endTime.split("T")[0]) >= rangeStart &&
          Date.parse(item.endTime.split("T")[0]) <= rangeEnd) ||
        (Date.parse(item.endTime.split("T")[0]) <= rangeStart &&
          Date.parse(item.endTime.split("T")[0]) >= rangeEnd)
      );
    });

    setIsShowFilter(!isShowFilter);

    setDataView(data);
  };

  const handleToggleFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const resetFilter = () => {
    setDateRange({
      start_date: "",
      end_date: "",
    });
  };

  return (
    <>
      <div className={"request-page-confirmation-container title"}>
        <div className="arrow-back">
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon className="icon" icon="arrow-left" />
          </Button>
        </div>
        <div className={"confirmation-title"}>Payslips</div>
      </div>
      <div className="payslip-page container">
        <div className="w-100" onClick={handleToggleFilter}>
          {isShowFilter ? (
            <Icon icon={"filter-remove"} iconSize={20} />
          ) : (
            <Icon icon={"filter-open"} iconSize={20} />
          )}
        </div>
        <div className={`card mt-2 ${!isShowFilter ? "d-none" : ""}`}>
          <div className="card-body pl-1">
            <div>
              <label>Start Date</label>
              <input
                type="date"
                name="start_date"
                value={dateRange?.start_date || ""}
                className="w-100"
                onChange={(e: any) => handleRangeChange(e)}
              />
            </div>
            <div className="mt-2">
              <label className="mt-4">End Date</label>
              <input
                type="date"
                name="end_date"
                value={dateRange?.end_date || ""}
                className="w-100"
                min={dateRange?.start_date}
                onChange={(e: any) => handleRangeChange(e)}
                disabled={dateRange?.start_date ? false : true}
              />
            </div>

            <button className="btn btn-info w-100 mt-2" onClick={submitFilter}>
              Filter
            </button>
            <button
              type="reset"
              className="btn btn-danger w-100 mt-2"
              onClick={resetFilter}
            >
              Reset
            </button>
          </div>
        </div>
        {dataView.length > 0 ? (
          dataView?.map((item: any, idx: number) => (
            <Link to={`/payslip/detail/${item.periodID}`} key={idx}>
              <div className="card" key={idx}>
                <div className="card-body">
                  <div className="dot success"></div>
                  <div className="flex title-slip">
                    <p>Salary Slip</p>
                    <small className="text-secondary">{item.created_at}</small>
                  </div>
                  <small className="text-secondary">
                    Periode{" "}
                    {new Date(item.startTime).toLocaleDateString("id-ID", {
                      day: "numeric",
                      year: "numeric",
                      month: "long",
                    })}{" "}
                    -{" "}
                    {new Date(item.endTime).toLocaleDateString("id-ID", {
                      day: "numeric",
                      year: "numeric",
                      month: "long",
                    })}
                  </small>
                  <div className="flex mt-2">
                    <strong>Paid</strong>
                    <strong className="ml-auto">
                      Rp. {rupiahFormatter(item.amount)}
                    </strong>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="text-center mt-4">
            <span>Tidak Ada Data</span>
          </div>
        )}
      </div>
    </>
  );
};
