import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import { useRegistrationVerifyOtpMutation } from "../../../../backend/app";
import Logo from "../../../../assets/logo.svg";

export const VerifyOTP: FunctionComponent<RegistrationComponentProps> = ({
  setNextIsDisabled,
  registrationToken,
  setRegistrationToken,
  onNextClickRef,
  setInvitationName,
}) => {
  const [otpCode, setOtpCode] = useState<string>("");

  useEffect(() => {
    setNextIsDisabled(otpCode.length !== 4);
  }, [otpCode, setNextIsDisabled]);

  const [verifyOtp] = useRegistrationVerifyOtpMutation();

  onNextClickRef.current = useCallback(async () => {
    const resp = await verifyOtp({
      variables: {
        otpCode: parseInt(otpCode).toString(),
        token: registrationToken,
      },
      fetchPolicy: "no-cache",
    });
    setInvitationName(resp.data?.RegistrationVerifyOTP.name);
    setRegistrationToken(resp.data?.RegistrationVerifyOTP.token);
  }, [setRegistrationToken, verifyOtp, registrationToken, otpCode]);

  return (
    <div className="hp">
      <div className="container logo">
        <img src={Logo} alt="gajiku-logo" />
        <div className="title">Gajiku</div>
      </div>
      <div className="header">
        <small>Harap masukan Nomor Kode OTP.</small>
      </div>
      <div className="container otp-number">
        <small>Nomor Kode</small>
        <div className="otp-field">
          <input
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            placeholder="Masukan OTP"
          />
        </div>
      </div>
    </div>
  );
};
