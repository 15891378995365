import {
  generateStateManagers,
  IAction,
  IStoreState,
  registerProvider,
} from "../utils/store";

export enum API_ACTION {
  SET_DATA,
  CLEAR_DATA,
}

export interface IAPIStore extends IStoreState {
  [_: string]: any;
}

export interface IAPIAction extends IAction {
  type: any;
  url?: string;
  [_: string]: any;
}

export const generateAPIReducer =
  (saveState: (s: IAPIStore) => IAPIStore) =>
  (state: IAPIStore, action: IAPIAction) => {
    switch (action.type) {
      case API_ACTION.SET_DATA:
        return saveState({
          ...state,
          [action.url!]: action.data,
        });
      case API_ACTION.CLEAR_DATA:
        return saveState({
          ...state,
          [action.url!]: undefined,
        });
      default:
        return state;
    }
  };

export const registerAPIStore = (name: string) => {
  const [saveState, loadState] = generateStateManagers(name);
  return registerProvider(generateAPIReducer(saveState), loadState());
};
