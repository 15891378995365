import React, {
  createContext,
  FunctionComponent,
  RefObject,
  useContext,
  useRef,
} from "react";
import { Intent, Toaster } from "@blueprintjs/core";
import { BackendResponse } from "../api/hook";

const ToasterContext = createContext<RefObject<Toaster> | undefined>(undefined);

export const ToasterProvider: FunctionComponent = ({ children }) => {
  const toaster = useRef<Toaster | null>(null);
  return (
    <>
      <ToasterContext.Provider value={toaster}>
        <Toaster ref={(ref) => (toaster.current = ref)} />
        {children}
      </ToasterContext.Provider>
    </>
  );
};

export const useToaster = () => useContext(ToasterContext);

export interface ToastResponse {
  resp?: any;
  error?: Error;
}

export const useToastHandler: () => (
  c: () => Promise<BackendResponse<any>>,
) => Promise<ToastResponse> = () => {
  const toaster = useToaster();
  return async (c) => {
    try {
      let resp = await c();
      if (!resp) {
        resp = {};
      }
      if (resp.error) {
        throw new Error(resp.message);
      }
      toaster?.current?.show({ message: "Success", intent: Intent.SUCCESS });
      return { resp };
    } catch (e) {
      toaster?.current?.show({ message: e.message, intent: Intent.DANGER });
      return { error: e };
    }
  };
};
