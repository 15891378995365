import React, { FunctionComponent } from "react";
import LoginPage from "./LoginPage";
import { Switch, Route } from "react-router-dom";
import RegistrationPage from "./Registration";
import ForgetPasswordPage from "./ForgetPassword";

export const Login: FunctionComponent<{
  baseRoute: string;
  loggedInRoute: string;
}> = ({ baseRoute, loggedInRoute }) => {
  return (
    <Switch>
      <Route exact path={`${baseRoute}`}>
        <LoginPage baseRoute={`${baseRoute}`} />
      </Route>
      <Route path={`${baseRoute}/register`}>
        <RegistrationPage
          baseRoute={`${baseRoute}/register`}
          loginRoute={baseRoute}
          registrationCompleteRoute={loggedInRoute}
        />
      </Route>
      <Route path={`${baseRoute}/forget-password`}>
        <ForgetPasswordPage
          baseRoute={`${baseRoute}/forget-password`}
          loginRoute={baseRoute}
          registrationCompleteRoute={loggedInRoute}
        />
      </Route>
    </Switch>
  );
};
