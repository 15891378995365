/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import {
  CompanyEmployee,
  useCompanyEmployeeRetrieveQuery,
} from "../../../../../../backend/app";
import { useLogin } from "../../../../../../data/login/api";
import { getAttendanceData } from "../../redux/clock-in/clockin.actions";
import {
  setHeader,
  unsetCaptureImage,
  unsetLocation,
} from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./home.style.scss";

const Home = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const [greetings, setGreetings] = useState("");
  const [fromTo, setFromTo] = useState("");

  const [, loginAuth] = useLogin();
  const { data: companyProfileData, error: companyProfileError } =
    useCompanyEmployeeRetrieveQuery({
      variables: {
        userID: loginAuth.userID,
        companyID: loginAuth.companyID,
      },
    });

  const profile =
    companyProfileData?.CompanyEmployeeRetrieve || ({} as CompanyEmployee);
  const name = profile.name || "";

  const { clockInOut, masterSchedule, checkAttendance } = useAppSelector(
    (state) => state.clockin,
  );

  useEffect(() => {
    dispatch(getAttendanceData(loginAuth.level !== "COMPANY_MANAGER"));
    dispatch(setHeader({ title: "Attendance", hasBack: true }));
    dispatch(unsetCaptureImage());
    dispatch(unsetLocation());
    const today = new Date();
    if (today.getHours() < 12) {
      setGreetings("Good Morning");
    } else if (today.getHours() < 18) {
      setGreetings("Good Afternoon");
    } else {
      setGreetings("Good Evening");
    }
  }, []);

  useEffect(() => {
    if (masterSchedule) {
      const currentDate = new Date();
      const timeRanges = masterSchedule.detail.timeRange;
      const found = timeRanges.filter((item: any) =>
        item.days.includes(currentDate.getDay()),
      )[0];
      if (found) setFromTo(`${found.startTime} - ${found.endTime}`);
    }
  }, [masterSchedule]);

  const generateClock = (): string => {
    if (clockInOut.length == 2) {
      const output: string[] = [];
      clockInOut.forEach((item: any) => {
        const date = item.time;
        output.push(date.split("T")[1].split(".")[0]);
      });
      return output.join(" - ");
    } else {
      const clockInData = clockInOut.filter(
        (item: any) => item.type === "clock_in",
      )[0];
      const date = clockInData?.time;
      return date?.split("T")[1]?.split(".")[0];
    }
  };

  return (
    <div className="home">
      <div className="profile-container">
        <img
          src="https://i.kym-cdn.com/photos/images/newsfeed/000/110/672/1301700505625.jpg"
          alt="profile-user"
        />
        <div className="greeting">{greetings}</div>
        <div className="name">{name}</div>
      </div>
      {loginAuth.level === "COMPANY_MANAGER" ? (
        <div className="button-container">
          <div className="btn" onClick={() => history.push(`${path}scan-qr`)}>
            <div className="text-section">
              <div className="title">Absen Masuk / Keluar</div>
              <div className="desc">Lorem ipsum dolor sit amet</div>
            </div>
            <Icon color="#fff" icon="document-open" iconSize={30} />
          </div>
        </div>
      ) : (
        <>
          <div className="upcoming-trip">
            <div className="card">
              <div className="trip-detail">
                <div className="detail">
                  <div className="desc">
                    {clockInOut?.length === 2
                      ? "Your Attendance"
                      : checkAttendance?.is_active
                      ? "Clock In"
                      : "Upcoming Shift"}
                  </div>
                  <div className="time">
                    {clockInOut?.length === 2 || checkAttendance?.is_active
                      ? generateClock()
                      : masterSchedule?.shiftName}
                  </div>
                  <div className="from-to">
                    {clockInOut?.length === 2 || checkAttendance?.is_active
                      ? masterSchedule?.shiftName
                      : fromTo}
                  </div>
                </div>
              </div>
              {clockInOut.length !== 2 && (
                <button
                  className="button"
                  onClick={() => history.push(`${path}choose-option`)}
                >
                  {checkAttendance?.is_active
                    ? "Absen Keluar Sekarang"
                    : "Absen Sekarang"}
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="btn" onClick={() => history.push(`${path}permit`)}>
              <div className="text-section">
                <div className="title">Pengajuan Izin</div>
                <div className="desc">Lorem ipsum dolor sit amet</div>
              </div>
              <Icon color="#fff" icon="list-detail-view" iconSize={30} />
            </div>
            <div
              className="btn"
              onClick={() => history.push(`${path}outstation`)}
            >
              <div className="text-section">
                <div className="title">Tugas Luar Kota</div>
                <div className="desc">Lorem ipsum dolor sit amet</div>
              </div>
              <Icon color="#fff" icon="airplane" iconSize={30} />
            </div>
            <div
              className="btn"
              onClick={() => history.push(`${path}approval`)}
            >
              <div className="text-section">
                <div className="title">Approval Request</div>
                <div className="desc">Lorem ipsum dolor sit amet</div>
              </div>
              <Icon color="#fff" icon="form" iconSize={30} />
            </div>
            <div
              className="btn"
              onClick={() => history.push(`${path}shift-request`)}
            >
              <div className="text-section">
                <div className="title">Shift Request</div>
                <div className="desc">Lorem ipsum dolor sit amet</div>
              </div>
              <Icon color="#fff" icon="time" iconSize={30} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
