/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonImage from "../../components/button-selfie";
import MapSection from "../../components/maps-section";
import { sendClockOutAttendance } from "../../redux/clock-in/clockin.actions";
import { setHeader, showAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./clockout.style.scss";

const ClockOut = () => {
  const dispatch = useAppDispatch();
  const { image, lat, long, isNearby } = useAppSelector(
    (state) => state.globalState,
  );
  const { status } = useAppSelector((state) => state.clockin.masterData);
  const [workDuration, setWorkDuration] = useState("");

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Keluar", hasBack: true }));
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (image && image !== "") {
      if (isNearby) {
        dispatch(
          sendClockOutAttendance(
            {
              attendance_id: "",
              lat,
              long,
              type: "clock_out",
              time: "",
              file: image,
              desc: "",
            },
            workDuration,
            "",
          ),
        );
      } else {
        dispatch(
          showAlert(
            "Anda tidak sedang berada dalam lingkungan Kantor!",
            "error",
          ),
        );
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} className="clock-out">
      <MapSection />
      <ButtonImage />
      <button type="submit" className="submit-btn">
        Absen Keluar
      </button>
    </form>
  );
};

export default ClockOut;
