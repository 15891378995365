import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { PageComponent } from "../../lib";
import { Button, Card, Elevation, FormGroup, Intent } from "@blueprintjs/core";
import "./index.scss";
import { UploadKTPDialog } from "./UploadKTPDialog";
import { UpdatePasswordDialog } from "./UpdatePasswordDialog";
import { useLogin, useLogout } from "../../../../data/login/api";
import {
  BankAccountVerificationStatus,
  CompanyEmployee,
  useBankAccountRetrieveDefaultLazyQuery,
  useBankAccountRetrieveDefaultQuery,
  useCompanyEmployeeRetrieveLazyQuery,
  useCompanyEmployeeRetrieveQuery,
} from "../../../../backend/app";
import { Icon } from "@iconify/react";
import { useAppDispatch } from "../Attendance/redux/hooks";
import { logoutRedux } from "../Attendance/redux/auth/auth.actions";

export const ProfileCard: FunctionComponent<
  PropsWithChildren<{
    title: string;
  }>
> = ({ children, title }) => {
  return (
    <div className={"profile-card"}>
      <Card elevation={Elevation.TWO}>
        <div className={"profile-card-header"}>{title}</div>
        <hr />
        <div className={"profile-card-body"}>{children}</div>
      </Card>
    </div>
  );
};

export const VerificationSpan: FunctionComponent<{
  value?: BankAccountVerificationStatus;
}> = ({ value }) => {
  let color: string | undefined;
  switch (value) {
    case BankAccountVerificationStatus.InvalidUnknown:
    case BankAccountVerificationStatus.InvalidAccountNumber:
    case BankAccountVerificationStatus.InvalidSuspectedAccount:
    case BankAccountVerificationStatus.InvalidBlackListed:
      color = "var(--theme-red)";
      break;
    case BankAccountVerificationStatus.Pending:
      color = "orange";
      break;
    case BankAccountVerificationStatus.Valid:
      color = "var(--theme-green)";
      break;
  }
  let text: string | undefined;
  switch (value) {
    case BankAccountVerificationStatus.InvalidUnknown:
    case BankAccountVerificationStatus.InvalidAccountNumber:
    case BankAccountVerificationStatus.InvalidSuspectedAccount:
    case BankAccountVerificationStatus.InvalidBlackListed:
      text = "Invalid";
      break;
    case BankAccountVerificationStatus.Pending:
      text = "Diproses";
      break;
    case BankAccountVerificationStatus.Valid:
      text = "Valid";
      break;
  }

  return <span style={{ color }}>{text}</span>;
};

export const ProfilePage: PageComponent = ({ onTopIconClickRef }) => {
  const dispatch = useAppDispatch();
  const [, loginAuth] = useLogin();
  const logout = useLogout();

  const { data: companyProfileData, error: companyProfileError } =
    useCompanyEmployeeRetrieveQuery({
      variables: {
        userID: loginAuth.userID,
        companyID: loginAuth.companyID,
      },
    });
  const { data: bankDetails, error: bankError } =
    useBankAccountRetrieveDefaultQuery({
      variables: { userID: loginAuth.userID },
      // TODO: Turn off polling when bank is valid
      pollInterval: 5 * 60 * 1000,
    });
  const [forceCompanyProfileRefresh] = useCompanyEmployeeRetrieveLazyQuery({
    variables: {
      userID: loginAuth.userID,
      companyID: loginAuth.companyID,
    },
    fetchPolicy: "network-only",
  });
  const [forceBankAccountRetrieveDefault] =
    useBankAccountRetrieveDefaultLazyQuery({
      variables: { userID: loginAuth.userID },
      fetchPolicy: "network-only",
    });

  onTopIconClickRef.current = () => {
    forceCompanyProfileRefresh();
    forceBankAccountRetrieveDefault();
  };

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] =
    useState<boolean>(false);

  const [ktpDialogIsOpen, setKtpDialogIsOpen] = useState<boolean>(false);

  const profile =
    companyProfileData?.CompanyEmployeeRetrieve || ({} as CompanyEmployee);

  const name = profile.name || "";
  const phoneNumber = profile.phoneNumber || "";
  const ktpUrl = profile.ktpUrl || "";
  const bankCode = bankDetails?.BankAccountRetrieveDefault.bankCode || "";
  const accountNumber =
    bankDetails?.BankAccountRetrieveDefault.accountNumber || "";
  const bankVerificationStatus =
    bankDetails?.BankAccountRetrieveDefault.verificationStatus;

  const hasCustomData = !!profile?.customData;
  const customDataElements: ReactNode[] = [];
  if (hasCustomData) {
    Object.keys(profile!.customData).map((k) => {
      const label = k;
      const value = profile!.customData[k];
      customDataElements.push(
        <FormGroup key={`custom-data-${k}`} label={label}>
          <div className={"profile-text"}>{value}</div>
        </FormGroup>,
      );
    });
  }

  return (
    <div className={"profile-container"}>
      <UploadKTPDialog
        url={ktpUrl}
        isOpen={ktpDialogIsOpen}
        onClose={() => {
          setKtpDialogIsOpen(false);
        }}
      />
      <UpdatePasswordDialog
        isOpen={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
      />
      <div className={"profile-body"}>
        <ProfileCard title={"Pribadi"}>
          <div className="profile-pict">
            <img
              src="https://i.kym-cdn.com/photos/images/newsfeed/000/110/672/1301700505625.jpg"
              alt=""
            />
          </div>
          <FormGroup label={"Nama Karyawan"}>
            <div className={"profile-text"}>{name}</div>
          </FormGroup>
          <FormGroup label={"Nomor HP"}>
            <div className={"profile-text"}>{phoneNumber}</div>
          </FormGroup>
          {customDataElements}
        </ProfileCard>
        <ProfileCard title={"Detail Bank"}>
          <FormGroup label={"Code Bank"}>
            <div className={"profile-text"}>{bankCode}</div>
          </FormGroup>
          <FormGroup label={"Rekening Bank"}>
            <div className={"profile-text"}>{accountNumber}</div>
          </FormGroup>
          <FormGroup label={"Status Verifikasi Rekening Bank"}>
            <div className={"profile-text"}>
              <VerificationSpan value={bankVerificationStatus} />
            </div>
          </FormGroup>
        </ProfileCard>
        <ProfileCard title={"Keamanan"}>
          <Button
            onClick={() => {
              setIsPasswordDialogOpen(true);
            }}
            className={"profile-card-button"}
          >
            Perbaharui Sandi
          </Button>
        </ProfileCard>
        <ProfileCard title={"Aplikasi"}>
          <FormGroup label={"User ID"}>
            <div style={{ fontSize: "1.6rem", fontFamily: "roboto mono" }}>
              {loginAuth.userID}
            </div>
          </FormGroup>
          <FormGroup label={"Member ID"}>
            <div style={{ fontSize: "1.6rem", fontFamily: "roboto mono" }}>
              {loginAuth.memberID}
            </div>
          </FormGroup>
        </ProfileCard>
        <ProfileCard title={"Logout"}>
          <Button
            className={"profile-card-button"}
            intent={Intent.DANGER}
            onClick={() => {
              logout();
              dispatch(logoutRedux());
            }}
          >
            Logout
          </Button>
        </ProfileCard>
      </div>
    </div>
  );
};
