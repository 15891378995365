const GlobalActionTypes = {
  SET_HEADER: "SET_HEADER",
  SHOW_LOADING: "SHOW_LOADING",
  HIDE_LOADING: "HIDE_LOADING",
  SET_IMAGE: "SET_IMAGE",
  UNSET_IMAGE: "UNSET_IMAGE",
  SET_LOCATION: "SET_LOCATION",
  UNSET_LOCATION: "UNSET_LOCATION",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  HIDE_SUCCESS: "HIDE_SUCCESS",
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
};

export default GlobalActionTypes;
