export interface AttendanceRecord {
  attendance_id: string;
  time: string;
  type: string;
  lat: string;
  long: string;
  file: any;
  desc: string;
}

export enum KEYS {
  GEOCODE = "AIzaSyAMN-K2gpJ5YD73wkzyg3_YPIJFJ7ceQIk",
}
