import React from "react";
import { Icon } from "@blueprintjs/core";
import { hideAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./alert.style.scss";

const Alert = () => {
  const dispatch = useAppDispatch();
  const { showAlert, alertMessage, alertType } = useAppSelector(
    (state) => state.globalState,
  );

  return (
    <div
      className={`toast ${alertType === "error" ? "bg-danger" : "bg-success"}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        opacity: showAlert ? 1 : 0,
        position: "fixed",
        margin: "10px",
        zIndex: showAlert ? 9999 : -1,
        right: showAlert ? 0 : "-1000px",
        top: 0,
      }}
    >
      <div className="d-flex">
        <div className="toast-body">{alertMessage}</div>
        <div
          className="btn-close border-0 me-2 m-auto"
          style={{ background: "none", color: "#fff" }}
          aria-label="Close"
          onClick={() => dispatch(hideAlert())}
        >
          <Icon icon="cross" />
        </div>
      </div>
    </div>
  );
};

export default Alert;
