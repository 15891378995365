import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Dialog, FileInput, Intent } from "@blueprintjs/core";

import "./uploadKTPDialog.css";
import { useToastHandler } from "../../../../utils/toaster";

export const UploadKTPDialog: FunctionComponent<{
  url?: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ onClose, isOpen, url }) => {
  const reader = new FileReader();
  // const [, employeeData] = useEmployeeGet();
  // const [, loginAuth] = useLogin();

  const [ktpFile, setKtpFile] = useState<File | undefined>();
  // const [ktpUrl, setKtpUrl] = useState<string>(url || "");

  const toastHandler = useToastHandler();

  // useEffect(() => {
  //   setKtpUrl((employeeData[0] || {}).ktpUrl || "");
  // }, [setKtpUrl, employeeData[0]]);

  // const submitKtp = useCallback(async () => {
  //   const resp = await toastHandler(async () => {
  //     const formData = new FormData();
  //     formData.append("ktp", ktpFile!);
  //     formData.append("userID", loginAuth.userID);
  //     formData.append("companyID", loginAuth.companyID!);
  //     const resp = await apiFetch("/ktp/post", formData);
  //     const j = (await resp.json()) as BackendResponse<UserKtpapiResponse>;
  //     if (j.error) {
  //       throw new Error(j.message);
  //     }
  //     return j;
  //   });
  //   setKtpUrl(resp.resp?.data?.ktpMedium || "");
  //   onClose();
  // }, [loginAuth, ktpFile, setKtpUrl]);

  // useEffect(() => {
  //   if (ktpFile) {
  //     reader.readAsDataURL(ktpFile);
  //     reader.onloadend = () => {
  //       if (reader.result) {
  //         setKtpUrl(reader.result.toString());
  //       }
  //     };
  //   }
  // }, [ktpFile]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={"ktp-upload-popup"}>
        <div className={"ktp-upload-detail-header"}>
          <div className={"ktp-upload-detail-title"}>Change KTP</div>
        </div>
        <div className={"ktp-upload-detail-body"}>
          <div className={"ktp-upload-detail-image"}>
            {url ? <img src={url} alt={"ktp"} /> : <div />}
          </div>
          <FileInput
            onInputChange={(e: any) => {
              setKtpFile(e.target.files[0]);
            }}
          />
        </div>
        <div className={"ktp-upload-detail-footer"}>
          <div className={"ktp-upload-detail-footer-details"}>
            <Button onClick={onClose} large={true}>
              Tutup
            </Button>
            {/*<Button intent={Intent.PRIMARY} large={true} onClick={submitKtp}>*/}
            {/*  Simpan*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
