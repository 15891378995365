import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AllProviders } from "./utils/providers";

if (window.location.href.startsWith("https://gajiku-pwa.web.app")) {
  const url = new URL(window.location.href);
  url.hostname = "pwa.gajikuapp.com";
  window.location.href = url.href;
}

ReactDOM.render(
  <AllProviders>
    <App />
  </AllProviders>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
