import { Button, Card, Elevation } from "@blueprintjs/core";
import React, {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./registrationPage.css";
import { VerifyOTP } from "./Steps/VerifyOTP";
import { ResetSecurityCode } from "./Steps/ResetSecurityCode";
import { useToastHandler } from "../../../utils/toaster";
import {
  RegistrationComponentProps,
  RegistrationComponentRenderer,
} from "../lib";
import { ValidateHandphone } from "./Steps/ValidateHandphone";
import Logo from "../../../assets/logo.svg";
import ZendeskButton from "../zendesk-button";

const ForgetPasswordStep: FunctionComponent<
  PropsWithChildren<{
    stepIndex: number;
    baseRoute: string;
    loginRoute: string;
    maxStep: number;
    onNextClickRef: MutableRefObject<() => Promise<any>>;
    renderComponent: RegistrationComponentRenderer;
    registrationCompleteRoute: string;
  }>
> = ({
  renderComponent,
  registrationCompleteRoute,
  onNextClickRef,
  loginRoute,
  baseRoute,
  stepIndex,
  maxStep,
}) => {
  const hist = useHistory();
  const [prevIsDisabled, setPrevIsDisabled] = useState<boolean>(false);
  const isFirstStep = stepIndex === 1;
  const [nextIsDisabled, setNextIsDisabled] = useState<boolean>(true);
  const isLastStep = stepIndex >= maxStep;
  const toastHandler = useToastHandler();
  const onNextClick = async () => {
    const resp = await toastHandler(async () => {
      return await onNextClickRef.current();
    });
    if (resp && !resp.error) {
      hist.push(`${baseRoute}/${stepIndex + 1}`);
    }
  };
  const onSubmitClick = async () => {
    const resp = await toastHandler(async () => {
      return await onNextClickRef.current();
    });
    if (resp && !resp.error) {
      hist.push(registrationCompleteRoute);
    }
  };

  return (
    <div className="registration-step">
      <Card elevation={Elevation.FOUR}>
        <div className="container logo">
          <img src={Logo} alt="gajiku-logo" />
          <div className="title">Gajiku</div>
        </div>
        <div>
          {renderComponent({
            setPrevIsDisabled,
            setNextIsDisabled,
          } as RegistrationComponentProps)}
        </div>
        <div className="footer">
          {isLastStep ? (
            <Button
              className="button"
              disabled={nextIsDisabled}
              onClick={onSubmitClick}
            >
              Ok
            </Button>
          ) : (
            <Button
              className="button"
              disabled={nextIsDisabled}
              onClick={onNextClick}
            >
              Berikutnya
            </Button>
          )}
          {isFirstStep ? (
            <Button onClick={() => hist.push(loginRoute)}>Cancel</Button>
          ) : (
            <Button
              disabled={prevIsDisabled}
              onClick={() => hist.push(`${baseRoute}/${stepIndex - 1}`)}
            >
              Kembali
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

const ForgetPasswordPage: FunctionComponent<{
  baseRoute: string;
  loginRoute: string;
  registrationCompleteRoute: string;
}> = ({ baseRoute, loginRoute, registrationCompleteRoute }) => {
  const [registrationToken, setRegistrationToken] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onNextClickRef = useRef<() => Promise<any>>(async () => {});

  const forgetPasswordBackendProps = {
    onNextClickRef,
    setRegistrationToken,
    registrationToken,
  } as RegistrationComponentProps;
  const pages = [
    (props) => <ValidateHandphone {...props} {...forgetPasswordBackendProps} />,
    (props) => <VerifyOTP {...props} {...forgetPasswordBackendProps} />,
    (props) => <ResetSecurityCode {...props} {...forgetPasswordBackendProps} />,
  ] as RegistrationComponentRenderer[];

  const nodes = pages.map((renderComponent, pageIndex) => {
    return (
      <Route
        key={`route-reset-password-${pageIndex}`}
        path={`${baseRoute}/${pageIndex + 1}`}
      >
        <ForgetPasswordStep
          maxStep={pages.length}
          baseRoute={baseRoute}
          stepIndex={pageIndex + 1}
          renderComponent={renderComponent}
          loginRoute={loginRoute}
          onNextClickRef={onNextClickRef}
          registrationCompleteRoute={registrationCompleteRoute}
        />
        <ZendeskButton />
      </Route>
    );
  });
  return <Switch>{nodes}</Switch>;
};

export default ForgetPasswordPage;
