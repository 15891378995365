const ClockinActionTypes = {
  SEND_ATTENDANCE: "SEND_ATTENDANCE",
  SEND_ATTENDANCE_SUCCESS: "SEND_ATTENDANCE_SUCCESS",
  SEND_ATTENDANCE_FAILURE: "SEND_ATTENDANCE_FAILURE",
  GET_MASTER_DATA: "GET_MASTER_DATA",
  GET_MASTER_DATA_SUCCESS: "GET_MASTER_DATA_SUCCESS",
  GET_MASTER_DATA_FAILURE: "GET_MASTER_DATA_FAILURE",
  GET_SCHEDULE_DATA: "GET_SCHEDULE_DATA",
  GET_SCHEDULE_DATA_SUCCESS: "GET_SCHEDULE_DATA_SUCCESS",
  GET_SCHEDULE_DATA_FAILURE: "GET_SCHEDULE_DATA_FAILURE",
};

export default ClockinActionTypes;
