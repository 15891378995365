declare global {
  interface Window {
    zE: any;
    zEPopout: any;
  }
}
class ZendeskController {
  static getChatIframe() {
    return document.querySelector("#zendesk-iframe") as
      | HTMLIFrameElement
      | undefined;
  }

  static initChat({
    onLoaded,
    onOpen,
    onChatStart,
    onClose,
  }: {
    onLoaded?: () => void;
    onOpen?: () => void;
    onChatStart?: () => void;
    onClose?: () => void;
  }) {
    if (document.querySelector("#zendesk-iframe")) {
      return;
    }

    const rawIframe = document.createElement("iframe") as HTMLIFrameElement;
    rawIframe.setAttribute("id", "zendesk-iframe");
    rawIframe.setAttribute("allowfullscreen", "true");
    this.css(rawIframe, {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      border: 0,
      "z-index": 9999,
      "background-color": "white",
    });
    document.body.appendChild(rawIframe);

    let framedoc = rawIframe.contentDocument;
    if (!framedoc && rawIframe.contentWindow) {
      framedoc = rawIframe.contentWindow.document;
    }

    this.setFullScreen();

    const zendeskKey = `fc6904bf-d005-4c6a-bd38-ff8292e2fa80`;
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    script.addEventListener("load", function () {
      onLoaded?.();
      self.setZendeskAPI("webWidget:on", "open", function () {
        onOpen?.();
      });
      self.setZendeskAPI("webWidget:on", "chat:connected", function () {
        onChatStart?.();
      });
      self.setZendeskAPI("webWidget:on", "close", function () {
        self.removeChat();
        onClose?.();
      });
    });
    framedoc!.body.appendChild(script);
  }

  static removeChat() {
    this.getChatIframe()?.remove();
  }

  static setZendeskAPI(...args: any[]) {
    if (this.getChatIframe()) {
      this?.getChatIframe()?.contentWindow?.zE.apply(null, args);
    } else {
      console.warn("Zendesk is not initialized yet");
    }
  }

  static setFullScreen() {
    const chatIFrame = this.getChatIframe();
    if (!chatIFrame) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    chatIFrame.contentWindow.zEPopout = true;
  }

  private static css(element: HTMLIFrameElement, style: any) {
    Object.keys(style).forEach((property: any) => {
      element.style[property] = style[property];
    });
  }
}

export default ZendeskController;
