/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { setHeader, showAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./clockoption.style.scss";
import QRCode from "react-qr-code";
import { useLogin } from "../../../../../../data/login/api";
import useQRParser, { ActionType } from "../../_helper/QRParser";

const ClockOption = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [, loginAuth] = useLogin();
  const {
    masterSchedule,
    clockInOut,
    currentSchedule,
    checkAttendance,
    location,
  } = useAppSelector((state) => ({
    ...state.clockin,
    ...state.clockin.masterData,
  }));
  const [formattedText, fromText, QRData, fromPicture] = useQRParser();

  const deg2rad = (deg: any) => {
    return deg * (Math.PI / 180);
  };

  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const generateQR = useCallback(() => {
    const currentDate = new Date().toLocaleDateString();
    if (currentSchedule.restrictLocation) {
      const currentLocation = location.filter(
        (item: any) => item.id === currentSchedule.locationId,
      )[0];
      let lat: string, long: string;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          long = pos.coords.longitude.toString();
          lat = pos.coords.latitude.toString();
          const distance = getDistance(
            parseFloat(lat),
            parseFloat(long),
            parseFloat(currentLocation.lat),
            parseFloat(currentLocation.long),
          );
          if (distance <= 1) {
            fromText({
              userId: loginAuth?.userID,
              currentSchedule: currentSchedule,
              date: currentDate,
              actionType: checkAttendance.is_active
                ? ActionType.ClockOut
                : ActionType.ClockIn,
            });
          } else {
            dispatch(
              showAlert(
                "Anda tidak sedang berada dalam lingkungan Kantor!",
                "error",
              ),
            );
          }
        },
        (err) => {
          dispatch(
            showAlert("Tidak dapat membaca lokasi anda saat ini!", "error"),
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      fromText({
        userId: loginAuth?.userID,
        currentSchedule: currentSchedule,
        date: currentDate,
        actionType: checkAttendance.is_active
          ? ActionType.ClockOut
          : ActionType.ClockIn,
      });
    }
  }, []);

  useEffect(() => {
    generateQR();
    dispatch(
      setHeader({
        title: checkAttendance.is_active ? "Clock Out" : "Clock In",
        hasBack: true,
      }),
    );
  }, [checkAttendance, generateQR]);

  useEffect(() => {
    if (formattedText !== "") fromPicture(formattedText);
  }, [formattedText]);

  const generateClock = (): string => {
    const clockInData = clockInOut.filter(
      (item: any) => item.type === "clock_in",
    )[0];
    const date = clockInData?.time;
    return date.split("T")[1].split(".")[0];
  };

  const generateOfficeHour = (): string => {
    const currentDate = new Date();
    const found = masterSchedule.detail.timeRange.filter((item: any) =>
      item.days.includes(currentDate.getDay()),
    )[0];
    return `${found.startTime} - ${found.endTime}`;
  };

  return (
    <div className="choose-page">
      {checkAttendance.is_active && (
        <div className="card mb-3">
          <div className="card-body">
            <p className="text-info">Office hour</p>
            <h3>{generateOfficeHour()}</h3>
            <small className="text-secondary">{masterSchedule.shiftName}</small>
            <div className="row-custom mt-2">
              <div className="flex">
                <p className="text-info">Check In</p>
                <h3>{generateClock()}</h3>
              </div>
              <div className="flex">
                <p className="text-info">Check Out</p>
                <h3>--:--:--</h3>
              </div>
              <div className="flex">
                <p className="text-info">Late Time </p>
                <h3>--:--:--</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <QRCode value={formattedText} />
      </div>
      {currentSchedule?.backupGPS && (
        <div
          onClick={() =>
            history.push(
              checkAttendance.is_active
                ? `/attendance/clockOut`
                : `/attendance/clockIn`,
            )
          }
        >
          <div className="row-custom btn-clock">
            <div>
              <p>Self clock in</p>
              <small className="text-secondary">
                Supervisor not around? Use your location and take selfie to
                clock in
              </small>
            </div>
            <div>
              <h3 className="icon">
                <Icon icon="chevron-right" iconSize={30} />
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClockOption;
