import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import BankCode, { AllBankCodes } from "../../../../backend/BankCode";
import { useRegistrationSubmitBankMutation } from "../../../../backend/app";
import Logo from "../../../../assets/logo.svg";

export const RegisterBankStep: FunctionComponent<RegistrationComponentProps> =
  ({
    setRegistrationToken,
    registrationToken,
    onNextClickRef,
    setNextIsDisabled,
  }) => {
    const bankCodes = AllBankCodes();
    const [finalBankCode, setFinalBankCode] = useState<BankCode | undefined>();
    const [rawBankNumber, setRawBankNumber] = useState<string>("");

    const bankCodeSelections: ReactNode[] = [
      <option key={"select-bank-null"}>Pilih Bank</option>,
    ].concat(
      bankCodes.map((bankCode, index) => {
        return (
          <option
            key={`select-bank-${index}`}
            placeholder="Nama Bank"
            value={bankCode}
          >
            {bankCode.toUpperCase()}
          </option>
        );
      }),
    );

    useEffect(() => {
      setNextIsDisabled(rawBankNumber === "" || finalBankCode === undefined);
    }, [finalBankCode, rawBankNumber, setNextIsDisabled]);

    const [verifyBankAccount] = useRegistrationSubmitBankMutation();
    onNextClickRef.current = useCallback(async () => {
      const resp = await verifyBankAccount({
        variables: {
          bankCode: finalBankCode as BankCode,
          accountNumber: rawBankNumber,
          token: registrationToken,
        },
        fetchPolicy: "no-cache",
      });
      setRegistrationToken(resp.data?.RegistrationSubmitBank);
    }, [
      setRegistrationToken,
      verifyBankAccount,
      finalBankCode,
      rawBankNumber,
      registrationToken,
    ]);

    return (
      <div className="bank">
        <div className="container logo">
          <img src={Logo} alt="gajiku-logo" />
          <div className="title">Gajiku</div>
        </div>
        <div className="header">
          <small>Harap masukan informasi bank</small>
        </div>
        <div className="body">
          <select
            onChange={(e) => setFinalBankCode(e.target.value as BankCode)}
          >
            {bankCodeSelections}
          </select>
          <input
            value={rawBankNumber}
            onChange={(e) => setRawBankNumber(e.target.value)}
            placeholder="Nomor Rekening"
          />
        </div>
      </div>
    );
  };
