import GlobalActionTypes from "./global.types";

export const setCaptureImage = (image: string) => {
  return async (dispatch: any) => {
    dispatch({ type: GlobalActionTypes.SET_IMAGE, payload: image });
  };
};

export const unsetCaptureImage = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.UNSET_IMAGE });
};

export const setLocation =
  (lat: string, long: string, address: string, isNearby: boolean) =>
  async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SET_LOCATION,
      payload: { lat, long, address, isNearby },
    });
  };

export const unsetLocation = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.UNSET_LOCATION });
};

export const setHeader = ({ title = "", hasBack = false }) => {
  return (dispatch: any) =>
    dispatch({
      type: GlobalActionTypes.SET_HEADER,
      payload: { title, hasBack },
    });
};

export const hideSuccess = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_SUCCESS });
};

export const showAlert =
  (alertMessage: string, alertType: string) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SHOW_ALERT,
      payload: { alertMessage, alertType },
    });
  };

export const hideAlert = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_ALERT });
};
