import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { IconNames } from "@blueprintjs/icons";

import { PageComponent } from "../../lib";
import "./styles.scss";
import { Icon } from "@blueprintjs/core";
export const ContactPage: FunctionComponent = () => {
  return (
    <>
      <div className="contact-container">
        <h3>Sampaikan pertanyaan anda disini</h3>
        <div className="form-container">
          <div className="form-group">
            <label>Nama</label>
            <div>
              <input type="text" />
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <div>
              <input type="email" />
            </div>
          </div>
          <div className="form-group">
            <label>Pesan</label>
            <div>
              <textarea name="" id=""></textarea>
            </div>
          </div>
          <div className="button-box">
            <button className="w-100 btn btn-success">Kirim Pesan</button>
          </div>
        </div>
      </div>
    </>
  );
};
