import React, { FunctionComponent, PropsWithChildren } from "react";
import { CombinedStoreProvider } from "./store";
import { ToasterProvider } from "./toaster";
import { BrowserRouter } from "react-router-dom";
import { GraphQLProvider } from "../backend/provider";
import {
  store,
  persistor,
} from "../pages/Dashboard/Pages/Attendance/redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

export const AllProviders: FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CombinedStoreProvider>
          <GraphQLProvider>
            <ToasterProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </ToasterProvider>
          </GraphQLProvider>
        </CombinedStoreProvider>
      </PersistGate>
    </Provider>
  );
};
