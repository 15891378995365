import React, { FunctionComponent, useEffect, useState } from "react";
import { PageComponent } from "../../lib";
import {
  AnchorButton,
  Card,
  Elevation,
  Icon,
  Intent,
  Switch,
} from "@blueprintjs/core";
import { HistoryDetailPopup } from "./HistoryDetailPopup";
import {
  checkIfRequestsOnly,
  checkIfWithinRange,
  formatTransactionDetails,
} from "./lib";

import "./index.css";
import { dateFormatter, rupiahFormatter } from "../../../../utils/formatters";
import { useLogin } from "../../../../data/login/api";
import {
  TransactionEvent,
  useAllowanceRetrieveLazyQuery,
  useCompanyInfoRetrieveQuery,
  useTransactionEventRetrieveByUserIdLazyQuery,
  useTransactionEventRetrieveByUserIdQuery,
} from "../../../../backend/app";
import { DateRangeInput } from "@blueprintjs/datetime";
import { useToaster } from "../../../../utils/toaster";

const styles = {
  currencyStats: { fontSize: "1.2em", color: "var(--theme-green)" },
  subheader: {
    fontSize: "1.2rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center" as const,
    color: "var(--theme-medium-gray)",
  },
};
export const HistoryRow: FunctionComponent<{
  tx: TransactionEvent;
  openDialog: () => void;
}> = ({ tx, openDialog }) => {
  const { title, amount, messageClass, statusMessage, statusClass, createdAt } =
    formatTransactionDetails(tx);
  const date = new Date(createdAt);
  let description = title;
  if (tx.description) {
    description += ": " + tx.description;
  }

  return (
    <div className="history-item">
      <div className="spaced-item">
        <div className="text">
          {date.toLocaleDateString("id-ID", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </div>
        <div className={`label ${statusClass}`}>
          {statusClass === "warning" ? "Sedang Diproses" : "Terkirim"}
        </div>
      </div>
      <div className="text">Tujuan penarikan</div>
      <div className="spaced-item">
        <div className="bold-text">{description}</div>
        <div className="bold-text">{rupiahFormatter(amount)}</div>
      </div>
      <div className="detail-button" onClick={openDialog}>
        Lihat Detail
      </div>
    </div>
  );
};

export const GajikuPage = ({
  onTopIconClickRef,
}: {
  onTopIconClickRef: any;
}) => {
  const [, loginAuth] = useLogin();
  const toaster = useToaster();

  const [forceAllowanceRetrieve, { data: allowanceAvailable }] =
    useAllowanceRetrieveLazyQuery({
      variables: { userID: loginAuth.userID },
      fetchPolicy: "cache-and-network",
    });

  const [selectedTransaction, setDialogTransaction] =
    useState<TransactionEvent | undefined>();

  const {
    loading: transactionsLoading,
    data: transactionsData,
    error: transactionErrors,
  } = useTransactionEventRetrieveByUserIdQuery({
    variables: { userID: loginAuth.userID },
    pollInterval: 5 * 60 * 1000,
  });

  const [forceRetrieveTransactions] =
    useTransactionEventRetrieveByUserIdLazyQuery({
      variables: { userID: loginAuth.userID },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    try {
      forceAllowanceRetrieve();
    } catch (e) {
      toaster?.current?.show({
        message: "can't fetch allowance",
        intent: Intent.WARNING,
      });
    }
  }, [forceAllowanceRetrieve]);

  onTopIconClickRef.current = () => {
    forceRetrieveTransactions();
    forceAllowanceRetrieve();
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const transactionHistory = [
    ...(transactionsData?.TransactionEventRetrieveByUserID || []),
  ] as TransactionEvent[];

  const [startDateSelected, setStartDateSelected] =
    useState<Date | undefined>();
  const [endDateSelected, setEndDateSelected] = useState<Date | undefined>();

  const [showRequestsOnly, setShowRequestsOnly] = useState<boolean>(true);

  const filteredTransactionHistory = transactionHistory.filter((row) => {
    const isWithinRange = checkIfWithinRange(
      dateFormatter(row.createdAt),
      dateFormatter(startDateSelected),
      dateFormatter(endDateSelected),
    );
    const isRequestsOnly = checkIfRequestsOnly(row.typ, showRequestsOnly);

    return isWithinRange && isRequestsOnly;
  });

  const filteredTransactionHistoryComponent =
    filteredTransactionHistory.length > 0 ? (
      filteredTransactionHistory
        .sort((l, r) => (l.createdAt! < r.createdAt! ? 1 : -1))
        .map((tx, index) => {
          return (
            <HistoryRow
              key={`${tx.memberID}-${tx.eventID}`}
              tx={tx}
              openDialog={() => {
                setDialogTransaction(tx);
                setIsDialogOpen(true);
              }}
            />
          );
        })
    ) : (
      <div className="no-data">No data</div>
    );

  const filteredTransactionsTotalAmount = filteredTransactionHistory.reduce(
    (acc, initial) => {
      return (acc += initial.amount);
    },
    0,
  );

  const { data: companyInfoData } = useCompanyInfoRetrieveQuery({
    variables: { companyID: loginAuth.companyID },
  });

  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(true);
  return (
    <>
      <HistoryDetailPopup
        isOpen={isDialogOpen}
        tx={selectedTransaction}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <div className="history-page">
        <div className="limit">
          <Card
            elevation={Elevation.TWO}
            style={{ width: "80%", margin: "2% auto" }}
          >
            <div style={styles.subheader}>
              Sisa Limit Di Periode Sekarang:
              <div style={styles.currencyStats}>
                Rp. {rupiahFormatter(allowanceAvailable?.AllowanceRetrieve)}
              </div>
            </div>
          </Card>
        </div>
        <div className="filter">
          <div onClick={() => setShowFilterOptions(!showFilterOptions)}>
            <Icon icon={"filter"} id="icon-filter" />{" "}
            <label htmlFor="icon-filter">Filter</label>
          </div>
          {showFilterOptions && (
            <>
              <DateRangeInput
                className="date-range-input"
                singleMonthOnly={true}
                formatDate={(date) => date.toLocaleDateString()}
                parseDate={(str) => new Date(str)}
                shortcuts={false}
                onChange={(e) => {
                  setStartDateSelected(e[0] || undefined);
                  setEndDateSelected(e[1] || undefined);
                }}
                value={[startDateSelected || null, endDateSelected || null]}
              />
              <Switch
                defaultChecked={true}
                alignIndicator={"right"}
                style={{
                  textAlign: "right",
                  fontSize: "0.8em",
                  margin: "2%",
                  fontStyle: "italic",
                }}
                onClick={() => {
                  setShowRequestsOnly(!showRequestsOnly);
                }}
                label={showRequestsOnly ? "Permintaan" : "Semua Aktivitas"}
              />
            </>
          )}
        </div>
        <div className="history-list">
          {filteredTransactionHistoryComponent}
        </div>
      </div>
    </>
  );
};
