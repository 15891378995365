import { batch } from "react-redux";
import { AttendanceRecord } from "../../_interfaces";
import { AttendanceServices } from "../../_services/attendance.services";
import { LeaveServices } from "../../_services/leave.services";
import { ScheduleServices } from "../../_services/schedule.services";
import GlobalActionTypes from "../global/global.types";
import ClockinActionTypes from "./clockin.types";

const deg2rad = (deg: any) => {
  return deg * (Math.PI / 180);
};

const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const sendAttendance = (
  data: AttendanceRecord,
  userId: string,
  shiftId: string,
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    const { approval, location, status } = state.clockin.masterData;
    const { checkAttendance } = state.clockin;
    try {
      let locationId: string;
      locationId = "";
      location.forEach((item: any) => {
        const distance = getDistance(
          parseFloat(data.lat),
          parseFloat(data.long),
          parseFloat(item.lat),
          parseFloat(item.long),
        );
        if (distance <= 1) locationId = item.id;
      });

      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ClockinActionTypes.SEND_ATTENDANCE });
      });
      data.time = new Date().toLocaleString();
      const pending = approval.filter((item: any) => item.type === "Pending")[0]
        .id;

      let check: any;
      if (userId !== "")
        check = await AttendanceServices.checkAttendance(userId);

      if (!checkAttendance?.is_active && !check?.data?.is_active) {
        const createAttendance = await AttendanceServices.createAttendance(
          shiftId,
          locationId,
          pending,
          userId,
        );
        data.attendance_id = createAttendance.data.id;
        const clockin = await AttendanceServices.record(data);

        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_SUCCESS,
            payload: clockin,
          });
          dispatch({
            type: GlobalActionTypes.SHOW_SUCCESS,
            payload: {
              title: "Absen masuk berhasil",
              desc: "Harap tunggu approval dari HRD",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
            payload: "Anda sudah melakukan absen masuk",
          });
          dispatch({
            type: GlobalActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Anda sudah melakukan absen masuk",
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const sendClockOutAttendance = (
  data: AttendanceRecord,
  workDuration: string,
  userId: string,
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    const { checkAttendance } = state.clockin;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ClockinActionTypes.SEND_ATTENDANCE });
      });
      data.time = new Date().toLocaleString();

      let check: any;
      if (userId !== "")
        check = await AttendanceServices.checkAttendance(userId);

      if (!checkAttendance?.is_active && !check?.data?.is_active) {
        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
            payload: "Anda sudah melakukan absen keluar",
          });
          dispatch({
            type: GlobalActionTypes.SHOW_ALERT,
            payload: {
              alertMessage:
                "Anda belum melakukan absen masuk atau sudah melakukan absen keluar",
              alertType: "error",
            },
          });
        });
      } else {
        const attendances = await AttendanceServices.getClockInData(
          checkAttendance?.attendance_id || check?.data?.attendance_id,
        );
        if (attendances.data.length !== 2) {
          data.attendance_id =
            checkAttendance?.attendance_id || check?.data?.attendance_id;
          const clockin = await AttendanceServices.record(data);
          const updateAttendance = await AttendanceServices.updateAttendance(
            clockin.data.attendance_id,
            workDuration,
          );

          batch(() => {
            dispatch({ type: GlobalActionTypes.HIDE_LOADING });
            dispatch({
              type: ClockinActionTypes.SEND_ATTENDANCE_SUCCESS,
              payload: { clockin, updateAttendance },
            });
            dispatch({
              type: GlobalActionTypes.SHOW_SUCCESS,
              payload: {
                title: "Absen keluar berhasil",
                desc: "",
              },
            });
          });
        } else {
          batch(() => {
            dispatch({ type: GlobalActionTypes.HIDE_LOADING });
            dispatch({
              type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
              payload: "Anda sudah melakukan absen keluar",
            });
            dispatch({
              type: GlobalActionTypes.SHOW_ALERT,
              payload: {
                alertMessage:
                  "Anda belum melakukan absen masuk atau sudah melakukan absen keluar",
                alertType: "error",
              },
            });
          });
        }
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getMasterData = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: ClockinActionTypes.GET_MASTER_DATA });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const status = await AttendanceServices.getStatus();
      const location = await AttendanceServices.getLocation();
      const approval = await LeaveServices.createApproval();

      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_MASTER_DATA_SUCCESS,
          payload: {
            status: status.data.rows,
            location: location.data.rows,
            approval: approval.data.rows,
          },
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_MASTER_DATA_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const getAttendanceData = (isEmployee: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_SCHEDULE_DATA,
        });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const payload: any = {};
      if (isEmployee) {
        const schedule = await ScheduleServices.getCurrentSchedule();
        const detailSchedule = await ScheduleServices.getScheduleData(
          schedule?.data?.rows[0]?.dataShiftId,
        );
        const check = await AttendanceServices.checkAttendance();
        payload.masterSchedule = detailSchedule.data;
        payload.currentSchedule = schedule?.data?.rows[0];
        payload.checkAttendance = check?.data;
        if (check?.data.is_active || check?.data.attendance_id) {
          const clockInOut = await AttendanceServices.getClockInData(
            check?.data.attendance_id,
          );
          payload.clockInOut = clockInOut?.data;
        }
      }
      const listMasterSchedule = await ScheduleServices.getScheduleList();
      payload.masterScheduleList = listMasterSchedule?.data?.rows;

      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_SCHEDULE_DATA_SUCCESS,
          payload: payload,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_SCHEDULE_DATA_FAILURE,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};
