import ClockinActionTypes from "./clockin.types";

interface MasterData {
  status: Array<any>;
  location: Array<any>;
  approval: Array<any>;
}
export interface TimeRange {
  days: number[];
  endTime: string;
  startTime: string;
}

export interface Detail {
  timeRange: TimeRange[];
}

export interface MasterSchedule {
  id: string;
  companyId: string;
  shiftName: string;
  detail: Detail;
  incentiveOperational: number;
  incentiveOvertime: number;
  createdAt: Date;
}

export interface CurrentSchedule {
  id: string;
  companyId: string;
  locationId: string;
  divisionId: string;
  startDate: Date;
  endDate: Date;
  dataShiftId: string;
  assignTo: string[];
  restrictLocation: boolean;
  backupGPS: boolean;
  createdAt: Date;
}

export interface CheckAttendance {
  is_active: boolean;
  attendance_id: string | null;
}

export interface ClockInOut {
  id: string;
  attendance_id: string;
  time: Date;
  type: string;
  lat: string;
  long: string;
  filename: string;
  createdAt: Date;
  updatedAt: Date;
  desc: string;
}

interface ClockinState {
  attendanceStats: string;
  masterData: MasterData;
  masterSchedule: MasterSchedule | null;
  currentSchedule: CurrentSchedule | null;
  checkAttendance: CheckAttendance | null;
  masterScheduleList: MasterSchedule[];
  clockInOut: ClockInOut[];
}

const INITIAL_STATE: ClockinState = {
  attendanceStats: "",
  masterData: {
    status: [],
    location: [],
    approval: [],
  },
  masterSchedule: null,
  currentSchedule: null,
  checkAttendance: null,
  masterScheduleList: [],
  clockInOut: [],
};

const ClockinReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ClockinActionTypes.SEND_ATTENDANCE:
      return {
        ...state,
        attendanceStats: "sending",
      };
    case ClockinActionTypes.SEND_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendanceStats: "success",
      };
    case ClockinActionTypes.SEND_ATTENDANCE_FAILURE:
      return {
        ...state,
        attendanceStats: "failure",
      };
    case ClockinActionTypes.GET_MASTER_DATA:
      return {
        ...state,
        masterData: {
          status: [],
          location: [],
          approval: [],
        },
      };
    case ClockinActionTypes.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        masterData: {
          status: action.payload.status,
          location: action.payload.location,
          approval: action.payload.approval,
        },
      };
    case ClockinActionTypes.GET_MASTER_DATA_FAILURE:
      return {
        ...state,
        masterData: {
          status: [],
          location: [],
          approval: [],
        },
      };
    case ClockinActionTypes.GET_SCHEDULE_DATA:
      return {
        ...state,
      };
    case ClockinActionTypes.GET_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        currentSchedule: action.payload.currentSchedule,
        masterSchedule: action.payload.masterSchedule,
        checkAttendance: action.payload.checkAttendance,
        masterScheduleList: action.payload.masterScheduleList,
        clockInOut: action.payload.clockInOut || [],
      };
    case ClockinActionTypes.GET_SCHEDULE_DATA_FAILURE:
      return {
        ...state,
        currentSchedule: null,
        masterSchedule: null,
        checkAttendance: null,
        masterScheduleList: [],
        clockInOut: [],
      };
    default:
      return state;
  }
};

export default ClockinReducer;
