const ShiftTypes = {
  GET_ALL_SHIFT: "GET_ALL_SHIFT",
  GET_ALL_SHIFT_SUCCESS: "GET_ALL_SHIFT_SUCCESS",
  GET_ALL_SHIFT_FAILURE: "GET_OUTSTATION_OPTIONS_FAILURE",
  SUBMIT_SHIFT_REQUEST: "SUBMIT_SHIFT_REQUEST",
  SUBMIT_SHIFT_REQUEST_SUCCESS: "SUBMIT_SHIFT_REQUEST_SUCCESS",
  SUBMIT_SHIFT_REQUEST_FAILURE: "SUBMIT_SHIFT_REQUEST_FAILURE",
};

export default ShiftTypes;
