/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { PageComponent } from "../../lib";
import { GajikuPage } from "./gajiku";
import "./history.style.scss";
import NoData from "./nodata";

export const HistoryPage: PageComponent = ({
  onTopIconClickRef,
  setTopIconIsDisabled,
}) => {
  const [activePage, setActivePage] = useState<number>(0);

  useEffect(() => {
    setTopIconIsDisabled(false);
  }, []);

  const pages = [
    <GajikuPage onTopIconClickRef={onTopIconClickRef} />,
    <NoData />,
    <NoData />,
    <NoData />,
  ];

  return (
    <div className="history-page">
      <div className="tabs">
        <div
          className={`tab-item ${activePage === 0 && "active"}`}
          onClick={() => setActivePage(0)}
        >
          Gajiku
        </div>
        <div
          className={`tab-item ${activePage === 1 && "active"}`}
          onClick={() => setActivePage(1)}
        >
          Koperasi
        </div>
        <div
          className={`tab-item ${activePage === 2 && "active"}`}
          onClick={() => setActivePage(2)}
        >
          Attendance
        </div>
        <div
          className={`tab-item ${activePage === 3 && "active"}`}
          onClick={() => setActivePage(3)}
        >
          KPI
        </div>
      </div>
      {pages[activePage]}
    </div>
  );
};
