import { batch } from "react-redux";
import { LeaveRecord } from "../../_interfaces";
import { LeaveServices } from "../../_services/leave.services";
import GlobalActionTypes from "../global/global.types";
import PermitActionTypes from "./permit.types";

export const getOptions = () => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PermitActionTypes.GET_OPTIONS });
      });

      const options = await LeaveServices.getLeaveOptions();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.GET_OPTIONS_SUCCESS,
          payload: options.data.rows,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({ type: PermitActionTypes.GET_OPTIONS_FAILURE });
      });
    }
  };
};

export const createLeaveRecord = ({ payload }: { payload: LeaveRecord }) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PermitActionTypes.SUBMIT_LEAVE });
      });

      payload.user_id = userData.userID;
      const approval = await LeaveServices.createApproval();
      const pending = approval.data.rows.filter(
        (item: any) => item.type === "Pending",
      );
      payload.approval_id = pending[0].id;
      const create = await LeaveServices.submitLeave({
        payload,
      });

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.SUBMIT_LEAVE_SUCCESS,
          payload: create,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_SUCCESS,
          payload: {
            title: "Pengajuan izin berhasil",
            desc: "Pengajuan izin telah di ajukan harap tunggu approval dari HRD",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.SUBMIT_LEAVE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};
