import { LeaveRecord } from "../_interfaces";
import request from "../_network/request";
const leaveUrl = "leave";
const leaveRecordUrl = "leave_record";
const approvalUrl = "approval";

const getLeaveOptions = () => {
  return request({
    url: `${leaveUrl}`,
    method: "GET",
  });
};

const getApprovalLeave = () => {
  return request({
    url: `${leaveRecordUrl}/by_user`,
    method: "GET",
  });
};

const submitLeave = ({ payload }: { payload: LeaveRecord }) => {
  const data = new FormData();
  const dateFrom = new Date(payload.date_from);

  data.append("approval_id", payload.approval_id);
  data.append("user_id", payload.user_id);
  data.append("leave_id", payload.leave_id);
  data.append("desc", payload.desc);
  data.append("date_from", dateFrom.toLocaleString());
  data.append("date_to", payload.date_to);
  if (payload.file) data.append("file", payload.file);
  return request({
    url: `${leaveRecordUrl}`,
    method: "POST",
    data: data,
  });
};

const createApproval = () => {
  return request({
    url: approvalUrl,
    method: "GET",
  });
};

export const LeaveServices = {
  getLeaveOptions,
  submitLeave,
  createApproval,
  getApprovalLeave,
};
