import request from "../_network/request";
const shiftRequest = "shift_request";

const getShiftRequest = () => {
  return request({
    url: `${shiftRequest}?fromMobile=true`,
    method: "GET",
  });
};

const postShiftRequest = (params: any) => {
  return request({
    url: `${shiftRequest}`,
    method: "POST",
    data: params,
  });
};

export const ShiftRequestService = {
  postShiftRequest,
  getShiftRequest,
};
