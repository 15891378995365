/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonImage from "../../components/button-selfie";
import MapSection from "../../components/maps-section";
import { sendAttendance } from "../../redux/clock-in/clockin.actions";
import { setHeader, showAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./clockin.style.scss";
const ClockIn = () => {
  const dispatch = useAppDispatch();
  const { image, lat, long, isNearby, currentSchedule } = useAppSelector(
    (state) => ({
      ...state.globalState,
      ...state.clockin,
    }),
  );
  const [desc, setDesc] = useState("");

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Masuk", hasBack: true }));
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (image && image !== "") {
      if (isNearby) {
        dispatch(
          sendAttendance(
            {
              attendance_id: "",
              lat,
              long,
              type: "clock_in",
              time: "123",
              file: image,
              desc: desc,
            },
            "",
            currentSchedule?.dataShiftId,
          ),
        );
      } else {
        dispatch(
          showAlert(
            "Anda tidak sedang berada dalam lingkungan Kantor!",
            "error",
          ),
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="clock-in">
      <MapSection />
      <ButtonImage />
      <div className="container">
        <textarea
          className="reason"
          placeholder="Keterangan"
          onChange={(e: any) => setDesc(e.target.value)}
        />
      </div>
      <button type="submit" className="submit-btn">
        Absen Masuk
      </button>
    </form>
  );
};

export default ClockIn;
