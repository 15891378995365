import React, { FunctionComponent, ReactNode } from "react";
import { AnchorButton, Dialog } from "@blueprintjs/core";
import "./historyDetailPopup.css";
import { formatTransactionDetails, MessageClass } from "./lib";
import { rupiahFormatter } from "../../../../utils/formatters";
import {
  TransactionEvent,
  TransactionEventType,
} from "../../../../backend/app";

const DetailRow: FunctionComponent<{
  name: string;
  value: string;
  valueClass?: MessageClass;
  nameClass?: MessageClass;
}> = ({ name, value, valueClass, nameClass }) => {
  return (
    <div className={`history-detail-row`}>
      <div className={`history-detail-type ${nameClass}`}>{name}</div>
      <div className={`history-detail-value ${valueClass}`}>{value}</div>
    </div>
  );
};

const NumberDetailRow: FunctionComponent<{
  name: string;
  value?: number | null;
  valueClass?: MessageClass;
  nameClass?: MessageClass;
}> = ({ name, value, valueClass, nameClass }) => (
  <DetailRow
    valueClass={valueClass}
    nameClass={nameClass}
    name={name}
    value={`Rp. ${rupiahFormatter(value)}`}
  />
);

export const HistoryDetailPopup: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  tx?: TransactionEvent;
}> = ({ isOpen, onClose, tx }) => {
  if (!tx) {
    return null;
  }
  let title = "";
  const { createdAt, statusMessage, statusClass } =
    formatTransactionDetails(tx);

  const historyRows: ReactNode[] = [];
  switch (tx.typ) {
    case TransactionEventType.Request:
      title = "Request";
      historyRows.push(
        <NumberDetailRow
          key={"req-amount"}
          name={"Amount"}
          value={tx.amount}
        />,
      );
      historyRows.push(
        <NumberDetailRow
          key={"req-fee"}
          name={"Biaya"}
          value={-1 * (tx.fee || 0)}
          valueClass={MessageClass.Danger}
        />,
      );
      historyRows.push(
        <NumberDetailRow
          key={"req-sisa"}
          name={"Yang Diterima"}
          value={tx.principal}
          valueClass={MessageClass.Success}
        />,
      );
      break;
    case TransactionEventType.Allowance:
      title = "Allowance";
      historyRows.push(
        <NumberDetailRow
          key={"req-allowance"}
          name={"Allowance"}
          value={tx.amount}
          valueClass={MessageClass.Success}
        />,
      );
      break;
  }

  const descriptionNode = tx.description ? (
    <>
      <hr className="dialog-hr" />
      <div className={`history-detail-description`}>{tx.description}</div>
      <hr className="dialog-hr" />
    </>
  ) : null;

  return (
    <Dialog
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={"history-detail-popup"}>
        <div className={`history-detail-header`}>
          <div className={`history-detail-title`}>{title}</div>
          <div className={`history-detail-subtitle`}>
            <div>{createdAt}</div>
            <div style={{ fontFamily: "roboto mono" }}>{tx.eventID}</div>
          </div>
          {descriptionNode}
        </div>
        <div className={`history-detail-body`}>{historyRows}</div>
        <div className={`history-detail-footer`}>
          <hr className="dialog-hr" />
          <div className={`history-detail-footer-details`}>
            <div className={`history-detail-status ${statusClass}`}>
              {statusMessage}
            </div>
            <AnchorButton large={true} onClick={onClose}>
              Close
            </AnchorButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
