import request from "../_network/request";
const scheduleURL = "schedule";
const masterScheduleURL = "shift_master";

const getCurrentSchedule = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  return request({
    url: `${scheduleURL}?getFromMobile=true&from=${currentDate}`,
    method: "GET",
  });
};

const getScheduleData = (id: string) => {
  return request({
    url: `${masterScheduleURL}/${id}`,
    method: "GET",
  });
};

const getScheduleList = () => {
  return request({
    url: masterScheduleURL,
    method: "GET",
  });
};

export const ScheduleServices = {
  getCurrentSchedule,
  getScheduleData,
  getScheduleList,
};
