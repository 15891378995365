import React, { FunctionComponent, useRef, useState } from "react";
import { IconNames } from "@blueprintjs/icons";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Icon, Spinner } from "@blueprintjs/core";
import { RequestPage } from "./Pages/Request";
import { ProfilePage } from "./Pages/Profile";
import { HistoryPage } from "./Pages/History";
import { HomePage } from "./Pages/Home";
import { ContactPage } from "./Pages/Contact";
import "./dashboard.css";
import "./dashboard.scss";
import Logo from "../../assets/logo.svg";
import { Icon as Iconify } from "@iconify/react";
import ZendeskController from "./ZendeskChat";

import { IPage } from "./lib";
import UserRoutes from "./Pages/Attendance/_routes/UserRoutes";
import { useAppSelector } from "./Pages/Attendance/redux/hooks";
import SuccessPopup from "./Pages/Attendance/components/success-popup";
import Alert from "./Pages/Attendance/components/alert";
import { Payslip } from "./Pages/Payslip";
import { PayslipDetail } from "./Pages/Payslip/detail";

export const Dashboard: FunctionComponent<{
  baseUrl: string;
}> = ({ baseUrl }) => {
  const { loading, showSuccess } = useAppSelector((state) => state.globalState);
  const hist = useHistory();

  const pages = [
    {
      component: HomePage,
      navIcon: <Iconify icon="ant-design:home-outlined" fr={undefined} />,
      label: "Home",
    },
    {
      component: HistoryPage,
      navIcon: <Iconify icon="fluent:history-16-filled" fr={undefined} />,
      label: "Riwayat",
      topIcon: IconNames.REFRESH,
    },
    {
      component: ProfilePage,
      navIcon: <Iconify icon="ant-design:user-outlined" fr={undefined} />,
      label: "Profile",
    },
    {
      component: ContactPage,
      navIcon: <Iconify icon="akar-icons:chat-question" fr={undefined} />,
      label: "Hubungi Kami",
    },
    {
      component: RequestPage,
      navIcon: (
        <img src={Logo} alt={"gajiku-logo"} style={{ width: "3.2em" }} />
      ),
      label: "Gajiku",
    },
  ] as IPage[];

  const linkWidth = 100 / pages.length;
  const defaultPageIndex = 1;
  const [selectedPage, setSelectedPage] = useState<number>(defaultPageIndex);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const onLoaded = () => {
    return true;
  };

  const onOpen = () => {
    return true;
  };

  const onChatStart = () => {
    return true;
  };

  const onClose = () => {
    setShowHelp(false);
  };

  const handleClick = () => {
    ZendeskController.initChat({
      onLoaded,
      onChatStart,
      onOpen,
      onClose,
    });
  };

  const navButtons = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    const navButtonPress = () => {
      if (page.label === "Hubungi Kami") {
        handleClick();
        setShowHelp(true);
      } else {
        setSelectedPage(index);
        hist.push(pagePath);
      }
    };

    const isSelectedLink = selectedPage === index;

    return (
      <>
        {page.label != "Gajiku" && (
          <div
            key={`nav-link-${page.label}-${index}`}
            className={`link ${isSelectedLink ? "link-selected" : ""}`}
            onClick={navButtonPress}
            style={{ width: `${linkWidth} vw` }}
          >
            <div className="footer-container-icon">
              <div className={"nav-icon"}>{page.navIcon}</div>
            </div>
          </div>
        )}
      </>
    );
  });

  const topIconDisabledStates = pages.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [topIconIsDisabled, setTopIconIsDisabled] = useState<boolean>(false);
    return { topIconIsDisabled, setTopIconIsDisabled };
  });

  const bodies = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    // eslint-disable-next-line react-hooks/rules-of-hooks,@typescript-eslint/no-empty-function
    const onTopIconClickRef = useRef<() => void>(() => {});
    const { topIconIsDisabled, setTopIconIsDisabled } =
      topIconDisabledStates[index];
    return (
      <Route key={`page-path-${page.label}-${index}`} exact path={pagePath}>
        {index > 0 && (
          <div className={"dashboard-header"}>
            <div className={"dashboard-title"}>{page.label}</div>
            <div
              className={"dashboard-reload"}
              onClick={() => onTopIconClickRef.current()}
            >
              {topIconIsDisabled ? (
                <Icon icon={page.topIcon} iconSize={24} />
              ) : (
                <Icon icon={page.topIcon} color={"#555555"} iconSize={20} />
              )}
            </div>
          </div>
        )}
        <div className={index > 0 ? "dashboard-body" : "dashboard-body home"}>
          {page.component({
            baseUrl: pagePath,
            onTopIconClickRef,
            topIconIsDisabled,
            setTopIconIsDisabled,
          })}
        </div>
      </Route>
    );
  });
  return !showHelp ? (
    <div className={"dashboard-container"}>
      <Alert />
      {showSuccess && <SuccessPopup />}
      {loading && (
        <div className="loading">
          <Spinner />
        </div>
      )}
      <Switch>
        {bodies}
        <Route path={"/attendance/"} component={UserRoutes} />
        <Route path={"/payslip/"} exact component={Payslip} />
        <Route path={"/payslip/detail/:id"} exact component={PayslipDetail} />
        <Route path={"/"}>
          <Redirect to={`${baseUrl}/${defaultPageIndex}`} />
        </Route>
      </Switch>
      <div className={"dashboard-footer"}>{navButtons}</div>
    </div>
  ) : null;
};
