import { apiFetch, useAPI } from "../../api/hook";
import { API_ACTION, registerAPIStore } from "../../api/store";
import { LoginCredentialsRequest } from "../models";
import { AuthRole } from "../../backend/app";

export const useLogin = () =>
  useAPI<LoginCredentialsRequest, AuthRole>(
    "/login",
    { userID: "", companyID: "", level: "" } as AuthRole,
    useLoginAPIStore,
  );

export const useLogout = () => {
  const [, dispatch] = useLoginAPIStore();
  return async () => {
    dispatch({ type: API_ACTION.CLEAR_DATA, url: "/login" });
    await apiFetch("/logout", "");
  };
};

export const useLoginAPIStore = registerAPIStore("login");
