import ShiftTypes from "./shiftrequest.types";

interface ShiftRequest {
  dataShift: Array<any>;
}

const INITIAL_STATE: ShiftRequest = {
  dataShift: [],
};

const ShiftRequestReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ShiftTypes.GET_ALL_SHIFT:
      return {
        ...state,
        dataShift: [],
      };
    case ShiftTypes.GET_ALL_SHIFT_SUCCESS:
      return {
        ...state,
        dataShift: action.payload,
      };
    case ShiftTypes.GET_ALL_SHIFT_FAILURE:
      return {
        ...state,
        dataShift: [],
      };
    case ShiftTypes.SUBMIT_SHIFT_REQUEST:
      return {
        ...state,
      };
    case ShiftTypes.SUBMIT_SHIFT_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case ShiftTypes.SUBMIT_SHIFT_REQUEST_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ShiftRequestReducer;
