import OutstationActionTypes from "./outstation.types";

interface ClockinState {
  outstationOptions: Array<any>;
}

const INITIAL_STATE: ClockinState = {
  outstationOptions: [],
};

const OutstationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case OutstationActionTypes.GET_OUTSTATION_OPTIONS:
      return {
        ...state,
        outstationOptions: [],
      };
    case OutstationActionTypes.GET_OUTSTATION_OPTIONS_SUCCESS:
      return {
        ...state,
        outstationOptions: action.payload,
      };
    case OutstationActionTypes.GET_OUTSTATION_OPTIONS_FAILURE:
      return {
        ...state,
        outstationOptions: [],
      };
    case OutstationActionTypes.SUBMIT_OUTSTATION:
      return {
        ...state,
      };
    case OutstationActionTypes.SUBMIT_OUTSTATION_SUCCESS:
      return {
        ...state,
      };
    case OutstationActionTypes.SUBMIT_OUTSTATION_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default OutstationReducer;
