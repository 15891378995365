import GlobalActionTypes from "./global.types";

interface GlobalState {
  title: string;
  hasBack: boolean;
  loading: boolean;
  image: string;
  lat: string;
  long: string;
  address: string;
  isNearby: boolean;
  showSuccess: boolean;
  successTitle: string;
  successDesc: string;
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
}

const INITIAL_STATE: GlobalState = {
  title: "",
  hasBack: false,
  loading: false,
  image: "",
  lat: "",
  long: "",
  address: "",
  isNearby: false,
  showSuccess: false,
  successTitle: "",
  successDesc: "",
  showAlert: false,
  alertMessage: "",
  alertType: "",
};

const GlobalReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GlobalActionTypes.SET_HEADER:
      return {
        ...state,
        title: action.payload.title,
        hasBack: action.payload.hasBack,
      };
    case GlobalActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GlobalActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GlobalActionTypes.SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case GlobalActionTypes.UNSET_IMAGE:
      return {
        ...state,
        image: "",
      };
    case GlobalActionTypes.SET_LOCATION:
      return {
        ...state,
        lat: action.payload.lat,
        long: action.payload.long,
        address: action.payload.address,
        isNearby: action.payload.isNearby,
      };
    case GlobalActionTypes.UNSET_LOCATION:
      return {
        ...state,
        lat: "",
        long: "",
        address: "",
        isNearby: false,
      };
    case GlobalActionTypes.SHOW_SUCCESS:
      return {
        ...state,
        showSuccess: true,
        successTitle: action.payload.title,
        successDesc: action.payload.desc,
      };
    case GlobalActionTypes.HIDE_SUCCESS:
      return {
        ...state,
        showSuccess: false,
        successTitle: "",
        successDesc: "",
      };
    case GlobalActionTypes.SHOW_ALERT:
      return {
        ...state,
        showAlert: true,
        alertMessage: action.payload.alertMessage,
        alertType: action.payload.alertType,
      };
    case GlobalActionTypes.HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
        alertMessage: "",
        alertType: "",
      };
    default:
      return state;
  }
};

export default GlobalReducer;
