import { AttendanceRecord } from "../_interfaces";
import request from "../_network/request";
const attendanceRecordUrl = "attendance_record";
const attendanceUrl = "attendance";

const record = (payload: AttendanceRecord) => {
  const data = new FormData();
  data.append("attendance_id", payload.attendance_id);
  data.append("time", payload.time);
  data.append("type", payload.type);
  data.append("lat", payload.lat);
  data.append("long", payload.long);
  if (payload.desc !== "") {
    data.append("desc", payload.desc);
  }
  if (payload.file !== "") {
    const blobBin = atob(payload.file.split(",")[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    data.append("file", file, `${payload.attendance_id}-${payload.type}.jpeg`);
  }
  return request({
    url: `${attendanceRecordUrl}`,
    method: "POST",
    data: data,
  });
};

const checkAttendance = (userID: string | null = null) => {
  const param = userID ? `?userID=${userID}` : "";
  return request({
    url: `${attendanceUrl}/check${param}`,
    method: "GET",
  });
};

const createAttendance = (
  status_id: string,
  location_id: string,
  approval_id: string,
  userID: string,
) => {
  let data: any;
  if (userID !== "") {
    data = {
      status_id,
      location_id,
      approval_id,
      userID,
    };
  } else {
    data = {
      status_id,
      location_id,
      approval_id,
    };
  }
  return request({
    url: attendanceUrl,
    method: "POST",
    data: data,
  });
};

const updateAttendance = (attendance_id: string, status_id: string) => {
  return request({
    url: `${attendanceUrl}/${attendance_id}`,
    method: "PUT",
    data: {
      is_active: false,
    },
  });
};

const getLocation = () => {
  return request({
    url: "location",
    method: "GET",
  });
};

const getStatus = () => {
  return request({
    url: "status",
    method: "GET",
  });
};

const getClockInData = (id: string) => {
  return request({
    url: `${attendanceRecordUrl}/${id}`,
    method: "GET",
  });
};

export const AttendanceServices = {
  record,
  checkAttendance,
  createAttendance,
  getLocation,
  getStatus,
  updateAttendance,
  getClockInData,
};
