import L from "leaflet";
import Icon from "./building.svg";

const iconBuildings = L.icon({
  iconUrl: Icon,
  iconRetinaUrl: Icon,
  iconSize: new L.Point(40, 55),
});

export { iconBuildings };
