import React from "react";
import { Icon } from "@iconify/react";
import "./zendesk-style.scss";
import ZendeskController from "../Dashboard/ZendeskChat";

const ZendeskButton = () => {
  const onLoaded = () => true;

  const onOpen = () => true;

  const onChatStart = () => true;

  const onClose = () => true;

  const handleClick = () => {
    ZendeskController.initChat({
      onLoaded,
      onChatStart,
      onOpen,
      onClose,
    });
  };
  return (
    <div className="zendesk-button" onClick={handleClick}>
      <Icon icon="fluent:chat-help-20-filled" fr={undefined} width={40} />
    </div>
  );
};

export default ZendeskButton;
