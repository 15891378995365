import React, { FunctionComponent, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import "./updatePasswordDialog.css";
import { useToastHandler } from "../../../../utils/toaster";
import { generateEnterKeyWatcher } from "../../../../utils/keys";
import { useLogin } from "../../../../data/login/api";
import { useUserUpdatePasswordMutation } from "../../../../backend/app";

export const UpdatePasswordDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [, loginAuth] = useLogin();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPassword2, setNewPassword2] = useState<string>("");
  const [oldPassword, setPassword] = useState<string>("");
  const [postNewPassword] = useUserUpdatePasswordMutation();
  const toastHandler = useToastHandler();

  const submit = useCallback(async () => {
    const resp = await toastHandler(async () => {
      if (newPassword !== newPassword2) {
        throw new Error("new passwords are not the same");
      }
      if (newPassword === oldPassword) {
        throw new Error("password unchanged");
      }
      return await postNewPassword({
        variables: {
          userID: loginAuth.userID,
          newPassword,
        },
      });
    });
    if (!resp.error) {
      onClose();
    }
  }, [toastHandler, newPassword, newPassword2, oldPassword, postNewPassword]);

  const onEnter = generateEnterKeyWatcher(submit);

  const submitDisabled =
    !newPassword ||
    newPassword.length < 4 ||
    !newPassword2 ||
    newPassword2.length < 4 ||
    !oldPassword;

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={"update-password-dialog"}>
        <div className={"update-password-header"}>
          <div className={"update-password-title"}>Perbaharui Sandi</div>
        </div>
        <div className={"update-password-body"}>
          <FormGroup label={"Kata Sandi Sekarang"}>
            <InputGroup
              value={oldPassword}
              onChange={({ target: { value } }) => setPassword(value)}
              large={true}
              onKeyPress={onEnter}
            />
          </FormGroup>
          <FormGroup label={"Kata Sandi Baru"}>
            <InputGroup
              value={newPassword}
              onChange={({ target: { value } }) => setNewPassword(value)}
              large={true}
              onKeyPress={onEnter}
            />
          </FormGroup>
          <FormGroup label={"Konfirmasi Kata Sandi Baru"}>
            <InputGroup
              value={newPassword2}
              onChange={({ target: { value } }) => setNewPassword2(value)}
              large={true}
              onKeyPress={onEnter}
            />
          </FormGroup>
        </div>
        <div className={"update-password-footer"}>
          <div className={"update-password-footer-details"}>
            <Button large={true} onClick={onClose}>
              Tutup
            </Button>
            <Button
              disabled={submitDisabled}
              large={true}
              intent={Intent.PRIMARY}
              onClick={submit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
