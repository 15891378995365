import { Button, Card, Elevation } from "@blueprintjs/core";
import React, {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./registrationPage.css";
import {
  RegistrationComponentProps,
  RegistrationComponentRenderer,
} from "../lib";
import { RegisterHandphone } from "./Steps/RegisterHandphone";
import { VerifyOTP } from "./Steps/VerifyOTP";
import { UploadKTP } from "./Steps/UploadKTP";
import { RegisterBankStep } from "./Steps/RegisterBankStep";
import { SetSecurityCode } from "./Steps/SetSecurityCode";
import { AgreeTnc } from "./Steps/AgreeTnc";
import { useToastHandler } from "../../../utils/toaster";
import Logo from "../../../assets/logo.svg";
import ZendeskButton from "../zendesk-button";

const RegistrationStep: FunctionComponent<
  PropsWithChildren<{
    stepIndex: number;
    baseRoute: string;
    loginRoute: string;
    maxStep: number;
    onNextClickRef: MutableRefObject<() => Promise<any>>;
    renderComponent: RegistrationComponentRenderer;
    registrationCompleteRoute: string;
  }>
> = ({
  renderComponent,
  registrationCompleteRoute,
  onNextClickRef,
  loginRoute,
  baseRoute,
  stepIndex,
  maxStep,
}) => {
  const hist = useHistory();
  const [prevIsDisabled, setPrevIsDisabled] = useState<boolean>(false);
  const isFirstStep = stepIndex === 1;
  const [nextIsDisabled, setNextIsDisabled] = useState<boolean>(true);
  const isLastStep = stepIndex >= maxStep;
  const toastHandler = useToastHandler();
  const onNextClick = async () => {
    const resp = await toastHandler(async () => {
      return await onNextClickRef.current();
    });
    if (resp && !resp.error) {
      hist.push(`${baseRoute}/${stepIndex + 1}`);
    }
  };
  const onSubmitClick = async () => {
    const resp = await toastHandler(async () => {
      return await onNextClickRef.current();
    });
    if (resp && !resp.error) {
      hist.push(registrationCompleteRoute);
    }
  };
  return (
    <div className="registration-step">
      <Card elevation={Elevation.FOUR}>
        <div>
          {renderComponent({
            setPrevIsDisabled,
            setNextIsDisabled,
          } as RegistrationComponentProps)}
        </div>
        <div className="footer">
          {isLastStep ? (
            <Button
              className="button"
              disabled={nextIsDisabled}
              onClick={onSubmitClick}
            >
              Ok
            </Button>
          ) : (
            <Button
              className="button"
              disabled={nextIsDisabled}
              onClick={onNextClick}
            >
              Berikutnya
            </Button>
          )}
          {isFirstStep ? (
            <Button onClick={() => hist.push(loginRoute)}>Cancel</Button>
          ) : (
            <Button
              disabled={prevIsDisabled}
              onClick={() => hist.push(`${baseRoute}/${stepIndex - 1}`)}
            >
              Kembali
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

const RegistrationPage: FunctionComponent<{
  baseRoute: string;
  loginRoute: string;
  registrationCompleteRoute: string;
}> = ({ baseRoute, loginRoute, registrationCompleteRoute }) => {
  const [registrationToken, setRegistrationToken] = useState<string>("");
  const [invitationName, setInvitationName] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onNextClickRef = useRef<() => Promise<any>>(async () => {});

  const registrationBackendProps = {
    registrationToken,
    setRegistrationToken,
    onNextClickRef,
  } as RegistrationComponentProps;

  const pages = [
    (props) => <AgreeTnc {...props} {...registrationBackendProps} />,
    (props) => <RegisterHandphone {...props} {...registrationBackendProps} />,
    (props) => (
      <VerifyOTP
        {...props}
        {...registrationBackendProps}
        setInvitationName={setInvitationName}
      />
    ),
    (props) => (
      <UploadKTP
        {...props}
        {...registrationBackendProps}
        invitationName={invitationName}
      />
    ),
    (props) => <RegisterBankStep {...props} {...registrationBackendProps} />,
    (props) => <SetSecurityCode {...props} {...registrationBackendProps} />,
  ] as RegistrationComponentRenderer[];

  const nodes = pages.map((renderComponent, pageIndex) => {
    return (
      <Route
        key={`route-registration-step-${pageIndex}`}
        path={`${baseRoute}/${pageIndex + 1}`}
      >
        <RegistrationStep
          maxStep={pages.length}
          baseRoute={baseRoute}
          stepIndex={pageIndex + 1}
          renderComponent={renderComponent}
          loginRoute={loginRoute}
          onNextClickRef={onNextClickRef}
          registrationCompleteRoute={registrationCompleteRoute}
        />
        <ZendeskButton />
      </Route>
    );
  });
  return <Switch>{nodes}</Switch>;
};

export default RegistrationPage;
