import {
  TransactionEvent,
  TransactionEventType,
} from "../../../../backend/app";
import PCFS from "../../../../backend/PCFS";
import { dateFormatter } from "../../../../utils/formatters";

export enum MessageClass {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Neutral = "",
}

export const formatTransactionDetails: (tx: TransactionEvent) => {
  title: string;
  status: string;
  amount: number;
  createdAt: string;
  messageClass: MessageClass;
  statusClass: MessageClass;
  statusMessage: string;
} = (tx) => {
  let amount = tx.amount;
  let title = "Allowance";

  let statusClass: MessageClass = MessageClass.Neutral;
  let messageClass: MessageClass = MessageClass.Neutral;
  let statusMessage = "";

  switch (tx.typ) {
    case TransactionEventType.Allowance:
      messageClass = MessageClass.Success;
      statusClass = MessageClass.Success;
      // Allowance is always successfully sent
      break;
    case TransactionEventType.Request:
      messageClass = MessageClass.Neutral;
      amount *= -1;
      title = "Request";
      break;
  }

  switch (tx.status) {
    case PCFS.SUCCESS:
      statusClass = MessageClass.Success;
      statusMessage = "Terkirim";
      break;
    case PCFS.FAILED:
    case PCFS.CANCELLED:
      statusClass = MessageClass.Danger;
      statusMessage = "Gagal";
      break;
    case PCFS.PROCESSING:
      statusClass = MessageClass.Warning;
      statusMessage = "Sedang Diproses";
      break;
  }
  return {
    title,
    status,
    amount,
    createdAt: dateFormatter(tx.createdAt) || "",
    statusMessage,
    statusClass,
    messageClass,
  };
};

export const checkIfWithinRange = (
  targetRowCreatedDate: string | undefined,
  startDate: string,
  endDate: string,
) => {
  if (targetRowCreatedDate) {
    if (startDate === "" && endDate === "") {
      // With no selection, return true
      return true;
    } else if (startDate && endDate) {
      return (
        targetRowCreatedDate >= startDate && targetRowCreatedDate <= endDate
      );
    } else if (startDate && !endDate) {
      return targetRowCreatedDate >= startDate;
    } else if (!startDate && endDate) {
      return targetRowCreatedDate <= endDate;
    }
  }
  return true;
};

export const checkIfRequestsOnly = (
  targetRowType: TransactionEventType,
  showRequestsOnly: boolean,
) => {
  if (showRequestsOnly) {
    return targetRowType === TransactionEventType.Request;
  } else return true;
};
