/* GRAPHQL_VERSION: 664cbb9 */
import BankCode from "./BankCode";
import PCFS from "./PCFS";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  AuthRoleLevel: any;
  BankCode: BankCode;
  Bool: any;
  Context: any;
  Historical: any;
  JSON: any;
  Map: any;
  PCFS: PCFS;
  Time: Date;
  Upload: File;
  Void: any;
};

export type AdditionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  type: PayrollAddDeductType;
  date: Scalars["Time"];
};

export type AllowanceRequest = {
  __typename?: "AllowanceRequest";
  requestID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  amount: Scalars["Int"];
  fee: Scalars["Int"];
  principal: Scalars["Int"];
  idempotencyKey: Scalars["String"];
  status: Scalars["PCFS"];
  createdAt?: Maybe<Scalars["Time"]>;
};

export type AuthRole = {
  __typename?: "AuthRole";
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  level: Scalars["AuthRoleLevel"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  bankKey: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  verificationStatus: BankAccountVerificationStatus;
};

export enum BankAccountVerificationStatus {
  Pending = "PENDING",
  Valid = "VALID",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  InvalidSuspectedAccount = "INVALID_SUSPECTED_ACCOUNT",
  InvalidBlackListed = "INVALID_BLACK_LISTED",
  InvalidUnknown = "INVALID_UNKNOWN",
}

export type CompanyBalance = {
  __typename?: "CompanyBalance";
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
  refillDate: Scalars["Time"];
};

export type CompanyBalanceAdd = {
  companyID: Scalars["ID"];
  amountToAdd: Scalars["Int"];
  date: Scalars["Time"];
};

export type CompanyBalanceDeduct = {
  companyID: Scalars["ID"];
  amountToDeduct: Scalars["Int"];
  date: Scalars["Time"];
};

export type CompanyEmployee = {
  __typename?: "CompanyEmployee";
  userID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  username: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  birthday?: Maybe<Scalars["Time"]>;
  name: Scalars["String"];
  lastName: Scalars["String"];
  type: EmploymentType;
  contract: ContractType;
  employeeID?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  ktpUrl: Scalars["String"];
  ktpNum: Scalars["String"];
  npwpNum: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  bankVerificationStatus: BankAccountVerificationStatus;
  wageRequestAllowed: Scalars["Boolean"];
  payrollAllowed: Scalars["Boolean"];
  attendanceAllowed: Scalars["Boolean"];
  attendanceOnOff: Scalars["Boolean"];
  shiftManagementOnOff: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Time"]>;
  endDate?: Maybe<Scalars["Time"]>;
  locationName: Scalars["String"];
  locationCode: Scalars["String"];
  customData: Scalars["Map"];
};

export type CompanyEmployeeInput = {
  companyID: Scalars["ID"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  birthday?: Maybe<Scalars["Time"]>;
  name: Scalars["String"];
  lastName: Scalars["String"];
  type: EmploymentType;
  contract: ContractType;
  employeeID?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  ktpNum: Scalars["String"];
  npwpNum: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  password: Scalars["String"];
  wageRequestAllowed: Scalars["Boolean"];
  payrollAllowed: Scalars["Boolean"];
  attendanceAllowed: Scalars["Boolean"];
  attendanceOnOff: Scalars["Boolean"];
  shiftManagementOnOff: Scalars["Boolean"];
  startDate: Scalars["Time"];
  endDate: Scalars["Time"];
  locationName: Scalars["String"];
  locationCode: Scalars["String"];
  customData: Scalars["Map"];
};

export type CompanyEmployeeUpdate = {
  userID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  phoneNumber?: Maybe<Scalars["Int"]>;
  monthlyLimit?: Maybe<Scalars["Int"]>;
  birthday?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  type?: Maybe<EmploymentType>;
  contract?: Maybe<ContractType>;
  employeeID?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  ktpNum?: Maybe<Scalars["String"]>;
  npwpNum?: Maybe<Scalars["String"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  wageRequestAllowed?: Maybe<Scalars["Boolean"]>;
  payrollAllowed?: Maybe<Scalars["Boolean"]>;
  attendanceAllowed?: Maybe<Scalars["Boolean"]>;
  attendanceOnOff?: Maybe<Scalars["Boolean"]>;
  shiftManagementOnOff?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  locationCode?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export type CompanyInfo = {
  __typename?: "CompanyInfo";
  companyID: Scalars["ID"];
  name: Scalars["String"];
  ewaFee: Scalars["String"];
  startPayPeriodDay: Scalars["Int"];
  endPayPeriodDay: Scalars["Int"];
  wageAllowanceAutoAccruingDaily: Scalars["Boolean"];
  /** Employees can't get access */
  phoneAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Admin dashboard enablement */
  dashboardEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request allowance */
  wageRequestEnabled?: Maybe<Scalars["Boolean"]>;
  /** allowance requests are automatically getting disbursed immediately */
  allowanceRequestAutoDisbursing: Scalars["Boolean"];
  /** minimumRequestAmount is the minimum amount that an employee is allowed to request */
  minimumRequestAmount?: Maybe<Scalars["Int"]>;
  /** wageImbursementPercentLimit is the percent limit an employee is allocated */
  wageImbursementPercentLimit: Scalars["Int"];
  /** Employees can't request Payroll */
  payrollEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request Attendance */
  attendanceEnabled?: Maybe<Scalars["Boolean"]>;
};

export type CompanySettingsInput = {
  name?: Maybe<Scalars["String"]>;
  /** Inclusive start of pay period */
  startPayPeriodDay?: Maybe<Scalars["Int"]>;
  /**
   * Exclusive end of pay period.
   * If the end falls on a day greater than 26, will attempt to compensate by
   * calculating the closest date
   */
  endPayPeriodDay?: Maybe<Scalars["Int"]>;
  wageAllowanceAutoAccruingDaily?: Maybe<Scalars["Boolean"]>;
  ewaFee?: Maybe<Scalars["String"]>;
  /** Employees can't get access */
  phoneAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Admin dashboard enablement */
  dashboardEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request allowance */
  wageRequestEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request Payroll */
  payrollEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request Attendance */
  attendanceEnabled?: Maybe<Scalars["Boolean"]>;
  allowanceRequestAutoDisbursing?: Maybe<Scalars["Boolean"]>;
  minimumRequestAmount?: Maybe<Scalars["Int"]>;
  wageImbursementPercentLimit?: Maybe<Scalars["Int"]>;
};

export enum ContractType {
  Permanent = "PERMANENT",
  NonPermanent = "NON_PERMANENT",
}

export enum DashboardType {
  Disbursals = "DISBURSALS",
  Payroll = "PAYROLL",
  Transactions = "TRANSACTIONS",
  Employees = "EMPLOYEES",
  Companies = "COMPANIES",
  Invitations = "INVITATIONS",
  Attendances = "ATTENDANCES",
}

export type DeductionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  type: PayrollAddDeductType;
  date: Scalars["Time"];
};

export type EmployeePeriodAddition = {
  __typename?: "EmployeePeriodAddition";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  type: PayrollAddDeductType;
  date: Scalars["Time"];
};

export type EmployeePeriodDaysWorked = {
  __typename?: "EmployeePeriodDaysWorked";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  daysWorked: Scalars["Int"];
};

export type EmployeePeriodDeduction = {
  __typename?: "EmployeePeriodDeduction";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  type: PayrollAddDeductType;
  date: Scalars["Time"];
};

export enum EmploymentType {
  Outsource = "OUTSOURCE",
  Insource = "INSOURCE",
}

export type FixedAdditionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type FixedDeductionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type FixedEmployeeAddition = {
  __typename?: "FixedEmployeeAddition";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type FixedEmployeeDeduction = {
  __typename?: "FixedEmployeeDeduction";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type LogActivity = {
  __typename?: "LogActivity";
  periodID: Scalars["ID"];
  status: Scalars["PCFS"];
};

export type Membership = {
  __typename?: "Membership";
  memberID: Scalars["ID"];
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  startDate?: Maybe<Scalars["Time"]>;
  endDate?: Maybe<Scalars["Time"]>;
  isManager: Scalars["Boolean"];
  employmentType: EmploymentType;
  contract: ContractType;
  payrollAllowed: Scalars["Boolean"];
  attendanceAllowed: Scalars["Boolean"];
  attendanceOnOff: Scalars["Boolean"];
  shiftManagementOnOff: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  AdminCreate: UserResponse;
  AllowanceAdd: Scalars["Int"];
  AllowanceRequestApprove: AllowanceRequest;
  AllowanceRequestCreate: Scalars["Int"];
  AllowanceRequestDeny: AllowanceRequest;
  CompanyCreate: CompanyInfo;
  CompanyEmployeeAttendanceOnOffSet?: Maybe<Scalars["Void"]>;
  CompanyEmployeeAttendanceSet?: Maybe<Scalars["Void"]>;
  CompanyEmployeeCreate: CompanyEmployee;
  CompanyEmployeePayrollSet?: Maybe<Scalars["Void"]>;
  CompanyEmployeeShiftManagementOnOFfSet?: Maybe<Scalars["Void"]>;
  CompanyEmployeeUpdate: CompanyEmployee;
  CompanyManagerCreate: Membership;
  CompanyManagerSet?: Maybe<Scalars["Void"]>;
  CompanyMembershipCreate: Membership;
  CompanyUpdate: CompanyInfo;
  HelloWorldM: Scalars["String"];
  PasswordReset: Scalars["String"];
  PasswordResetSendOtp: Scalars["String"];
  PasswordResetVerifyOtp: Scalars["String"];
  PasswordUpdate?: Maybe<Scalars["Void"]>;
  PayrollAdditionCreate?: Maybe<Scalars["Void"]>;
  PayrollCompanyBalanceAddition?: Maybe<Scalars["Void"]>;
  PayrollCompanyBalanceDeduction?: Maybe<Scalars["Void"]>;
  PayrollDeductionCreate?: Maybe<Scalars["Void"]>;
  PayrollEmployeeCreate: PayrollEmployee;
  PayrollEmployeeUpdate: PayrollEmployee;
  PayrollFixedAdditionCreate?: Maybe<Scalars["Void"]>;
  PayrollFixedDeductionCreate?: Maybe<Scalars["Void"]>;
  PayrollMultipleAdditionsCreate?: Maybe<Scalars["Void"]>;
  PayrollMultipleDeductionsCreate?: Maybe<Scalars["Void"]>;
  PayrollPeriodMemberExecute?: Maybe<Scalars["Void"]>;
  PayrollRequestWageAndDisburse?: Maybe<Scalars["Void"]>;
  PayrollTableDataUpdate: PayrollTableData;
  PayrollTopUpAdd: Scalars["Int"];
  /** PhoneInvitation will idempotently create an invitation for a phone number */
  PhoneInvitationCreate: PhoneInvitation;
  PhoneInvitationDelete?: Maybe<Scalars["Void"]>;
  /** PhoneOtpCreate will create a manual otp for the phone number. Must include country code */
  PhoneOtpCreate?: Maybe<Scalars["Void"]>;
  PhoneOtpDelete?: Maybe<Scalars["Void"]>;
  RegistrationSendOTP: Scalars["String"];
  RegistrationSubmitBank: Scalars["String"];
  RegistrationSubmitInfo: Scalars["String"];
  RegistrationSubmitPassword: RegistrationInfo;
  RegistrationVerifyOTP: RegistrationInfo;
  UserCreate: UserResponse;
  UserEducationInfoCreate: UserEducationInfo;
  UserEducationInfoUpdate: UserEducationInfo;
  UserFamilyInfoCreate: UserFamilyInfo;
  UserFamilyInfoUpdate: UserFamilyInfo;
  UserProfileCreate?: Maybe<Scalars["Void"]>;
  UserProfileUpdate?: Maybe<Scalars["Void"]>;
  UserUpdatePassword?: Maybe<Scalars["Void"]>;
};

export type MutationAdminCreateArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationAllowanceAddArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
};

export type MutationAllowanceRequestApproveArgs = {
  idempotencyKey: Scalars["String"];
};

export type MutationAllowanceRequestCreateArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
};

export type MutationAllowanceRequestDenyArgs = {
  idempotencyKey: Scalars["String"];
};

export type MutationCompanyCreateArgs = {
  inp: CompanySettingsInput;
};

export type MutationCompanyEmployeeAttendanceOnOffSetArgs = {
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  onOff: Scalars["Boolean"];
};

export type MutationCompanyEmployeeAttendanceSetArgs = {
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  attendanceAllowed: Scalars["Boolean"];
};

export type MutationCompanyEmployeeCreateArgs = {
  inp: CompanyEmployeeInput;
};

export type MutationCompanyEmployeePayrollSetArgs = {
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  payrollAllowed: Scalars["Boolean"];
};

export type MutationCompanyEmployeeShiftManagementOnOFfSetArgs = {
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  onOff: Scalars["Boolean"];
};

export type MutationCompanyEmployeeUpdateArgs = {
  companyID: Scalars["ID"];
  update: CompanyEmployeeUpdate;
};

export type MutationCompanyManagerCreateArgs = {
  companyID: Scalars["ID"];
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationCompanyManagerSetArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  isManager: Scalars["Boolean"];
};

export type MutationCompanyMembershipCreateArgs = {
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
};

export type MutationCompanyUpdateArgs = {
  companyID: Scalars["ID"];
  inp: CompanySettingsInput;
};

export type MutationHelloWorldMArgs = {
  companyID: Scalars["ID"];
};

export type MutationPasswordResetArgs = {
  token: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationPasswordResetSendOtpArgs = {
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
};

export type MutationPasswordResetVerifyOtpArgs = {
  token: Scalars["String"];
  otpCode: Scalars["String"];
};

export type MutationPasswordUpdateArgs = {
  userID: Scalars["ID"];
  newPassword: Scalars["String"];
};

export type MutationPayrollAdditionCreateArgs = {
  inp: AdditionInsert;
};

export type MutationPayrollCompanyBalanceAdditionArgs = {
  inp: CompanyBalanceAdd;
};

export type MutationPayrollCompanyBalanceDeductionArgs = {
  inp: CompanyBalanceDeduct;
};

export type MutationPayrollDeductionCreateArgs = {
  inp: DeductionInsert;
};

export type MutationPayrollEmployeeCreateArgs = {
  inp: PayrollEmployeeCreate;
};

export type MutationPayrollEmployeeUpdateArgs = {
  memberID: Scalars["ID"];
  inp: PayrollEmployeeCreate;
};

export type MutationPayrollFixedAdditionCreateArgs = {
  inp: FixedAdditionInsert;
};

export type MutationPayrollFixedDeductionCreateArgs = {
  inp: FixedDeductionInsert;
};

export type MutationPayrollMultipleAdditionsCreateArgs = {
  inp: Array<Maybe<AdditionInsert>>;
};

export type MutationPayrollMultipleDeductionsCreateArgs = {
  inp: Array<Maybe<DeductionInsert>>;
};

export type MutationPayrollPeriodMemberExecuteArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type MutationPayrollRequestWageAndDisburseArgs = {
  inp: PayrollWageRequestInsert;
};

export type MutationPayrollTableDataUpdateArgs = {
  memberID: Scalars["ID"];
  inp: PayrollTableDataInsert;
};

export type MutationPayrollTopUpAddArgs = {
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
};

export type MutationPhoneInvitationCreateArgs = {
  companyID: Scalars["ID"];
  insert: PhoneInvitationInsert;
};

export type MutationPhoneInvitationDeleteArgs = {
  inviteID: Scalars["ID"];
};

export type MutationPhoneOtpCreateArgs = {
  phoneNumber: Scalars["Int"];
  otpCode: Scalars["String"];
};

export type MutationPhoneOtpDeleteArgs = {
  phoneNumber: Scalars["Int"];
};

export type MutationRegistrationSendOtpArgs = {
  token: Scalars["String"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
};

export type MutationRegistrationSubmitBankArgs = {
  token: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
};

export type MutationRegistrationSubmitInfoArgs = {
  token: Scalars["String"];
  ktp: Scalars["Upload"];
  birthday: Scalars["Time"];
};

export type MutationRegistrationSubmitPasswordArgs = {
  token: Scalars["String"];
  password: Scalars["String"];
};

export type MutationRegistrationVerifyOtpArgs = {
  token: Scalars["String"];
  otpCode: Scalars["String"];
};

export type MutationUserCreateArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUserEducationInfoCreateArgs = {
  inp: UserEducationInfoInput;
};

export type MutationUserEducationInfoUpdateArgs = {
  inp: UserEducationInfoUpdate;
};

export type MutationUserFamilyInfoCreateArgs = {
  inp: UserFamilyInfoInput;
};

export type MutationUserFamilyInfoUpdateArgs = {
  famInfID: Scalars["ID"];
  inp: UserFamilyInfoUpdate;
};

export type MutationUserProfileCreateArgs = {
  inp: UserProfileUpdate;
};

export type MutationUserProfileUpdateArgs = {
  userID: Scalars["ID"];
  update: UserProfileUpdate;
};

export type MutationUserUpdatePasswordArgs = {
  userID: Scalars["String"];
  newPassword: Scalars["String"];
};

export enum Ptkp {
  Tk_0 = "TK_0",
  Tk_1 = "TK_1",
  Tk_2 = "TK_2",
  Tk_3 = "TK_3",
  K_0 = "K_0",
  K_1 = "K_1",
  K_2 = "K_2",
  K_3 = "K_3",
  KI_0 = "K_I_0",
  KI_1 = "K_I_1",
  KI_2 = "K_I_2",
  KI_3 = "K_I_3",
}

export enum PayrollAddDeductType {
  Fixed = "FIXED",
  ByAttendance = "BY_ATTENDANCE",
  Restarting = "RESTARTING",
  OneTime = "ONE_TIME",
}

export enum PayrollAdditionIncentive {
  Operational = "OPERATIONAL",
  LebihJam = "LEBIH_JAM",
  Jabatan = "JABATAN",
  UHadir = "U_HADIR",
  Transport = "TRANSPORT",
  Resiko = "RESIKO",
  Fungsional = "FUNGSIONAL",
  Lo = "LO",
  Khusus = "KHUSUS",
  Bbm = "BBM",
  BHead = "B_HEAD",
  Pulsa = "PULSA",
  Sales = "SALES",
  BpjsTk = "BPJS_TK",
  BpjsKes = "BPJS_KES",
  BpjsTf = "BPJS_TF",
  Jpk3 = "JPK3",
}

export enum PayrollBankAccountVerificationStatus {
  Pending = "PENDING",
  Valid = "VALID",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  InvalidSuspectedAccount = "INVALID_SUSPECTED_ACCOUNT",
  InvalidBlackListed = "INVALID_BLACK_LISTED",
  InvalidUnknown = "INVALID_UNKNOWN",
}

export enum PayrollDeductionIncentive {
  Pph = "PPH",
  Pinjaman = "PINJAMAN",
  KasbonSementara = "KASBON_SEMENTARA",
  Sppbg = "SPPBG",
  Jpk3Karyawan = "JPK3_KARYAWAN",
  Jpk3Keluarga = "JPK3_KELUARGA",
  BpjsTkPerusahaan = "BPJS_TK_PERUSAHAAN",
  BpjsTkKaryawan = "BPJS_TK_KARYAWAN",
  BpjsKesPerusahaan = "BPJS_KES_PERUSAHAAN",
  BpjsKesKaryawan = "BPJS_KES_KARYAWAN",
  BpjsTfKaryawan = "BPJS_TF_KARYAWAN",
}

export type PayrollEmployee = {
  __typename?: "PayrollEmployee";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  name: Scalars["String"];
  taxTkRate: Scalars["Int"];
  taxKesRate: Scalars["Int"];
  taxTFRate: Scalars["Int"];
  salaryType: PayrollSalaryType;
  salary: Scalars["Int"];
  dailyRateOverride?: Maybe<Scalars["Int"]>;
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  onBehalfOf: Scalars["String"];
  statusPTKP: Scalars["String"];
  customData: Scalars["Map"];
};

export type PayrollEmployeeCreate = {
  companyID: Scalars["ID"];
  name: Scalars["String"];
  taxTkRate?: Maybe<Scalars["Int"]>;
  taxKesRate?: Maybe<Scalars["Int"]>;
  taxTFRate?: Maybe<Scalars["Int"]>;
  salaryType?: Maybe<PayrollSalaryType>;
  salary?: Maybe<Scalars["Int"]>;
  dailyRateOverride?: Maybe<Scalars["Int"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  onBehalfOf?: Maybe<Scalars["String"]>;
  statusPTKP?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export type PayrollPreviewTableData = {
  __typename?: "PayrollPreviewTableData";
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  totalEmployees: Scalars["Int"];
  totalPayroll: Scalars["Int"];
};

export enum PayrollSalaryType {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export type PayrollTableData = {
  __typename?: "PayrollTableData";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  taxTkRate?: Maybe<Scalars["Int"]>;
  taxKesRate?: Maybe<Scalars["Int"]>;
  taxTFRate?: Maybe<Scalars["Int"]>;
  salaryType: PayrollSalaryType;
  salary?: Maybe<Scalars["Int"]>;
  customData?: Maybe<Scalars["Map"]>;
  daysWorked?: Maybe<Scalars["Int"]>;
  phoneNumber?: Maybe<Scalars["Int"]>;
  totalAdditions: Scalars["Int"];
  totalDeductions: Scalars["Int"];
  status?: Maybe<Scalars["PCFS"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankVerificationStatus: PayrollBankAccountVerificationStatus;
};

export type PayrollTableDataInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  taxTkRate?: Maybe<Scalars["Int"]>;
  taxKesRate?: Maybe<Scalars["Int"]>;
  taxTFRate?: Maybe<Scalars["Int"]>;
  salaryType?: Maybe<PayrollSalaryType>;
  salary?: Maybe<Scalars["Int"]>;
  customData?: Maybe<Scalars["Map"]>;
  daysWorked?: Maybe<Scalars["Int"]>;
  phoneNumber?: Maybe<Scalars["Int"]>;
  totalAdditions?: Maybe<Scalars["Int"]>;
  totalDeductions?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["PCFS"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankVerificationStatus?: Maybe<PayrollBankAccountVerificationStatus>;
};

export type PayrollWageRequest = {
  __typename?: "PayrollWageRequest";
  requestID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  amount: Scalars["Int"];
  status: Scalars["PCFS"];
  createdAt?: Maybe<Scalars["Time"]>;
  disbursedAt?: Maybe<Scalars["Time"]>;
};

export type PayrollWageRequestInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type Payslip = {
  __typename?: "Payslip";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  additions: Array<Maybe<EmployeePeriodAddition>>;
  deductions: Array<Maybe<EmployeePeriodDeduction>>;
  amount: Scalars["Int"];
  startTime: Scalars["Time"];
  endTime: Scalars["Time"];
  createdAt?: Maybe<Scalars["Time"]>;
  disbursedAt?: Maybe<Scalars["Time"]>;
};

export type Period = {
  __typename?: "Period";
  periodID: Scalars["ID"];
  companyID: Scalars["ID"];
  startTime: Scalars["Time"];
  endTime: Scalars["Time"];
};

export type PeriodData = {
  __typename?: "PeriodData";
  periodID: Scalars["ID"];
  startTime: Scalars["Time"];
  endTime: Scalars["Time"];
};

export type PhoneInvitation = {
  __typename?: "PhoneInvitation";
  inviteID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  name: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  bankCode?: Maybe<Scalars["BankCode"]>;
  bankNumber?: Maybe<Scalars["String"]>;
  accepted: Scalars["Boolean"];
  customData: Scalars["Map"];
};

export type PhoneInvitationInsert = {
  companyID: Scalars["ID"];
  name: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  bankCode?: Maybe<Scalars["BankCode"]>;
  bankNumber?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export type Query = {
  __typename?: "Query";
  AllowanceRequestFee: Scalars["Int"];
  AllowanceRequestMinimum: Scalars["Int"];
  AllowanceRequestRetrieveChannelResponses?: Maybe<Array<Scalars["String"]>>;
  AllowanceRequestRetrieveUnapproved: Array<Maybe<AllowanceRequest>>;
  AllowanceRetrieve: Scalars["Int"];
  AuthRoleRetrieve: AuthRole;
  BankAccountRetrieveDefault: BankAccount;
  CompanyEmployeeRetrieve: CompanyEmployee;
  CompanyEmployeesRetrieve: Array<Maybe<CompanyEmployee>>;
  CompanyInfoRetrieve: CompanyInfo;
  CompanyInfoRetrieveAll: Array<Maybe<CompanyInfo>>;
  CompanyMembershipRetrieve: Membership;
  CompanyMembershipRetrieveAll: Array<Maybe<Membership>>;
  CompanyMembershipsReadAll: Array<Maybe<Membership>>;
  DashboardsVisibleRetrieve: Array<DashboardType>;
  FamilyInfoReadAllByCompany: Array<Maybe<UserFamilyInfo>>;
  FlipBalance: Scalars["Int"];
  FlipTransactionsJSON: Array<Scalars["String"]>;
  HelloWorldQ: Scalars["String"];
  PayrollAdditionsReadAll: Array<Maybe<EmployeePeriodAddition>>;
  PayrollCompanyBalanceRead: CompanyBalance;
  PayrollCompanyPayslipsRead: Array<Maybe<Payslip>>;
  PayrollDaysWorkedReadAll: Array<Maybe<EmployeePeriodDaysWorked>>;
  PayrollDeductionsReadAll: Array<Maybe<EmployeePeriodDeduction>>;
  PayrollEmployeePayslipsRangeRead: Array<Maybe<Payslip>>;
  PayrollEmployeePayslipsRead: Payslip;
  PayrollEmployeePayslipsReadAll: Array<Maybe<Payslip>>;
  PayrollEmployeeRead: PayrollEmployee;
  PayrollEmployeesReadAll: Array<Maybe<PayrollEmployee>>;
  PayrollFixedAdditionsReadAll: Array<Maybe<FixedEmployeeAddition>>;
  PayrollFixedDeductionsReadAll: Array<Maybe<FixedEmployeeDeduction>>;
  PayrollLogActivityRetrieveAll: Array<Maybe<LogActivity>>;
  PayrollPeriodDataRead: Array<Maybe<PeriodData>>;
  PayrollPreviewTableDataByPeriod: PayrollPreviewTableData;
  PayrollTableDataByPeriodAll: Array<Maybe<PayrollTableData>>;
  PayrollTopUpRetrieve: Scalars["Int"];
  PeriodRetrieve: Period;
  PeriodsRetrieveAll: Array<Maybe<Period>>;
  PeriodsRetrieveAllWithKey: Array<Maybe<Period>>;
  PhoneInvitationsRetrieve: Array<PhoneInvitation>;
  TransactionEventRetrieveByCompanyID: Array<Maybe<TransactionEvent>>;
  TransactionEventRetrieveByUserID: Array<Maybe<TransactionEvent>>;
  UserEducationInfoRetrieve: UserEducationInfo;
  UserFamilyInfoReadAll: Array<Maybe<UserFamilyInfo>>;
  UserFamilyInfoReadAllByCompany: Array<Maybe<UserFamilyInfo>>;
  UserProfileRetrieve: UserProfile;
  UserRetrieveByUsername: UserResponse;
};

export type QueryAllowanceRequestFeeArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
};

export type QueryAllowanceRequestMinimumArgs = {
  userID: Scalars["String"];
};

export type QueryAllowanceRequestRetrieveChannelResponsesArgs = {
  requestID: Scalars["ID"];
};

export type QueryAllowanceRetrieveArgs = {
  userID: Scalars["String"];
};

export type QueryBankAccountRetrieveDefaultArgs = {
  userID: Scalars["ID"];
};

export type QueryCompanyEmployeeRetrieveArgs = {
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
};

export type QueryCompanyEmployeesRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryCompanyInfoRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryCompanyMembershipRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryCompanyMembershipRetrieveAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryDashboardsVisibleRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryFamilyInfoReadAllByCompanyArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollAdditionsReadAllArgs = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollCompanyBalanceReadArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollCompanyPayslipsReadArgs = {
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollDaysWorkedReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollDeductionsReadAllArgs = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollEmployeePayslipsRangeReadArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
};

export type QueryPayrollEmployeePayslipsReadArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollEmployeePayslipsReadAllArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
};

export type QueryPayrollEmployeeReadArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
};

export type QueryPayrollEmployeesReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollFixedAdditionsReadAllArgs = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
};

export type QueryPayrollFixedDeductionsReadAllArgs = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
};

export type QueryPayrollLogActivityRetrieveAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollPeriodDataReadArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollPreviewTableDataByPeriodArgs = {
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollTableDataByPeriodAllArgs = {
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type QueryPayrollTopUpRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryPeriodRetrieveArgs = {
  periodID: Scalars["ID"];
};

export type QueryPeriodsRetrieveAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPeriodsRetrieveAllWithKeyArgs = {
  companyID: Scalars["ID"];
  periodKey: Scalars["String"];
};

export type QueryPhoneInvitationsRetrieveArgs = {
  companyID: Scalars["ID"];
  accepted?: Maybe<Scalars["Boolean"]>;
};

export type QueryTransactionEventRetrieveByCompanyIdArgs = {
  companyID: Scalars["String"];
};

export type QueryTransactionEventRetrieveByUserIdArgs = {
  userID: Scalars["String"];
};

export type QueryUserEducationInfoRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryUserFamilyInfoReadAllArgs = {
  userID: Scalars["ID"];
};

export type QueryUserFamilyInfoReadAllByCompanyArgs = {
  companyID: Scalars["ID"];
};

export type QueryUserProfileRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryUserRetrieveByUsernameArgs = {
  username: Scalars["String"];
};

export type RegistrationInfo = {
  __typename?: "RegistrationInfo";
  name: Scalars["String"];
  username: Scalars["String"];
  token: Scalars["String"];
};

export type TransactionEvent = {
  __typename?: "TransactionEvent";
  eventID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  memberID: Scalars["ID"];
  amount: Scalars["Int"];
  fee: Scalars["Int"];
  principal: Scalars["Int"];
  description: Scalars["String"];
  status: Scalars["PCFS"];
  idempotencyKey: Scalars["String"];
  typ: TransactionEventType;
  allocatedAt?: Maybe<Scalars["Time"]>;
  createdAt?: Maybe<Scalars["Time"]>;
};

export enum TransactionEventType {
  Request = "REQUEST",
  Allowance = "ALLOWANCE",
}

export type UserEducationInfo = {
  __typename?: "UserEducationInfo";
  userID: Scalars["ID"];
  lastEducation?: Maybe<Scalars["String"]>;
  institutionName?: Maybe<Scalars["String"]>;
  educationStartYear?: Maybe<Scalars["Int"]>;
  educationEndYear?: Maybe<Scalars["Int"]>;
  workExperience?: Maybe<Scalars["String"]>;
  jobDescription?: Maybe<Scalars["String"]>;
  namePT?: Maybe<Scalars["String"]>;
  workStartYear?: Maybe<Scalars["Int"]>;
  workEndYear?: Maybe<Scalars["Int"]>;
};

export type UserEducationInfoInput = {
  userID: Scalars["ID"];
  lastEducation?: Maybe<Scalars["String"]>;
  institutionName?: Maybe<Scalars["String"]>;
  educationStartYear?: Maybe<Scalars["Int"]>;
  educationEndYear?: Maybe<Scalars["Int"]>;
  workExperience?: Maybe<Scalars["String"]>;
  jobDescription?: Maybe<Scalars["String"]>;
  namePT?: Maybe<Scalars["String"]>;
  workStartYear?: Maybe<Scalars["Int"]>;
  workEndYear?: Maybe<Scalars["Int"]>;
};

export type UserEducationInfoUpdate = {
  userID: Scalars["ID"];
  lastEducation?: Maybe<Scalars["String"]>;
  institutionName?: Maybe<Scalars["String"]>;
  educationStartYear?: Maybe<Scalars["Int"]>;
  educationEndYear?: Maybe<Scalars["Int"]>;
  workExperience?: Maybe<Scalars["String"]>;
  jobDescription?: Maybe<Scalars["String"]>;
  namePT?: Maybe<Scalars["String"]>;
  workStartYear?: Maybe<Scalars["Int"]>;
  workEndYear?: Maybe<Scalars["Int"]>;
};

export type UserFamilyInfo = {
  __typename?: "UserFamilyInfo";
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  famInfID: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  relationship?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["Time"]>;
  ktpNum?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
};

export type UserFamilyInfoInput = {
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  relationship?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["Time"]>;
  ktpNum?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
};

export type UserFamilyInfoUpdate = {
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  relationship?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["Time"]>;
  ktpNum?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  username: Scalars["String"];
  ktpUrl: Scalars["String"];
  npwpnum: Scalars["String"];
  ktpNum?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
};

export type UserProfileUpdate = {
  ktp?: Maybe<Scalars["Upload"]>;
};

export type UserResponse = {
  __typename?: "UserResponse";
  userID: Scalars["ID"];
  username: Scalars["String"];
};

export type AdminCreateMutationVariables = Exact<{
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type AdminCreateMutation = { __typename?: "Mutation" } & {
  AdminCreate: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export type AllowanceAddMutationVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
}>;

export type AllowanceAddMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "AllowanceAdd"
>;

export type AllowanceRequestApproveMutationVariables = Exact<{
  idempotencyKey: Scalars["String"];
}>;

export type AllowanceRequestApproveMutation = { __typename?: "Mutation" } & {
  AllowanceRequestApprove: { __typename?: "AllowanceRequest" } & Pick<
    AllowanceRequest,
    | "requestID"
    | "memberID"
    | "companyID"
    | "periodID"
    | "bankCode"
    | "accountNumber"
    | "amount"
    | "fee"
    | "principal"
    | "idempotencyKey"
    | "status"
    | "createdAt"
  >;
};

export type AllowanceRequestCreateMutationVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
}>;

export type AllowanceRequestCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "AllowanceRequestCreate"
>;

export type AllowanceRequestDenyMutationVariables = Exact<{
  idempotencyKey: Scalars["String"];
}>;

export type AllowanceRequestDenyMutation = { __typename?: "Mutation" } & {
  AllowanceRequestDeny: { __typename?: "AllowanceRequest" } & Pick<
    AllowanceRequest,
    | "requestID"
    | "memberID"
    | "companyID"
    | "periodID"
    | "bankCode"
    | "accountNumber"
    | "amount"
    | "fee"
    | "principal"
    | "idempotencyKey"
    | "status"
    | "createdAt"
  >;
};

export type CompanyCreateMutationVariables = Exact<{
  inp: CompanySettingsInput;
}>;

export type CompanyCreateMutation = { __typename?: "Mutation" } & {
  CompanyCreate: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
    | "payrollEnabled"
    | "attendanceEnabled"
  >;
};

export type CompanyEmployeeAttendanceOnOffSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  onOff: Scalars["Boolean"];
}>;

export type CompanyEmployeeAttendanceOnOffSetMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "CompanyEmployeeAttendanceOnOffSet">;

export type CompanyEmployeeAttendanceSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  attendanceAllowed: Scalars["Boolean"];
}>;

export type CompanyEmployeeAttendanceSetMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "CompanyEmployeeAttendanceSet">;

export type CompanyEmployeeCreateMutationVariables = Exact<{
  inp: CompanyEmployeeInput;
}>;

export type CompanyEmployeeCreateMutation = { __typename?: "Mutation" } & {
  CompanyEmployeeCreate: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "lastName"
    | "type"
    | "contract"
    | "employeeID"
    | "email"
    | "ktpUrl"
    | "ktpNum"
    | "npwpNum"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
    | "startDate"
    | "endDate"
    | "locationName"
    | "locationCode"
    | "customData"
  >;
};

export type CompanyEmployeePayrollSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  payrollAllowed: Scalars["Boolean"];
}>;

export type CompanyEmployeePayrollSetMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "CompanyEmployeePayrollSet">;

export type CompanyEmployeeShiftManagementOnOFfSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
  onOff: Scalars["Boolean"];
}>;

export type CompanyEmployeeShiftManagementOnOFfSetMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "CompanyEmployeeShiftManagementOnOFfSet">;

export type CompanyEmployeeUpdateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  update: CompanyEmployeeUpdate;
}>;

export type CompanyEmployeeUpdateMutation = { __typename?: "Mutation" } & {
  CompanyEmployeeUpdate: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "lastName"
    | "type"
    | "contract"
    | "employeeID"
    | "email"
    | "ktpUrl"
    | "ktpNum"
    | "npwpNum"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
    | "startDate"
    | "endDate"
    | "locationName"
    | "locationCode"
    | "customData"
  >;
};

export type CompanyManagerCreateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type CompanyManagerCreateMutation = { __typename?: "Mutation" } & {
  CompanyManagerCreate: { __typename?: "Membership" } & Pick<
    Membership,
    | "memberID"
    | "userID"
    | "companyID"
    | "startDate"
    | "endDate"
    | "isManager"
    | "employmentType"
    | "contract"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
  >;
};

export type CompanyManagerSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  isManager: Scalars["Boolean"];
}>;

export type CompanyManagerSetMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "CompanyManagerSet"
>;

export type CompanyMembershipCreateMutationVariables = Exact<{
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
}>;

export type CompanyMembershipCreateMutation = { __typename?: "Mutation" } & {
  CompanyMembershipCreate: { __typename?: "Membership" } & Pick<
    Membership,
    | "memberID"
    | "userID"
    | "companyID"
    | "startDate"
    | "endDate"
    | "isManager"
    | "employmentType"
    | "contract"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
  >;
};

export type CompanyUpdateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  inp: CompanySettingsInput;
}>;

export type CompanyUpdateMutation = { __typename?: "Mutation" } & {
  CompanyUpdate: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
    | "payrollEnabled"
    | "attendanceEnabled"
  >;
};

export type HelloWorldMMutationVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type HelloWorldMMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "HelloWorldM"
>;

export type PasswordResetMutationVariables = Exact<{
  token: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type PasswordResetMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordReset"
>;

export type PasswordResetSendOtpMutationVariables = Exact<{
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
}>;

export type PasswordResetSendOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordResetSendOtp"
>;

export type PasswordResetVerifyOtpMutationVariables = Exact<{
  token: Scalars["String"];
  otpCode: Scalars["String"];
}>;

export type PasswordResetVerifyOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordResetVerifyOtp"
>;

export type PasswordUpdateMutationVariables = Exact<{
  userID: Scalars["ID"];
  newPassword: Scalars["String"];
}>;

export type PasswordUpdateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordUpdate"
>;

export type PayrollAdditionCreateMutationVariables = Exact<{
  inp: AdditionInsert;
}>;

export type PayrollAdditionCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollAdditionCreate"
>;

export type PayrollCompanyBalanceAdditionMutationVariables = Exact<{
  inp: CompanyBalanceAdd;
}>;

export type PayrollCompanyBalanceAdditionMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollCompanyBalanceAddition">;

export type PayrollCompanyBalanceDeductionMutationVariables = Exact<{
  inp: CompanyBalanceDeduct;
}>;

export type PayrollCompanyBalanceDeductionMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollCompanyBalanceDeduction">;

export type PayrollDeductionCreateMutationVariables = Exact<{
  inp: DeductionInsert;
}>;

export type PayrollDeductionCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollDeductionCreate"
>;

export type PayrollEmployeeCreateMutationVariables = Exact<{
  inp: PayrollEmployeeCreate;
}>;

export type PayrollEmployeeCreateMutation = { __typename?: "Mutation" } & {
  PayrollEmployeeCreate: { __typename?: "PayrollEmployee" } & Pick<
    PayrollEmployee,
    | "memberID"
    | "companyID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "taxTFRate"
    | "salaryType"
    | "salary"
    | "dailyRateOverride"
    | "bankCode"
    | "accountNumber"
    | "onBehalfOf"
    | "statusPTKP"
    | "customData"
  >;
};

export type PayrollEmployeeUpdateMutationVariables = Exact<{
  memberID: Scalars["ID"];
  inp: PayrollEmployeeCreate;
}>;

export type PayrollEmployeeUpdateMutation = { __typename?: "Mutation" } & {
  PayrollEmployeeUpdate: { __typename?: "PayrollEmployee" } & Pick<
    PayrollEmployee,
    | "memberID"
    | "companyID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "taxTFRate"
    | "salaryType"
    | "salary"
    | "dailyRateOverride"
    | "bankCode"
    | "accountNumber"
    | "onBehalfOf"
    | "statusPTKP"
    | "customData"
  >;
};

export type PayrollFixedAdditionCreateMutationVariables = Exact<{
  inp: FixedAdditionInsert;
}>;

export type PayrollFixedAdditionCreateMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollFixedAdditionCreate">;

export type PayrollFixedDeductionCreateMutationVariables = Exact<{
  inp: FixedDeductionInsert;
}>;

export type PayrollFixedDeductionCreateMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollFixedDeductionCreate">;

export type PayrollMultipleAdditionsCreateMutationVariables = Exact<{
  inp: Array<Maybe<AdditionInsert>> | Maybe<AdditionInsert>;
}>;

export type PayrollMultipleAdditionsCreateMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollMultipleAdditionsCreate">;

export type PayrollMultipleDeductionsCreateMutationVariables = Exact<{
  inp: Array<Maybe<DeductionInsert>> | Maybe<DeductionInsert>;
}>;

export type PayrollMultipleDeductionsCreateMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollMultipleDeductionsCreate">;

export type PayrollPeriodMemberExecuteMutationVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollPeriodMemberExecuteMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollPeriodMemberExecute">;

export type PayrollRequestWageAndDisburseMutationVariables = Exact<{
  inp: PayrollWageRequestInsert;
}>;

export type PayrollRequestWageAndDisburseMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollRequestWageAndDisburse">;

export type PayrollTableDataUpdateMutationVariables = Exact<{
  memberID: Scalars["ID"];
  inp: PayrollTableDataInsert;
}>;

export type PayrollTableDataUpdateMutation = { __typename?: "Mutation" } & {
  PayrollTableDataUpdate: { __typename?: "PayrollTableData" } & Pick<
    PayrollTableData,
    | "memberID"
    | "companyID"
    | "periodID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "taxTFRate"
    | "salaryType"
    | "salary"
    | "customData"
    | "daysWorked"
    | "phoneNumber"
    | "totalAdditions"
    | "totalDeductions"
    | "status"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
  >;
};

export type PayrollTopUpAddMutationVariables = Exact<{
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
}>;

export type PayrollTopUpAddMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollTopUpAdd"
>;

export type PhoneInvitationCreateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  insert: PhoneInvitationInsert;
}>;

export type PhoneInvitationCreateMutation = { __typename?: "Mutation" } & {
  PhoneInvitationCreate: { __typename?: "PhoneInvitation" } & Pick<
    PhoneInvitation,
    | "inviteID"
    | "memberID"
    | "companyID"
    | "name"
    | "phoneNumber"
    | "monthlyLimit"
    | "bankCode"
    | "bankNumber"
    | "accepted"
    | "customData"
  >;
};

export type PhoneInvitationDeleteMutationVariables = Exact<{
  inviteID: Scalars["ID"];
}>;

export type PhoneInvitationDeleteMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneInvitationDelete"
>;

export type PhoneOtpCreateMutationVariables = Exact<{
  phoneNumber: Scalars["Int"];
  otpCode: Scalars["String"];
}>;

export type PhoneOtpCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneOtpCreate"
>;

export type PhoneOtpDeleteMutationVariables = Exact<{
  phoneNumber: Scalars["Int"];
}>;

export type PhoneOtpDeleteMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneOtpDelete"
>;

export type RegistrationSendOtpMutationVariables = Exact<{
  token: Scalars["String"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
}>;

export type RegistrationSendOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSendOTP"
>;

export type RegistrationSubmitBankMutationVariables = Exact<{
  token: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
}>;

export type RegistrationSubmitBankMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSubmitBank"
>;

export type RegistrationSubmitInfoMutationVariables = Exact<{
  token: Scalars["String"];
  ktp: Scalars["Upload"];
  birthday: Scalars["Time"];
}>;

export type RegistrationSubmitInfoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSubmitInfo"
>;

export type RegistrationSubmitPasswordMutationVariables = Exact<{
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type RegistrationSubmitPasswordMutation = { __typename?: "Mutation" } & {
  RegistrationSubmitPassword: { __typename?: "RegistrationInfo" } & Pick<
    RegistrationInfo,
    "name" | "username" | "token"
  >;
};

export type RegistrationVerifyOtpMutationVariables = Exact<{
  token: Scalars["String"];
  otpCode: Scalars["String"];
}>;

export type RegistrationVerifyOtpMutation = { __typename?: "Mutation" } & {
  RegistrationVerifyOTP: { __typename?: "RegistrationInfo" } & Pick<
    RegistrationInfo,
    "name" | "username" | "token"
  >;
};

export type UserCreateMutationVariables = Exact<{
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type UserCreateMutation = { __typename?: "Mutation" } & {
  UserCreate: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export type UserEducationInfoCreateMutationVariables = Exact<{
  inp: UserEducationInfoInput;
}>;

export type UserEducationInfoCreateMutation = { __typename?: "Mutation" } & {
  UserEducationInfoCreate: { __typename?: "UserEducationInfo" } & Pick<
    UserEducationInfo,
    | "userID"
    | "lastEducation"
    | "institutionName"
    | "educationStartYear"
    | "educationEndYear"
    | "workExperience"
    | "jobDescription"
    | "namePT"
    | "workStartYear"
    | "workEndYear"
  >;
};

export type UserEducationInfoUpdateMutationVariables = Exact<{
  inp: UserEducationInfoUpdate;
}>;

export type UserEducationInfoUpdateMutation = { __typename?: "Mutation" } & {
  UserEducationInfoUpdate: { __typename?: "UserEducationInfo" } & Pick<
    UserEducationInfo,
    | "userID"
    | "lastEducation"
    | "institutionName"
    | "educationStartYear"
    | "educationEndYear"
    | "workExperience"
    | "jobDescription"
    | "namePT"
    | "workStartYear"
    | "workEndYear"
  >;
};

export type UserFamilyInfoCreateMutationVariables = Exact<{
  inp: UserFamilyInfoInput;
}>;

export type UserFamilyInfoCreateMutation = { __typename?: "Mutation" } & {
  UserFamilyInfoCreate: { __typename?: "UserFamilyInfo" } & Pick<
    UserFamilyInfo,
    | "userID"
    | "companyID"
    | "famInfID"
    | "name"
    | "relationship"
    | "birthday"
    | "ktpNum"
    | "jobTitle"
  >;
};

export type UserFamilyInfoUpdateMutationVariables = Exact<{
  famInfID: Scalars["ID"];
  inp: UserFamilyInfoUpdate;
}>;

export type UserFamilyInfoUpdateMutation = { __typename?: "Mutation" } & {
  UserFamilyInfoUpdate: { __typename?: "UserFamilyInfo" } & Pick<
    UserFamilyInfo,
    | "userID"
    | "companyID"
    | "famInfID"
    | "name"
    | "relationship"
    | "birthday"
    | "ktpNum"
    | "jobTitle"
  >;
};

export type UserProfileCreateMutationVariables = Exact<{
  inp: UserProfileUpdate;
}>;

export type UserProfileCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UserProfileCreate"
>;

export type UserProfileUpdateMutationVariables = Exact<{
  userID: Scalars["ID"];
  update: UserProfileUpdate;
}>;

export type UserProfileUpdateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UserProfileUpdate"
>;

export type UserUpdatePasswordMutationVariables = Exact<{
  userID: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type UserUpdatePasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UserUpdatePassword"
>;

export type AllowanceRequestFeeQueryVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
}>;

export type AllowanceRequestFeeQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRequestFee"
>;

export type AllowanceRequestMinimumQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type AllowanceRequestMinimumQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRequestMinimum"
>;

export type AllowanceRequestRetrieveChannelResponsesQueryVariables = Exact<{
  requestID: Scalars["ID"];
}>;

export type AllowanceRequestRetrieveChannelResponsesQuery = {
  __typename?: "Query";
} & Pick<Query, "AllowanceRequestRetrieveChannelResponses">;

export type AllowanceRequestRetrieveUnapprovedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllowanceRequestRetrieveUnapprovedQuery = {
  __typename?: "Query";
} & {
  AllowanceRequestRetrieveUnapproved: Array<
    Maybe<
      { __typename?: "AllowanceRequest" } & Pick<
        AllowanceRequest,
        | "requestID"
        | "memberID"
        | "companyID"
        | "periodID"
        | "bankCode"
        | "accountNumber"
        | "amount"
        | "fee"
        | "principal"
        | "idempotencyKey"
        | "status"
        | "createdAt"
      >
    >
  >;
};

export type AllowanceRetrieveQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type AllowanceRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRetrieve"
>;

export type AuthRoleRetrieveQueryVariables = Exact<{ [key: string]: never }>;

export type AuthRoleRetrieveQuery = { __typename?: "Query" } & {
  AuthRoleRetrieve: { __typename?: "AuthRole" } & Pick<
    AuthRole,
    "userID" | "companyID" | "memberID" | "level"
  >;
};

export type BankAccountRetrieveDefaultQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type BankAccountRetrieveDefaultQuery = { __typename?: "Query" } & {
  BankAccountRetrieveDefault: { __typename?: "BankAccount" } & Pick<
    BankAccount,
    "bankKey" | "bankCode" | "accountNumber" | "verificationStatus"
  >;
};

export type CompanyEmployeeRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
  userID: Scalars["ID"];
}>;

export type CompanyEmployeeRetrieveQuery = { __typename?: "Query" } & {
  CompanyEmployeeRetrieve: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "lastName"
    | "type"
    | "contract"
    | "employeeID"
    | "email"
    | "ktpUrl"
    | "ktpNum"
    | "npwpNum"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
    | "startDate"
    | "endDate"
    | "locationName"
    | "locationCode"
    | "customData"
  >;
};

export type CompanyEmployeesRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyEmployeesRetrieveQuery = { __typename?: "Query" } & {
  CompanyEmployeesRetrieve: Array<
    Maybe<
      { __typename?: "CompanyEmployee" } & Pick<
        CompanyEmployee,
        | "userID"
        | "memberID"
        | "companyID"
        | "username"
        | "phoneNumber"
        | "monthlyLimit"
        | "birthday"
        | "name"
        | "lastName"
        | "type"
        | "contract"
        | "employeeID"
        | "email"
        | "ktpUrl"
        | "ktpNum"
        | "npwpNum"
        | "bankCode"
        | "accountNumber"
        | "bankVerificationStatus"
        | "wageRequestAllowed"
        | "payrollAllowed"
        | "attendanceAllowed"
        | "attendanceOnOff"
        | "shiftManagementOnOff"
        | "startDate"
        | "endDate"
        | "locationName"
        | "locationCode"
        | "customData"
      >
    >
  >;
};

export type CompanyInfoRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyInfoRetrieveQuery = { __typename?: "Query" } & {
  CompanyInfoRetrieve: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
    | "payrollEnabled"
    | "attendanceEnabled"
  >;
};

export type CompanyInfoRetrieveAllQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyInfoRetrieveAllQuery = { __typename?: "Query" } & {
  CompanyInfoRetrieveAll: Array<
    Maybe<
      { __typename?: "CompanyInfo" } & Pick<
        CompanyInfo,
        | "companyID"
        | "name"
        | "ewaFee"
        | "startPayPeriodDay"
        | "endPayPeriodDay"
        | "wageAllowanceAutoAccruingDaily"
        | "phoneAppEnabled"
        | "dashboardEnabled"
        | "wageRequestEnabled"
        | "allowanceRequestAutoDisbursing"
        | "minimumRequestAmount"
        | "wageImbursementPercentLimit"
        | "payrollEnabled"
        | "attendanceEnabled"
      >
    >
  >;
};

export type CompanyMembershipRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type CompanyMembershipRetrieveQuery = { __typename?: "Query" } & {
  CompanyMembershipRetrieve: { __typename?: "Membership" } & Pick<
    Membership,
    | "memberID"
    | "userID"
    | "companyID"
    | "startDate"
    | "endDate"
    | "isManager"
    | "employmentType"
    | "contract"
    | "payrollAllowed"
    | "attendanceAllowed"
    | "attendanceOnOff"
    | "shiftManagementOnOff"
  >;
};

export type CompanyMembershipRetrieveAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyMembershipRetrieveAllQuery = { __typename?: "Query" } & {
  CompanyMembershipRetrieveAll: Array<
    Maybe<
      { __typename?: "Membership" } & Pick<
        Membership,
        | "memberID"
        | "userID"
        | "companyID"
        | "startDate"
        | "endDate"
        | "isManager"
        | "employmentType"
        | "contract"
        | "payrollAllowed"
        | "attendanceAllowed"
        | "attendanceOnOff"
        | "shiftManagementOnOff"
      >
    >
  >;
};

export type CompanyMembershipsReadAllQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyMembershipsReadAllQuery = { __typename?: "Query" } & {
  CompanyMembershipsReadAll: Array<
    Maybe<
      { __typename?: "Membership" } & Pick<
        Membership,
        | "memberID"
        | "userID"
        | "companyID"
        | "startDate"
        | "endDate"
        | "isManager"
        | "employmentType"
        | "contract"
        | "payrollAllowed"
        | "attendanceAllowed"
        | "attendanceOnOff"
        | "shiftManagementOnOff"
      >
    >
  >;
};

export type DashboardsVisibleRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type DashboardsVisibleRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "DashboardsVisibleRetrieve"
>;

export type FamilyInfoReadAllByCompanyQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type FamilyInfoReadAllByCompanyQuery = { __typename?: "Query" } & {
  FamilyInfoReadAllByCompany: Array<
    Maybe<
      { __typename?: "UserFamilyInfo" } & Pick<
        UserFamilyInfo,
        | "userID"
        | "companyID"
        | "famInfID"
        | "name"
        | "relationship"
        | "birthday"
        | "ktpNum"
        | "jobTitle"
      >
    >
  >;
};

export type FlipBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type FlipBalanceQuery = { __typename?: "Query" } & Pick<
  Query,
  "FlipBalance"
>;

export type FlipTransactionsJsonQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FlipTransactionsJsonQuery = { __typename?: "Query" } & Pick<
  Query,
  "FlipTransactionsJSON"
>;

export type HelloWorldQQueryVariables = Exact<{ [key: string]: never }>;

export type HelloWorldQQuery = { __typename?: "Query" } & Pick<
  Query,
  "HelloWorldQ"
>;

export type PayrollAdditionsReadAllQueryVariables = Exact<{
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollAdditionsReadAllQuery = { __typename?: "Query" } & {
  PayrollAdditionsReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodAddition" } & Pick<
        EmployeePeriodAddition,
        | "memberID"
        | "companyID"
        | "periodID"
        | "amount"
        | "reason"
        | "type"
        | "date"
      >
    >
  >;
};

export type PayrollCompanyBalanceReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollCompanyBalanceReadQuery = { __typename?: "Query" } & {
  PayrollCompanyBalanceRead: { __typename?: "CompanyBalance" } & Pick<
    CompanyBalance,
    "companyID" | "amount" | "refillDate"
  >;
};

export type PayrollCompanyPayslipsReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollCompanyPayslipsReadQuery = { __typename?: "Query" } & {
  PayrollCompanyPayslipsRead: Array<
    Maybe<
      { __typename?: "Payslip" } & Pick<
        Payslip,
        | "memberID"
        | "companyID"
        | "periodID"
        | "bankCode"
        | "accountNumber"
        | "amount"
        | "startTime"
        | "endTime"
        | "createdAt"
        | "disbursedAt"
      > & {
          additions: Array<
            Maybe<
              { __typename?: "EmployeePeriodAddition" } & Pick<
                EmployeePeriodAddition,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
          deductions: Array<
            Maybe<
              { __typename?: "EmployeePeriodDeduction" } & Pick<
                EmployeePeriodDeduction,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
        }
    >
  >;
};

export type PayrollDaysWorkedReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollDaysWorkedReadAllQuery = { __typename?: "Query" } & {
  PayrollDaysWorkedReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodDaysWorked" } & Pick<
        EmployeePeriodDaysWorked,
        "memberID" | "companyID" | "periodID" | "daysWorked"
      >
    >
  >;
};

export type PayrollDeductionsReadAllQueryVariables = Exact<{
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollDeductionsReadAllQuery = { __typename?: "Query" } & {
  PayrollDeductionsReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodDeduction" } & Pick<
        EmployeePeriodDeduction,
        | "memberID"
        | "companyID"
        | "periodID"
        | "amount"
        | "reason"
        | "type"
        | "date"
      >
    >
  >;
};

export type PayrollEmployeePayslipsRangeReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
}>;

export type PayrollEmployeePayslipsRangeReadQuery = { __typename?: "Query" } & {
  PayrollEmployeePayslipsRangeRead: Array<
    Maybe<
      { __typename?: "Payslip" } & Pick<
        Payslip,
        | "memberID"
        | "companyID"
        | "periodID"
        | "bankCode"
        | "accountNumber"
        | "amount"
        | "startTime"
        | "endTime"
        | "createdAt"
        | "disbursedAt"
      > & {
          additions: Array<
            Maybe<
              { __typename?: "EmployeePeriodAddition" } & Pick<
                EmployeePeriodAddition,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
          deductions: Array<
            Maybe<
              { __typename?: "EmployeePeriodDeduction" } & Pick<
                EmployeePeriodDeduction,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
        }
    >
  >;
};

export type PayrollEmployeePayslipsReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollEmployeePayslipsReadQuery = { __typename?: "Query" } & {
  PayrollEmployeePayslipsRead: { __typename?: "Payslip" } & Pick<
    Payslip,
    | "memberID"
    | "companyID"
    | "periodID"
    | "bankCode"
    | "accountNumber"
    | "amount"
    | "startTime"
    | "endTime"
    | "createdAt"
    | "disbursedAt"
  > & {
      additions: Array<
        Maybe<
          { __typename?: "EmployeePeriodAddition" } & Pick<
            EmployeePeriodAddition,
            | "memberID"
            | "companyID"
            | "periodID"
            | "amount"
            | "reason"
            | "type"
            | "date"
          >
        >
      >;
      deductions: Array<
        Maybe<
          { __typename?: "EmployeePeriodDeduction" } & Pick<
            EmployeePeriodDeduction,
            | "memberID"
            | "companyID"
            | "periodID"
            | "amount"
            | "reason"
            | "type"
            | "date"
          >
        >
      >;
    };
};

export type PayrollEmployeePayslipsReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
}>;

export type PayrollEmployeePayslipsReadAllQuery = { __typename?: "Query" } & {
  PayrollEmployeePayslipsReadAll: Array<
    Maybe<
      { __typename?: "Payslip" } & Pick<
        Payslip,
        | "memberID"
        | "companyID"
        | "periodID"
        | "bankCode"
        | "accountNumber"
        | "amount"
        | "startTime"
        | "endTime"
        | "createdAt"
        | "disbursedAt"
      > & {
          additions: Array<
            Maybe<
              { __typename?: "EmployeePeriodAddition" } & Pick<
                EmployeePeriodAddition,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
          deductions: Array<
            Maybe<
              { __typename?: "EmployeePeriodDeduction" } & Pick<
                EmployeePeriodDeduction,
                | "memberID"
                | "companyID"
                | "periodID"
                | "amount"
                | "reason"
                | "type"
                | "date"
              >
            >
          >;
        }
    >
  >;
};

export type PayrollEmployeeReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
}>;

export type PayrollEmployeeReadQuery = { __typename?: "Query" } & {
  PayrollEmployeeRead: { __typename?: "PayrollEmployee" } & Pick<
    PayrollEmployee,
    | "memberID"
    | "companyID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "taxTFRate"
    | "salaryType"
    | "salary"
    | "dailyRateOverride"
    | "bankCode"
    | "accountNumber"
    | "onBehalfOf"
    | "statusPTKP"
    | "customData"
  >;
};

export type PayrollEmployeesReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollEmployeesReadAllQuery = { __typename?: "Query" } & {
  PayrollEmployeesReadAll: Array<
    Maybe<
      { __typename?: "PayrollEmployee" } & Pick<
        PayrollEmployee,
        | "memberID"
        | "companyID"
        | "name"
        | "taxTkRate"
        | "taxKesRate"
        | "taxTFRate"
        | "salaryType"
        | "salary"
        | "dailyRateOverride"
        | "bankCode"
        | "accountNumber"
        | "onBehalfOf"
        | "statusPTKP"
        | "customData"
      >
    >
  >;
};

export type PayrollFixedAdditionsReadAllQueryVariables = Exact<{
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
}>;

export type PayrollFixedAdditionsReadAllQuery = { __typename?: "Query" } & {
  PayrollFixedAdditionsReadAll: Array<
    Maybe<
      { __typename?: "FixedEmployeeAddition" } & Pick<
        FixedEmployeeAddition,
        "memberID" | "companyID" | "amount" | "reason" | "date"
      >
    >
  >;
};

export type PayrollFixedDeductionsReadAllQueryVariables = Exact<{
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
}>;

export type PayrollFixedDeductionsReadAllQuery = { __typename?: "Query" } & {
  PayrollFixedDeductionsReadAll: Array<
    Maybe<
      { __typename?: "FixedEmployeeDeduction" } & Pick<
        FixedEmployeeDeduction,
        "memberID" | "companyID" | "amount" | "reason" | "date"
      >
    >
  >;
};

export type PayrollLogActivityRetrieveAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollLogActivityRetrieveAllQuery = { __typename?: "Query" } & {
  PayrollLogActivityRetrieveAll: Array<
    Maybe<
      { __typename?: "LogActivity" } & Pick<LogActivity, "periodID" | "status">
    >
  >;
};

export type PayrollPeriodDataReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollPeriodDataReadQuery = { __typename?: "Query" } & {
  PayrollPeriodDataRead: Array<
    Maybe<
      { __typename?: "PeriodData" } & Pick<
        PeriodData,
        "periodID" | "startTime" | "endTime"
      >
    >
  >;
};

export type PayrollPreviewTableDataByPeriodQueryVariables = Exact<{
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollPreviewTableDataByPeriodQuery = { __typename?: "Query" } & {
  PayrollPreviewTableDataByPeriod: {
    __typename?: "PayrollPreviewTableData";
  } & Pick<
    PayrollPreviewTableData,
    "companyID" | "periodID" | "totalEmployees" | "totalPayroll"
  >;
};

export type PayrollTableDataByPeriodAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollTableDataByPeriodAllQuery = { __typename?: "Query" } & {
  PayrollTableDataByPeriodAll: Array<
    Maybe<
      { __typename?: "PayrollTableData" } & Pick<
        PayrollTableData,
        | "memberID"
        | "companyID"
        | "periodID"
        | "name"
        | "taxTkRate"
        | "taxKesRate"
        | "taxTFRate"
        | "salaryType"
        | "salary"
        | "customData"
        | "daysWorked"
        | "phoneNumber"
        | "totalAdditions"
        | "totalDeductions"
        | "status"
        | "bankCode"
        | "accountNumber"
        | "bankVerificationStatus"
      >
    >
  >;
};

export type PayrollTopUpRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollTopUpRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "PayrollTopUpRetrieve"
>;

export type PeriodRetrieveQueryVariables = Exact<{
  periodID: Scalars["ID"];
}>;

export type PeriodRetrieveQuery = { __typename?: "Query" } & {
  PeriodRetrieve: { __typename?: "Period" } & Pick<
    Period,
    "periodID" | "companyID" | "startTime" | "endTime"
  >;
};

export type PeriodsRetrieveAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PeriodsRetrieveAllQuery = { __typename?: "Query" } & {
  PeriodsRetrieveAll: Array<
    Maybe<
      { __typename?: "Period" } & Pick<
        Period,
        "periodID" | "companyID" | "startTime" | "endTime"
      >
    >
  >;
};

export type PeriodsRetrieveAllWithKeyQueryVariables = Exact<{
  companyID: Scalars["ID"];
  periodKey: Scalars["String"];
}>;

export type PeriodsRetrieveAllWithKeyQuery = { __typename?: "Query" } & {
  PeriodsRetrieveAllWithKey: Array<
    Maybe<
      { __typename?: "Period" } & Pick<
        Period,
        "periodID" | "companyID" | "startTime" | "endTime"
      >
    >
  >;
};

export type PhoneInvitationsRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
  accepted?: Maybe<Scalars["Boolean"]>;
}>;

export type PhoneInvitationsRetrieveQuery = { __typename?: "Query" } & {
  PhoneInvitationsRetrieve: Array<
    { __typename?: "PhoneInvitation" } & Pick<
      PhoneInvitation,
      | "inviteID"
      | "memberID"
      | "companyID"
      | "name"
      | "phoneNumber"
      | "monthlyLimit"
      | "bankCode"
      | "bankNumber"
      | "accepted"
      | "customData"
    >
  >;
};

export type TransactionEventRetrieveByCompanyIdQueryVariables = Exact<{
  companyID: Scalars["String"];
}>;

export type TransactionEventRetrieveByCompanyIdQuery = {
  __typename?: "Query";
} & {
  TransactionEventRetrieveByCompanyID: Array<
    Maybe<
      { __typename?: "TransactionEvent" } & Pick<
        TransactionEvent,
        | "eventID"
        | "companyID"
        | "periodID"
        | "memberID"
        | "amount"
        | "fee"
        | "principal"
        | "description"
        | "status"
        | "idempotencyKey"
        | "typ"
        | "allocatedAt"
        | "createdAt"
      >
    >
  >;
};

export type TransactionEventRetrieveByUserIdQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type TransactionEventRetrieveByUserIdQuery = { __typename?: "Query" } & {
  TransactionEventRetrieveByUserID: Array<
    Maybe<
      { __typename?: "TransactionEvent" } & Pick<
        TransactionEvent,
        | "eventID"
        | "companyID"
        | "periodID"
        | "memberID"
        | "amount"
        | "fee"
        | "principal"
        | "description"
        | "status"
        | "idempotencyKey"
        | "typ"
        | "allocatedAt"
        | "createdAt"
      >
    >
  >;
};

export type UserEducationInfoRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type UserEducationInfoRetrieveQuery = { __typename?: "Query" } & {
  UserEducationInfoRetrieve: { __typename?: "UserEducationInfo" } & Pick<
    UserEducationInfo,
    | "userID"
    | "lastEducation"
    | "institutionName"
    | "educationStartYear"
    | "educationEndYear"
    | "workExperience"
    | "jobDescription"
    | "namePT"
    | "workStartYear"
    | "workEndYear"
  >;
};

export type UserFamilyInfoReadAllQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type UserFamilyInfoReadAllQuery = { __typename?: "Query" } & {
  UserFamilyInfoReadAll: Array<
    Maybe<
      { __typename?: "UserFamilyInfo" } & Pick<
        UserFamilyInfo,
        | "userID"
        | "companyID"
        | "famInfID"
        | "name"
        | "relationship"
        | "birthday"
        | "ktpNum"
        | "jobTitle"
      >
    >
  >;
};

export type UserFamilyInfoReadAllByCompanyQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type UserFamilyInfoReadAllByCompanyQuery = { __typename?: "Query" } & {
  UserFamilyInfoReadAllByCompany: Array<
    Maybe<
      { __typename?: "UserFamilyInfo" } & Pick<
        UserFamilyInfo,
        | "userID"
        | "companyID"
        | "famInfID"
        | "name"
        | "relationship"
        | "birthday"
        | "ktpNum"
        | "jobTitle"
      >
    >
  >;
};

export type UserProfileRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type UserProfileRetrieveQuery = { __typename?: "Query" } & {
  UserProfileRetrieve: { __typename?: "UserProfile" } & Pick<
    UserProfile,
    "username" | "ktpUrl" | "npwpnum" | "ktpNum" | "name" | "lastName" | "email"
  >;
};

export type UserRetrieveByUsernameQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type UserRetrieveByUsernameQuery = { __typename?: "Query" } & {
  UserRetrieveByUsername: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export const AdminCreateDocument = gql`
  mutation AdminCreate($username: String!, $password: String!) {
    AdminCreate(username: $username, password: $password) {
      userID
      username
    }
  }
`;
export type AdminCreateMutationFn = Apollo.MutationFunction<
  AdminCreateMutation,
  AdminCreateMutationVariables
>;

/**
 * __useAdminCreateMutation__
 *
 * To run a mutation, you first call `useAdminCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateMutation, { data, loading, error }] = useAdminCreateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateMutation,
    AdminCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateMutation, AdminCreateMutationVariables>(
    AdminCreateDocument,
    options,
  );
}
export type AdminCreateMutationHookResult = ReturnType<
  typeof useAdminCreateMutation
>;
export type AdminCreateMutationResult =
  Apollo.MutationResult<AdminCreateMutation>;
export type AdminCreateMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateMutation,
  AdminCreateMutationVariables
>;
export const AllowanceAddDocument = gql`
  mutation AllowanceAdd($userID: String!, $amount: Int!) {
    AllowanceAdd(userID: $userID, amount: $amount)
  }
`;
export type AllowanceAddMutationFn = Apollo.MutationFunction<
  AllowanceAddMutation,
  AllowanceAddMutationVariables
>;

/**
 * __useAllowanceAddMutation__
 *
 * To run a mutation, you first call `useAllowanceAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceAddMutation, { data, loading, error }] = useAllowanceAddMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAllowanceAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceAddMutation,
    AllowanceAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceAddMutation,
    AllowanceAddMutationVariables
  >(AllowanceAddDocument, options);
}
export type AllowanceAddMutationHookResult = ReturnType<
  typeof useAllowanceAddMutation
>;
export type AllowanceAddMutationResult =
  Apollo.MutationResult<AllowanceAddMutation>;
export type AllowanceAddMutationOptions = Apollo.BaseMutationOptions<
  AllowanceAddMutation,
  AllowanceAddMutationVariables
>;
export const AllowanceRequestApproveDocument = gql`
  mutation AllowanceRequestApprove($idempotencyKey: String!) {
    AllowanceRequestApprove(idempotencyKey: $idempotencyKey) {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;
export type AllowanceRequestApproveMutationFn = Apollo.MutationFunction<
  AllowanceRequestApproveMutation,
  AllowanceRequestApproveMutationVariables
>;

/**
 * __useAllowanceRequestApproveMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestApproveMutation, { data, loading, error }] = useAllowanceRequestApproveMutation({
 *   variables: {
 *      idempotencyKey: // value for 'idempotencyKey'
 *   },
 * });
 */
export function useAllowanceRequestApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestApproveMutation,
    AllowanceRequestApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestApproveMutation,
    AllowanceRequestApproveMutationVariables
  >(AllowanceRequestApproveDocument, options);
}
export type AllowanceRequestApproveMutationHookResult = ReturnType<
  typeof useAllowanceRequestApproveMutation
>;
export type AllowanceRequestApproveMutationResult =
  Apollo.MutationResult<AllowanceRequestApproveMutation>;
export type AllowanceRequestApproveMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestApproveMutation,
  AllowanceRequestApproveMutationVariables
>;
export const AllowanceRequestCreateDocument = gql`
  mutation AllowanceRequestCreate(
    $userID: String!
    $amount: Int!
    $description: String!
  ) {
    AllowanceRequestCreate(
      userID: $userID
      amount: $amount
      description: $description
    )
  }
`;
export type AllowanceRequestCreateMutationFn = Apollo.MutationFunction<
  AllowanceRequestCreateMutation,
  AllowanceRequestCreateMutationVariables
>;

/**
 * __useAllowanceRequestCreateMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestCreateMutation, { data, loading, error }] = useAllowanceRequestCreateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAllowanceRequestCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestCreateMutation,
    AllowanceRequestCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestCreateMutation,
    AllowanceRequestCreateMutationVariables
  >(AllowanceRequestCreateDocument, options);
}
export type AllowanceRequestCreateMutationHookResult = ReturnType<
  typeof useAllowanceRequestCreateMutation
>;
export type AllowanceRequestCreateMutationResult =
  Apollo.MutationResult<AllowanceRequestCreateMutation>;
export type AllowanceRequestCreateMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestCreateMutation,
  AllowanceRequestCreateMutationVariables
>;
export const AllowanceRequestDenyDocument = gql`
  mutation AllowanceRequestDeny($idempotencyKey: String!) {
    AllowanceRequestDeny(idempotencyKey: $idempotencyKey) {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;
export type AllowanceRequestDenyMutationFn = Apollo.MutationFunction<
  AllowanceRequestDenyMutation,
  AllowanceRequestDenyMutationVariables
>;

/**
 * __useAllowanceRequestDenyMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestDenyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestDenyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestDenyMutation, { data, loading, error }] = useAllowanceRequestDenyMutation({
 *   variables: {
 *      idempotencyKey: // value for 'idempotencyKey'
 *   },
 * });
 */
export function useAllowanceRequestDenyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestDenyMutation,
    AllowanceRequestDenyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestDenyMutation,
    AllowanceRequestDenyMutationVariables
  >(AllowanceRequestDenyDocument, options);
}
export type AllowanceRequestDenyMutationHookResult = ReturnType<
  typeof useAllowanceRequestDenyMutation
>;
export type AllowanceRequestDenyMutationResult =
  Apollo.MutationResult<AllowanceRequestDenyMutation>;
export type AllowanceRequestDenyMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestDenyMutation,
  AllowanceRequestDenyMutationVariables
>;
export const CompanyCreateDocument = gql`
  mutation CompanyCreate($inp: CompanySettingsInput!) {
    CompanyCreate(inp: $inp) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
      payrollEnabled
      attendanceEnabled
    }
  }
`;
export type CompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options);
}
export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>;
export type CompanyCreateMutationResult =
  Apollo.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;
export const CompanyEmployeeAttendanceOnOffSetDocument = gql`
  mutation CompanyEmployeeAttendanceOnOffSet(
    $companyID: ID!
    $userID: ID!
    $onOff: Boolean!
  ) {
    CompanyEmployeeAttendanceOnOffSet(
      companyID: $companyID
      userID: $userID
      onOff: $onOff
    )
  }
`;
export type CompanyEmployeeAttendanceOnOffSetMutationFn =
  Apollo.MutationFunction<
    CompanyEmployeeAttendanceOnOffSetMutation,
    CompanyEmployeeAttendanceOnOffSetMutationVariables
  >;

/**
 * __useCompanyEmployeeAttendanceOnOffSetMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeAttendanceOnOffSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeAttendanceOnOffSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeAttendanceOnOffSetMutation, { data, loading, error }] = useCompanyEmployeeAttendanceOnOffSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *      onOff: // value for 'onOff'
 *   },
 * });
 */
export function useCompanyEmployeeAttendanceOnOffSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeAttendanceOnOffSetMutation,
    CompanyEmployeeAttendanceOnOffSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeAttendanceOnOffSetMutation,
    CompanyEmployeeAttendanceOnOffSetMutationVariables
  >(CompanyEmployeeAttendanceOnOffSetDocument, options);
}
export type CompanyEmployeeAttendanceOnOffSetMutationHookResult = ReturnType<
  typeof useCompanyEmployeeAttendanceOnOffSetMutation
>;
export type CompanyEmployeeAttendanceOnOffSetMutationResult =
  Apollo.MutationResult<CompanyEmployeeAttendanceOnOffSetMutation>;
export type CompanyEmployeeAttendanceOnOffSetMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyEmployeeAttendanceOnOffSetMutation,
    CompanyEmployeeAttendanceOnOffSetMutationVariables
  >;
export const CompanyEmployeeAttendanceSetDocument = gql`
  mutation CompanyEmployeeAttendanceSet(
    $companyID: ID!
    $userID: ID!
    $attendanceAllowed: Boolean!
  ) {
    CompanyEmployeeAttendanceSet(
      companyID: $companyID
      userID: $userID
      attendanceAllowed: $attendanceAllowed
    )
  }
`;
export type CompanyEmployeeAttendanceSetMutationFn = Apollo.MutationFunction<
  CompanyEmployeeAttendanceSetMutation,
  CompanyEmployeeAttendanceSetMutationVariables
>;

/**
 * __useCompanyEmployeeAttendanceSetMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeAttendanceSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeAttendanceSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeAttendanceSetMutation, { data, loading, error }] = useCompanyEmployeeAttendanceSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *      attendanceAllowed: // value for 'attendanceAllowed'
 *   },
 * });
 */
export function useCompanyEmployeeAttendanceSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeAttendanceSetMutation,
    CompanyEmployeeAttendanceSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeAttendanceSetMutation,
    CompanyEmployeeAttendanceSetMutationVariables
  >(CompanyEmployeeAttendanceSetDocument, options);
}
export type CompanyEmployeeAttendanceSetMutationHookResult = ReturnType<
  typeof useCompanyEmployeeAttendanceSetMutation
>;
export type CompanyEmployeeAttendanceSetMutationResult =
  Apollo.MutationResult<CompanyEmployeeAttendanceSetMutation>;
export type CompanyEmployeeAttendanceSetMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyEmployeeAttendanceSetMutation,
    CompanyEmployeeAttendanceSetMutationVariables
  >;
export const CompanyEmployeeCreateDocument = gql`
  mutation CompanyEmployeeCreate($inp: CompanyEmployeeInput!) {
    CompanyEmployeeCreate(inp: $inp) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      lastName
      type
      contract
      employeeID
      email
      ktpUrl
      ktpNum
      npwpNum
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
      startDate
      endDate
      locationName
      locationCode
      customData
    }
  }
`;
export type CompanyEmployeeCreateMutationFn = Apollo.MutationFunction<
  CompanyEmployeeCreateMutation,
  CompanyEmployeeCreateMutationVariables
>;

/**
 * __useCompanyEmployeeCreateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeCreateMutation, { data, loading, error }] = useCompanyEmployeeCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyEmployeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeCreateMutation,
    CompanyEmployeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeCreateMutation,
    CompanyEmployeeCreateMutationVariables
  >(CompanyEmployeeCreateDocument, options);
}
export type CompanyEmployeeCreateMutationHookResult = ReturnType<
  typeof useCompanyEmployeeCreateMutation
>;
export type CompanyEmployeeCreateMutationResult =
  Apollo.MutationResult<CompanyEmployeeCreateMutation>;
export type CompanyEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyEmployeeCreateMutation,
  CompanyEmployeeCreateMutationVariables
>;
export const CompanyEmployeePayrollSetDocument = gql`
  mutation CompanyEmployeePayrollSet(
    $companyID: ID!
    $userID: ID!
    $payrollAllowed: Boolean!
  ) {
    CompanyEmployeePayrollSet(
      companyID: $companyID
      userID: $userID
      payrollAllowed: $payrollAllowed
    )
  }
`;
export type CompanyEmployeePayrollSetMutationFn = Apollo.MutationFunction<
  CompanyEmployeePayrollSetMutation,
  CompanyEmployeePayrollSetMutationVariables
>;

/**
 * __useCompanyEmployeePayrollSetMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeePayrollSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeePayrollSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeePayrollSetMutation, { data, loading, error }] = useCompanyEmployeePayrollSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *      payrollAllowed: // value for 'payrollAllowed'
 *   },
 * });
 */
export function useCompanyEmployeePayrollSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeePayrollSetMutation,
    CompanyEmployeePayrollSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeePayrollSetMutation,
    CompanyEmployeePayrollSetMutationVariables
  >(CompanyEmployeePayrollSetDocument, options);
}
export type CompanyEmployeePayrollSetMutationHookResult = ReturnType<
  typeof useCompanyEmployeePayrollSetMutation
>;
export type CompanyEmployeePayrollSetMutationResult =
  Apollo.MutationResult<CompanyEmployeePayrollSetMutation>;
export type CompanyEmployeePayrollSetMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyEmployeePayrollSetMutation,
    CompanyEmployeePayrollSetMutationVariables
  >;
export const CompanyEmployeeShiftManagementOnOFfSetDocument = gql`
  mutation CompanyEmployeeShiftManagementOnOFfSet(
    $companyID: ID!
    $userID: ID!
    $onOff: Boolean!
  ) {
    CompanyEmployeeShiftManagementOnOFfSet(
      companyID: $companyID
      userID: $userID
      onOff: $onOff
    )
  }
`;
export type CompanyEmployeeShiftManagementOnOFfSetMutationFn =
  Apollo.MutationFunction<
    CompanyEmployeeShiftManagementOnOFfSetMutation,
    CompanyEmployeeShiftManagementOnOFfSetMutationVariables
  >;

/**
 * __useCompanyEmployeeShiftManagementOnOFfSetMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeShiftManagementOnOFfSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeShiftManagementOnOFfSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeShiftManagementOnOFfSetMutation, { data, loading, error }] = useCompanyEmployeeShiftManagementOnOFfSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *      onOff: // value for 'onOff'
 *   },
 * });
 */
export function useCompanyEmployeeShiftManagementOnOFfSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeShiftManagementOnOFfSetMutation,
    CompanyEmployeeShiftManagementOnOFfSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeShiftManagementOnOFfSetMutation,
    CompanyEmployeeShiftManagementOnOFfSetMutationVariables
  >(CompanyEmployeeShiftManagementOnOFfSetDocument, options);
}
export type CompanyEmployeeShiftManagementOnOFfSetMutationHookResult =
  ReturnType<typeof useCompanyEmployeeShiftManagementOnOFfSetMutation>;
export type CompanyEmployeeShiftManagementOnOFfSetMutationResult =
  Apollo.MutationResult<CompanyEmployeeShiftManagementOnOFfSetMutation>;
export type CompanyEmployeeShiftManagementOnOFfSetMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyEmployeeShiftManagementOnOFfSetMutation,
    CompanyEmployeeShiftManagementOnOFfSetMutationVariables
  >;
export const CompanyEmployeeUpdateDocument = gql`
  mutation CompanyEmployeeUpdate(
    $companyID: ID!
    $update: CompanyEmployeeUpdate!
  ) {
    CompanyEmployeeUpdate(companyID: $companyID, update: $update) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      lastName
      type
      contract
      employeeID
      email
      ktpUrl
      ktpNum
      npwpNum
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
      startDate
      endDate
      locationName
      locationCode
      customData
    }
  }
`;
export type CompanyEmployeeUpdateMutationFn = Apollo.MutationFunction<
  CompanyEmployeeUpdateMutation,
  CompanyEmployeeUpdateMutationVariables
>;

/**
 * __useCompanyEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeUpdateMutation, { data, loading, error }] = useCompanyEmployeeUpdateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useCompanyEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeUpdateMutation,
    CompanyEmployeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeUpdateMutation,
    CompanyEmployeeUpdateMutationVariables
  >(CompanyEmployeeUpdateDocument, options);
}
export type CompanyEmployeeUpdateMutationHookResult = ReturnType<
  typeof useCompanyEmployeeUpdateMutation
>;
export type CompanyEmployeeUpdateMutationResult =
  Apollo.MutationResult<CompanyEmployeeUpdateMutation>;
export type CompanyEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyEmployeeUpdateMutation,
  CompanyEmployeeUpdateMutationVariables
>;
export const CompanyManagerCreateDocument = gql`
  mutation CompanyManagerCreate(
    $companyID: ID!
    $username: String!
    $password: String!
  ) {
    CompanyManagerCreate(
      companyID: $companyID
      username: $username
      password: $password
    ) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
      employmentType
      contract
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
    }
  }
`;
export type CompanyManagerCreateMutationFn = Apollo.MutationFunction<
  CompanyManagerCreateMutation,
  CompanyManagerCreateMutationVariables
>;

/**
 * __useCompanyManagerCreateMutation__
 *
 * To run a mutation, you first call `useCompanyManagerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyManagerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyManagerCreateMutation, { data, loading, error }] = useCompanyManagerCreateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCompanyManagerCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyManagerCreateMutation,
    CompanyManagerCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyManagerCreateMutation,
    CompanyManagerCreateMutationVariables
  >(CompanyManagerCreateDocument, options);
}
export type CompanyManagerCreateMutationHookResult = ReturnType<
  typeof useCompanyManagerCreateMutation
>;
export type CompanyManagerCreateMutationResult =
  Apollo.MutationResult<CompanyManagerCreateMutation>;
export type CompanyManagerCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyManagerCreateMutation,
  CompanyManagerCreateMutationVariables
>;
export const CompanyManagerSetDocument = gql`
  mutation CompanyManagerSet(
    $companyID: ID!
    $memberID: ID!
    $isManager: Boolean!
  ) {
    CompanyManagerSet(
      companyID: $companyID
      memberID: $memberID
      isManager: $isManager
    )
  }
`;
export type CompanyManagerSetMutationFn = Apollo.MutationFunction<
  CompanyManagerSetMutation,
  CompanyManagerSetMutationVariables
>;

/**
 * __useCompanyManagerSetMutation__
 *
 * To run a mutation, you first call `useCompanyManagerSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyManagerSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyManagerSetMutation, { data, loading, error }] = useCompanyManagerSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      isManager: // value for 'isManager'
 *   },
 * });
 */
export function useCompanyManagerSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyManagerSetMutation,
    CompanyManagerSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyManagerSetMutation,
    CompanyManagerSetMutationVariables
  >(CompanyManagerSetDocument, options);
}
export type CompanyManagerSetMutationHookResult = ReturnType<
  typeof useCompanyManagerSetMutation
>;
export type CompanyManagerSetMutationResult =
  Apollo.MutationResult<CompanyManagerSetMutation>;
export type CompanyManagerSetMutationOptions = Apollo.BaseMutationOptions<
  CompanyManagerSetMutation,
  CompanyManagerSetMutationVariables
>;
export const CompanyMembershipCreateDocument = gql`
  mutation CompanyMembershipCreate($userID: ID!, $companyID: ID!) {
    CompanyMembershipCreate(userID: $userID, companyID: $companyID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
      employmentType
      contract
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
    }
  }
`;
export type CompanyMembershipCreateMutationFn = Apollo.MutationFunction<
  CompanyMembershipCreateMutation,
  CompanyMembershipCreateMutationVariables
>;

/**
 * __useCompanyMembershipCreateMutation__
 *
 * To run a mutation, you first call `useCompanyMembershipCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyMembershipCreateMutation, { data, loading, error }] = useCompanyMembershipCreateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyMembershipCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyMembershipCreateMutation,
    CompanyMembershipCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyMembershipCreateMutation,
    CompanyMembershipCreateMutationVariables
  >(CompanyMembershipCreateDocument, options);
}
export type CompanyMembershipCreateMutationHookResult = ReturnType<
  typeof useCompanyMembershipCreateMutation
>;
export type CompanyMembershipCreateMutationResult =
  Apollo.MutationResult<CompanyMembershipCreateMutation>;
export type CompanyMembershipCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyMembershipCreateMutation,
  CompanyMembershipCreateMutationVariables
>;
export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate($companyID: ID!, $inp: CompanySettingsInput!) {
    CompanyUpdate(companyID: $companyID, inp: $inp) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
      payrollEnabled
      attendanceEnabled
    }
  }
`;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;
export const HelloWorldMDocument = gql`
  mutation HelloWorldM($companyID: ID!) {
    HelloWorldM(companyID: $companyID)
  }
`;
export type HelloWorldMMutationFn = Apollo.MutationFunction<
  HelloWorldMMutation,
  HelloWorldMMutationVariables
>;

/**
 * __useHelloWorldMMutation__
 *
 * To run a mutation, you first call `useHelloWorldMMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHelloWorldMMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [helloWorldMMutation, { data, loading, error }] = useHelloWorldMMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useHelloWorldMMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HelloWorldMMutation,
    HelloWorldMMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HelloWorldMMutation, HelloWorldMMutationVariables>(
    HelloWorldMDocument,
    options,
  );
}
export type HelloWorldMMutationHookResult = ReturnType<
  typeof useHelloWorldMMutation
>;
export type HelloWorldMMutationResult =
  Apollo.MutationResult<HelloWorldMMutation>;
export type HelloWorldMMutationOptions = Apollo.BaseMutationOptions<
  HelloWorldMMutation,
  HelloWorldMMutationVariables
>;
export const PasswordResetDocument = gql`
  mutation PasswordReset($token: String!, $newPassword: String!) {
    PasswordReset(token: $token, newPassword: $newPassword)
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >(PasswordResetDocument, options);
}
export type PasswordResetMutationHookResult = ReturnType<
  typeof usePasswordResetMutation
>;
export type PasswordResetMutationResult =
  Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const PasswordResetSendOtpDocument = gql`
  mutation PasswordResetSendOtp($countryCode: Int!, $phoneNumber: Int!) {
    PasswordResetSendOtp(countryCode: $countryCode, phoneNumber: $phoneNumber)
  }
`;
export type PasswordResetSendOtpMutationFn = Apollo.MutationFunction<
  PasswordResetSendOtpMutation,
  PasswordResetSendOtpMutationVariables
>;

/**
 * __usePasswordResetSendOtpMutation__
 *
 * To run a mutation, you first call `usePasswordResetSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetSendOtpMutation, { data, loading, error }] = usePasswordResetSendOtpMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePasswordResetSendOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetSendOtpMutation,
    PasswordResetSendOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetSendOtpMutation,
    PasswordResetSendOtpMutationVariables
  >(PasswordResetSendOtpDocument, options);
}
export type PasswordResetSendOtpMutationHookResult = ReturnType<
  typeof usePasswordResetSendOtpMutation
>;
export type PasswordResetSendOtpMutationResult =
  Apollo.MutationResult<PasswordResetSendOtpMutation>;
export type PasswordResetSendOtpMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetSendOtpMutation,
  PasswordResetSendOtpMutationVariables
>;
export const PasswordResetVerifyOtpDocument = gql`
  mutation PasswordResetVerifyOtp($token: String!, $otpCode: String!) {
    PasswordResetVerifyOtp(token: $token, otpCode: $otpCode)
  }
`;
export type PasswordResetVerifyOtpMutationFn = Apollo.MutationFunction<
  PasswordResetVerifyOtpMutation,
  PasswordResetVerifyOtpMutationVariables
>;

/**
 * __usePasswordResetVerifyOtpMutation__
 *
 * To run a mutation, you first call `usePasswordResetVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetVerifyOtpMutation, { data, loading, error }] = usePasswordResetVerifyOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function usePasswordResetVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetVerifyOtpMutation,
    PasswordResetVerifyOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetVerifyOtpMutation,
    PasswordResetVerifyOtpMutationVariables
  >(PasswordResetVerifyOtpDocument, options);
}
export type PasswordResetVerifyOtpMutationHookResult = ReturnType<
  typeof usePasswordResetVerifyOtpMutation
>;
export type PasswordResetVerifyOtpMutationResult =
  Apollo.MutationResult<PasswordResetVerifyOtpMutation>;
export type PasswordResetVerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetVerifyOtpMutation,
  PasswordResetVerifyOtpMutationVariables
>;
export const PasswordUpdateDocument = gql`
  mutation PasswordUpdate($userID: ID!, $newPassword: String!) {
    PasswordUpdate(userID: $userID, newPassword: $newPassword)
  }
`;
export type PasswordUpdateMutationFn = Apollo.MutationFunction<
  PasswordUpdateMutation,
  PasswordUpdateMutationVariables
>;

/**
 * __usePasswordUpdateMutation__
 *
 * To run a mutation, you first call `usePasswordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordUpdateMutation, { data, loading, error }] = usePasswordUpdateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordUpdateMutation,
    PasswordUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordUpdateMutation,
    PasswordUpdateMutationVariables
  >(PasswordUpdateDocument, options);
}
export type PasswordUpdateMutationHookResult = ReturnType<
  typeof usePasswordUpdateMutation
>;
export type PasswordUpdateMutationResult =
  Apollo.MutationResult<PasswordUpdateMutation>;
export type PasswordUpdateMutationOptions = Apollo.BaseMutationOptions<
  PasswordUpdateMutation,
  PasswordUpdateMutationVariables
>;
export const PayrollAdditionCreateDocument = gql`
  mutation PayrollAdditionCreate($inp: AdditionInsert!) {
    PayrollAdditionCreate(inp: $inp)
  }
`;
export type PayrollAdditionCreateMutationFn = Apollo.MutationFunction<
  PayrollAdditionCreateMutation,
  PayrollAdditionCreateMutationVariables
>;

/**
 * __usePayrollAdditionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollAdditionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdditionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollAdditionCreateMutation, { data, loading, error }] = usePayrollAdditionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollAdditionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollAdditionCreateMutation,
    PayrollAdditionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollAdditionCreateMutation,
    PayrollAdditionCreateMutationVariables
  >(PayrollAdditionCreateDocument, options);
}
export type PayrollAdditionCreateMutationHookResult = ReturnType<
  typeof usePayrollAdditionCreateMutation
>;
export type PayrollAdditionCreateMutationResult =
  Apollo.MutationResult<PayrollAdditionCreateMutation>;
export type PayrollAdditionCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollAdditionCreateMutation,
  PayrollAdditionCreateMutationVariables
>;
export const PayrollCompanyBalanceAdditionDocument = gql`
  mutation PayrollCompanyBalanceAddition($inp: CompanyBalanceAdd!) {
    PayrollCompanyBalanceAddition(inp: $inp)
  }
`;
export type PayrollCompanyBalanceAdditionMutationFn = Apollo.MutationFunction<
  PayrollCompanyBalanceAdditionMutation,
  PayrollCompanyBalanceAdditionMutationVariables
>;

/**
 * __usePayrollCompanyBalanceAdditionMutation__
 *
 * To run a mutation, you first call `usePayrollCompanyBalanceAdditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyBalanceAdditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollCompanyBalanceAdditionMutation, { data, loading, error }] = usePayrollCompanyBalanceAdditionMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollCompanyBalanceAdditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollCompanyBalanceAdditionMutation,
    PayrollCompanyBalanceAdditionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollCompanyBalanceAdditionMutation,
    PayrollCompanyBalanceAdditionMutationVariables
  >(PayrollCompanyBalanceAdditionDocument, options);
}
export type PayrollCompanyBalanceAdditionMutationHookResult = ReturnType<
  typeof usePayrollCompanyBalanceAdditionMutation
>;
export type PayrollCompanyBalanceAdditionMutationResult =
  Apollo.MutationResult<PayrollCompanyBalanceAdditionMutation>;
export type PayrollCompanyBalanceAdditionMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollCompanyBalanceAdditionMutation,
    PayrollCompanyBalanceAdditionMutationVariables
  >;
export const PayrollCompanyBalanceDeductionDocument = gql`
  mutation PayrollCompanyBalanceDeduction($inp: CompanyBalanceDeduct!) {
    PayrollCompanyBalanceDeduction(inp: $inp)
  }
`;
export type PayrollCompanyBalanceDeductionMutationFn = Apollo.MutationFunction<
  PayrollCompanyBalanceDeductionMutation,
  PayrollCompanyBalanceDeductionMutationVariables
>;

/**
 * __usePayrollCompanyBalanceDeductionMutation__
 *
 * To run a mutation, you first call `usePayrollCompanyBalanceDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyBalanceDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollCompanyBalanceDeductionMutation, { data, loading, error }] = usePayrollCompanyBalanceDeductionMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollCompanyBalanceDeductionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollCompanyBalanceDeductionMutation,
    PayrollCompanyBalanceDeductionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollCompanyBalanceDeductionMutation,
    PayrollCompanyBalanceDeductionMutationVariables
  >(PayrollCompanyBalanceDeductionDocument, options);
}
export type PayrollCompanyBalanceDeductionMutationHookResult = ReturnType<
  typeof usePayrollCompanyBalanceDeductionMutation
>;
export type PayrollCompanyBalanceDeductionMutationResult =
  Apollo.MutationResult<PayrollCompanyBalanceDeductionMutation>;
export type PayrollCompanyBalanceDeductionMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollCompanyBalanceDeductionMutation,
    PayrollCompanyBalanceDeductionMutationVariables
  >;
export const PayrollDeductionCreateDocument = gql`
  mutation PayrollDeductionCreate($inp: DeductionInsert!) {
    PayrollDeductionCreate(inp: $inp)
  }
`;
export type PayrollDeductionCreateMutationFn = Apollo.MutationFunction<
  PayrollDeductionCreateMutation,
  PayrollDeductionCreateMutationVariables
>;

/**
 * __usePayrollDeductionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollDeductionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollDeductionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollDeductionCreateMutation, { data, loading, error }] = usePayrollDeductionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollDeductionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollDeductionCreateMutation,
    PayrollDeductionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollDeductionCreateMutation,
    PayrollDeductionCreateMutationVariables
  >(PayrollDeductionCreateDocument, options);
}
export type PayrollDeductionCreateMutationHookResult = ReturnType<
  typeof usePayrollDeductionCreateMutation
>;
export type PayrollDeductionCreateMutationResult =
  Apollo.MutationResult<PayrollDeductionCreateMutation>;
export type PayrollDeductionCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollDeductionCreateMutation,
  PayrollDeductionCreateMutationVariables
>;
export const PayrollEmployeeCreateDocument = gql`
  mutation PayrollEmployeeCreate($inp: PayrollEmployeeCreate!) {
    PayrollEmployeeCreate(inp: $inp) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      onBehalfOf
      statusPTKP
      customData
    }
  }
`;
export type PayrollEmployeeCreateMutationFn = Apollo.MutationFunction<
  PayrollEmployeeCreateMutation,
  PayrollEmployeeCreateMutationVariables
>;

/**
 * __usePayrollEmployeeCreateMutation__
 *
 * To run a mutation, you first call `usePayrollEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollEmployeeCreateMutation, { data, loading, error }] = usePayrollEmployeeCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollEmployeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollEmployeeCreateMutation,
    PayrollEmployeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollEmployeeCreateMutation,
    PayrollEmployeeCreateMutationVariables
  >(PayrollEmployeeCreateDocument, options);
}
export type PayrollEmployeeCreateMutationHookResult = ReturnType<
  typeof usePayrollEmployeeCreateMutation
>;
export type PayrollEmployeeCreateMutationResult =
  Apollo.MutationResult<PayrollEmployeeCreateMutation>;
export type PayrollEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEmployeeCreateMutation,
  PayrollEmployeeCreateMutationVariables
>;
export const PayrollEmployeeUpdateDocument = gql`
  mutation PayrollEmployeeUpdate($memberID: ID!, $inp: PayrollEmployeeCreate!) {
    PayrollEmployeeUpdate(memberID: $memberID, inp: $inp) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      onBehalfOf
      statusPTKP
      customData
    }
  }
`;
export type PayrollEmployeeUpdateMutationFn = Apollo.MutationFunction<
  PayrollEmployeeUpdateMutation,
  PayrollEmployeeUpdateMutationVariables
>;

/**
 * __usePayrollEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `usePayrollEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollEmployeeUpdateMutation, { data, loading, error }] = usePayrollEmployeeUpdateMutation({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollEmployeeUpdateMutation,
    PayrollEmployeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollEmployeeUpdateMutation,
    PayrollEmployeeUpdateMutationVariables
  >(PayrollEmployeeUpdateDocument, options);
}
export type PayrollEmployeeUpdateMutationHookResult = ReturnType<
  typeof usePayrollEmployeeUpdateMutation
>;
export type PayrollEmployeeUpdateMutationResult =
  Apollo.MutationResult<PayrollEmployeeUpdateMutation>;
export type PayrollEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEmployeeUpdateMutation,
  PayrollEmployeeUpdateMutationVariables
>;
export const PayrollFixedAdditionCreateDocument = gql`
  mutation PayrollFixedAdditionCreate($inp: FixedAdditionInsert!) {
    PayrollFixedAdditionCreate(inp: $inp)
  }
`;
export type PayrollFixedAdditionCreateMutationFn = Apollo.MutationFunction<
  PayrollFixedAdditionCreateMutation,
  PayrollFixedAdditionCreateMutationVariables
>;

/**
 * __usePayrollFixedAdditionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollFixedAdditionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAdditionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollFixedAdditionCreateMutation, { data, loading, error }] = usePayrollFixedAdditionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollFixedAdditionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollFixedAdditionCreateMutation,
    PayrollFixedAdditionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollFixedAdditionCreateMutation,
    PayrollFixedAdditionCreateMutationVariables
  >(PayrollFixedAdditionCreateDocument, options);
}
export type PayrollFixedAdditionCreateMutationHookResult = ReturnType<
  typeof usePayrollFixedAdditionCreateMutation
>;
export type PayrollFixedAdditionCreateMutationResult =
  Apollo.MutationResult<PayrollFixedAdditionCreateMutation>;
export type PayrollFixedAdditionCreateMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollFixedAdditionCreateMutation,
    PayrollFixedAdditionCreateMutationVariables
  >;
export const PayrollFixedDeductionCreateDocument = gql`
  mutation PayrollFixedDeductionCreate($inp: FixedDeductionInsert!) {
    PayrollFixedDeductionCreate(inp: $inp)
  }
`;
export type PayrollFixedDeductionCreateMutationFn = Apollo.MutationFunction<
  PayrollFixedDeductionCreateMutation,
  PayrollFixedDeductionCreateMutationVariables
>;

/**
 * __usePayrollFixedDeductionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollFixedDeductionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedDeductionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollFixedDeductionCreateMutation, { data, loading, error }] = usePayrollFixedDeductionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollFixedDeductionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollFixedDeductionCreateMutation,
    PayrollFixedDeductionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollFixedDeductionCreateMutation,
    PayrollFixedDeductionCreateMutationVariables
  >(PayrollFixedDeductionCreateDocument, options);
}
export type PayrollFixedDeductionCreateMutationHookResult = ReturnType<
  typeof usePayrollFixedDeductionCreateMutation
>;
export type PayrollFixedDeductionCreateMutationResult =
  Apollo.MutationResult<PayrollFixedDeductionCreateMutation>;
export type PayrollFixedDeductionCreateMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollFixedDeductionCreateMutation,
    PayrollFixedDeductionCreateMutationVariables
  >;
export const PayrollMultipleAdditionsCreateDocument = gql`
  mutation PayrollMultipleAdditionsCreate($inp: [AdditionInsert]!) {
    PayrollMultipleAdditionsCreate(inp: $inp)
  }
`;
export type PayrollMultipleAdditionsCreateMutationFn = Apollo.MutationFunction<
  PayrollMultipleAdditionsCreateMutation,
  PayrollMultipleAdditionsCreateMutationVariables
>;

/**
 * __usePayrollMultipleAdditionsCreateMutation__
 *
 * To run a mutation, you first call `usePayrollMultipleAdditionsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollMultipleAdditionsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollMultipleAdditionsCreateMutation, { data, loading, error }] = usePayrollMultipleAdditionsCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollMultipleAdditionsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollMultipleAdditionsCreateMutation,
    PayrollMultipleAdditionsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollMultipleAdditionsCreateMutation,
    PayrollMultipleAdditionsCreateMutationVariables
  >(PayrollMultipleAdditionsCreateDocument, options);
}
export type PayrollMultipleAdditionsCreateMutationHookResult = ReturnType<
  typeof usePayrollMultipleAdditionsCreateMutation
>;
export type PayrollMultipleAdditionsCreateMutationResult =
  Apollo.MutationResult<PayrollMultipleAdditionsCreateMutation>;
export type PayrollMultipleAdditionsCreateMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollMultipleAdditionsCreateMutation,
    PayrollMultipleAdditionsCreateMutationVariables
  >;
export const PayrollMultipleDeductionsCreateDocument = gql`
  mutation PayrollMultipleDeductionsCreate($inp: [DeductionInsert]!) {
    PayrollMultipleDeductionsCreate(inp: $inp)
  }
`;
export type PayrollMultipleDeductionsCreateMutationFn = Apollo.MutationFunction<
  PayrollMultipleDeductionsCreateMutation,
  PayrollMultipleDeductionsCreateMutationVariables
>;

/**
 * __usePayrollMultipleDeductionsCreateMutation__
 *
 * To run a mutation, you first call `usePayrollMultipleDeductionsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollMultipleDeductionsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollMultipleDeductionsCreateMutation, { data, loading, error }] = usePayrollMultipleDeductionsCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollMultipleDeductionsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollMultipleDeductionsCreateMutation,
    PayrollMultipleDeductionsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollMultipleDeductionsCreateMutation,
    PayrollMultipleDeductionsCreateMutationVariables
  >(PayrollMultipleDeductionsCreateDocument, options);
}
export type PayrollMultipleDeductionsCreateMutationHookResult = ReturnType<
  typeof usePayrollMultipleDeductionsCreateMutation
>;
export type PayrollMultipleDeductionsCreateMutationResult =
  Apollo.MutationResult<PayrollMultipleDeductionsCreateMutation>;
export type PayrollMultipleDeductionsCreateMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollMultipleDeductionsCreateMutation,
    PayrollMultipleDeductionsCreateMutationVariables
  >;
export const PayrollPeriodMemberExecuteDocument = gql`
  mutation PayrollPeriodMemberExecute(
    $companyID: ID!
    $memberID: ID!
    $periodID: ID!
  ) {
    PayrollPeriodMemberExecute(
      companyID: $companyID
      memberID: $memberID
      periodID: $periodID
    )
  }
`;
export type PayrollPeriodMemberExecuteMutationFn = Apollo.MutationFunction<
  PayrollPeriodMemberExecuteMutation,
  PayrollPeriodMemberExecuteMutationVariables
>;

/**
 * __usePayrollPeriodMemberExecuteMutation__
 *
 * To run a mutation, you first call `usePayrollPeriodMemberExecuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollPeriodMemberExecuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollPeriodMemberExecuteMutation, { data, loading, error }] = usePayrollPeriodMemberExecuteMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollPeriodMemberExecuteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >(PayrollPeriodMemberExecuteDocument, options);
}
export type PayrollPeriodMemberExecuteMutationHookResult = ReturnType<
  typeof usePayrollPeriodMemberExecuteMutation
>;
export type PayrollPeriodMemberExecuteMutationResult =
  Apollo.MutationResult<PayrollPeriodMemberExecuteMutation>;
export type PayrollPeriodMemberExecuteMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >;
export const PayrollRequestWageAndDisburseDocument = gql`
  mutation PayrollRequestWageAndDisburse($inp: PayrollWageRequestInsert!) {
    PayrollRequestWageAndDisburse(inp: $inp)
  }
`;
export type PayrollRequestWageAndDisburseMutationFn = Apollo.MutationFunction<
  PayrollRequestWageAndDisburseMutation,
  PayrollRequestWageAndDisburseMutationVariables
>;

/**
 * __usePayrollRequestWageAndDisburseMutation__
 *
 * To run a mutation, you first call `usePayrollRequestWageAndDisburseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollRequestWageAndDisburseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollRequestWageAndDisburseMutation, { data, loading, error }] = usePayrollRequestWageAndDisburseMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollRequestWageAndDisburseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollRequestWageAndDisburseMutation,
    PayrollRequestWageAndDisburseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollRequestWageAndDisburseMutation,
    PayrollRequestWageAndDisburseMutationVariables
  >(PayrollRequestWageAndDisburseDocument, options);
}
export type PayrollRequestWageAndDisburseMutationHookResult = ReturnType<
  typeof usePayrollRequestWageAndDisburseMutation
>;
export type PayrollRequestWageAndDisburseMutationResult =
  Apollo.MutationResult<PayrollRequestWageAndDisburseMutation>;
export type PayrollRequestWageAndDisburseMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollRequestWageAndDisburseMutation,
    PayrollRequestWageAndDisburseMutationVariables
  >;
export const PayrollTableDataUpdateDocument = gql`
  mutation PayrollTableDataUpdate(
    $memberID: ID!
    $inp: PayrollTableDataInsert!
  ) {
    PayrollTableDataUpdate(memberID: $memberID, inp: $inp) {
      memberID
      companyID
      periodID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      customData
      daysWorked
      phoneNumber
      totalAdditions
      totalDeductions
      status
      bankCode
      accountNumber
      bankVerificationStatus
    }
  }
`;
export type PayrollTableDataUpdateMutationFn = Apollo.MutationFunction<
  PayrollTableDataUpdateMutation,
  PayrollTableDataUpdateMutationVariables
>;

/**
 * __usePayrollTableDataUpdateMutation__
 *
 * To run a mutation, you first call `usePayrollTableDataUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollTableDataUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollTableDataUpdateMutation, { data, loading, error }] = usePayrollTableDataUpdateMutation({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollTableDataUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollTableDataUpdateMutation,
    PayrollTableDataUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollTableDataUpdateMutation,
    PayrollTableDataUpdateMutationVariables
  >(PayrollTableDataUpdateDocument, options);
}
export type PayrollTableDataUpdateMutationHookResult = ReturnType<
  typeof usePayrollTableDataUpdateMutation
>;
export type PayrollTableDataUpdateMutationResult =
  Apollo.MutationResult<PayrollTableDataUpdateMutation>;
export type PayrollTableDataUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayrollTableDataUpdateMutation,
  PayrollTableDataUpdateMutationVariables
>;
export const PayrollTopUpAddDocument = gql`
  mutation PayrollTopUpAdd($companyID: ID!, $amount: Int!) {
    PayrollTopUpAdd(companyID: $companyID, amount: $amount)
  }
`;
export type PayrollTopUpAddMutationFn = Apollo.MutationFunction<
  PayrollTopUpAddMutation,
  PayrollTopUpAddMutationVariables
>;

/**
 * __usePayrollTopUpAddMutation__
 *
 * To run a mutation, you first call `usePayrollTopUpAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollTopUpAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollTopUpAddMutation, { data, loading, error }] = usePayrollTopUpAddMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function usePayrollTopUpAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollTopUpAddMutation,
    PayrollTopUpAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollTopUpAddMutation,
    PayrollTopUpAddMutationVariables
  >(PayrollTopUpAddDocument, options);
}
export type PayrollTopUpAddMutationHookResult = ReturnType<
  typeof usePayrollTopUpAddMutation
>;
export type PayrollTopUpAddMutationResult =
  Apollo.MutationResult<PayrollTopUpAddMutation>;
export type PayrollTopUpAddMutationOptions = Apollo.BaseMutationOptions<
  PayrollTopUpAddMutation,
  PayrollTopUpAddMutationVariables
>;
export const PhoneInvitationCreateDocument = gql`
  mutation PhoneInvitationCreate(
    $companyID: ID!
    $insert: PhoneInvitationInsert!
  ) {
    PhoneInvitationCreate(companyID: $companyID, insert: $insert) {
      inviteID
      memberID
      companyID
      name
      phoneNumber
      monthlyLimit
      bankCode
      bankNumber
      accepted
      customData
    }
  }
`;
export type PhoneInvitationCreateMutationFn = Apollo.MutationFunction<
  PhoneInvitationCreateMutation,
  PhoneInvitationCreateMutationVariables
>;

/**
 * __usePhoneInvitationCreateMutation__
 *
 * To run a mutation, you first call `usePhoneInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneInvitationCreateMutation, { data, loading, error }] = usePhoneInvitationCreateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      insert: // value for 'insert'
 *   },
 * });
 */
export function usePhoneInvitationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneInvitationCreateMutation,
    PhoneInvitationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneInvitationCreateMutation,
    PhoneInvitationCreateMutationVariables
  >(PhoneInvitationCreateDocument, options);
}
export type PhoneInvitationCreateMutationHookResult = ReturnType<
  typeof usePhoneInvitationCreateMutation
>;
export type PhoneInvitationCreateMutationResult =
  Apollo.MutationResult<PhoneInvitationCreateMutation>;
export type PhoneInvitationCreateMutationOptions = Apollo.BaseMutationOptions<
  PhoneInvitationCreateMutation,
  PhoneInvitationCreateMutationVariables
>;
export const PhoneInvitationDeleteDocument = gql`
  mutation PhoneInvitationDelete($inviteID: ID!) {
    PhoneInvitationDelete(inviteID: $inviteID)
  }
`;
export type PhoneInvitationDeleteMutationFn = Apollo.MutationFunction<
  PhoneInvitationDeleteMutation,
  PhoneInvitationDeleteMutationVariables
>;

/**
 * __usePhoneInvitationDeleteMutation__
 *
 * To run a mutation, you first call `usePhoneInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneInvitationDeleteMutation, { data, loading, error }] = usePhoneInvitationDeleteMutation({
 *   variables: {
 *      inviteID: // value for 'inviteID'
 *   },
 * });
 */
export function usePhoneInvitationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneInvitationDeleteMutation,
    PhoneInvitationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneInvitationDeleteMutation,
    PhoneInvitationDeleteMutationVariables
  >(PhoneInvitationDeleteDocument, options);
}
export type PhoneInvitationDeleteMutationHookResult = ReturnType<
  typeof usePhoneInvitationDeleteMutation
>;
export type PhoneInvitationDeleteMutationResult =
  Apollo.MutationResult<PhoneInvitationDeleteMutation>;
export type PhoneInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
  PhoneInvitationDeleteMutation,
  PhoneInvitationDeleteMutationVariables
>;
export const PhoneOtpCreateDocument = gql`
  mutation PhoneOtpCreate($phoneNumber: Int!, $otpCode: String!) {
    PhoneOtpCreate(phoneNumber: $phoneNumber, otpCode: $otpCode)
  }
`;
export type PhoneOtpCreateMutationFn = Apollo.MutationFunction<
  PhoneOtpCreateMutation,
  PhoneOtpCreateMutationVariables
>;

/**
 * __usePhoneOtpCreateMutation__
 *
 * To run a mutation, you first call `usePhoneOtpCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneOtpCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneOtpCreateMutation, { data, loading, error }] = usePhoneOtpCreateMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function usePhoneOtpCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneOtpCreateMutation,
    PhoneOtpCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneOtpCreateMutation,
    PhoneOtpCreateMutationVariables
  >(PhoneOtpCreateDocument, options);
}
export type PhoneOtpCreateMutationHookResult = ReturnType<
  typeof usePhoneOtpCreateMutation
>;
export type PhoneOtpCreateMutationResult =
  Apollo.MutationResult<PhoneOtpCreateMutation>;
export type PhoneOtpCreateMutationOptions = Apollo.BaseMutationOptions<
  PhoneOtpCreateMutation,
  PhoneOtpCreateMutationVariables
>;
export const PhoneOtpDeleteDocument = gql`
  mutation PhoneOtpDelete($phoneNumber: Int!) {
    PhoneOtpDelete(phoneNumber: $phoneNumber)
  }
`;
export type PhoneOtpDeleteMutationFn = Apollo.MutationFunction<
  PhoneOtpDeleteMutation,
  PhoneOtpDeleteMutationVariables
>;

/**
 * __usePhoneOtpDeleteMutation__
 *
 * To run a mutation, you first call `usePhoneOtpDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneOtpDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneOtpDeleteMutation, { data, loading, error }] = usePhoneOtpDeleteMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePhoneOtpDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneOtpDeleteMutation,
    PhoneOtpDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneOtpDeleteMutation,
    PhoneOtpDeleteMutationVariables
  >(PhoneOtpDeleteDocument, options);
}
export type PhoneOtpDeleteMutationHookResult = ReturnType<
  typeof usePhoneOtpDeleteMutation
>;
export type PhoneOtpDeleteMutationResult =
  Apollo.MutationResult<PhoneOtpDeleteMutation>;
export type PhoneOtpDeleteMutationOptions = Apollo.BaseMutationOptions<
  PhoneOtpDeleteMutation,
  PhoneOtpDeleteMutationVariables
>;
export const RegistrationSendOtpDocument = gql`
  mutation RegistrationSendOTP(
    $token: String!
    $countryCode: Int!
    $phoneNumber: Int!
  ) {
    RegistrationSendOTP(
      token: $token
      countryCode: $countryCode
      phoneNumber: $phoneNumber
    )
  }
`;
export type RegistrationSendOtpMutationFn = Apollo.MutationFunction<
  RegistrationSendOtpMutation,
  RegistrationSendOtpMutationVariables
>;

/**
 * __useRegistrationSendOtpMutation__
 *
 * To run a mutation, you first call `useRegistrationSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSendOtpMutation, { data, loading, error }] = useRegistrationSendOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRegistrationSendOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSendOtpMutation,
    RegistrationSendOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSendOtpMutation,
    RegistrationSendOtpMutationVariables
  >(RegistrationSendOtpDocument, options);
}
export type RegistrationSendOtpMutationHookResult = ReturnType<
  typeof useRegistrationSendOtpMutation
>;
export type RegistrationSendOtpMutationResult =
  Apollo.MutationResult<RegistrationSendOtpMutation>;
export type RegistrationSendOtpMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSendOtpMutation,
  RegistrationSendOtpMutationVariables
>;
export const RegistrationSubmitBankDocument = gql`
  mutation RegistrationSubmitBank(
    $token: String!
    $bankCode: BankCode!
    $accountNumber: String!
  ) {
    RegistrationSubmitBank(
      token: $token
      bankCode: $bankCode
      accountNumber: $accountNumber
    )
  }
`;
export type RegistrationSubmitBankMutationFn = Apollo.MutationFunction<
  RegistrationSubmitBankMutation,
  RegistrationSubmitBankMutationVariables
>;

/**
 * __useRegistrationSubmitBankMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitBankMutation, { data, loading, error }] = useRegistrationSubmitBankMutation({
 *   variables: {
 *      token: // value for 'token'
 *      bankCode: // value for 'bankCode'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useRegistrationSubmitBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitBankMutation,
    RegistrationSubmitBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitBankMutation,
    RegistrationSubmitBankMutationVariables
  >(RegistrationSubmitBankDocument, options);
}
export type RegistrationSubmitBankMutationHookResult = ReturnType<
  typeof useRegistrationSubmitBankMutation
>;
export type RegistrationSubmitBankMutationResult =
  Apollo.MutationResult<RegistrationSubmitBankMutation>;
export type RegistrationSubmitBankMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSubmitBankMutation,
  RegistrationSubmitBankMutationVariables
>;
export const RegistrationSubmitInfoDocument = gql`
  mutation RegistrationSubmitInfo(
    $token: String!
    $ktp: Upload!
    $birthday: Time!
  ) {
    RegistrationSubmitInfo(token: $token, ktp: $ktp, birthday: $birthday)
  }
`;
export type RegistrationSubmitInfoMutationFn = Apollo.MutationFunction<
  RegistrationSubmitInfoMutation,
  RegistrationSubmitInfoMutationVariables
>;

/**
 * __useRegistrationSubmitInfoMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitInfoMutation, { data, loading, error }] = useRegistrationSubmitInfoMutation({
 *   variables: {
 *      token: // value for 'token'
 *      ktp: // value for 'ktp'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useRegistrationSubmitInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitInfoMutation,
    RegistrationSubmitInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitInfoMutation,
    RegistrationSubmitInfoMutationVariables
  >(RegistrationSubmitInfoDocument, options);
}
export type RegistrationSubmitInfoMutationHookResult = ReturnType<
  typeof useRegistrationSubmitInfoMutation
>;
export type RegistrationSubmitInfoMutationResult =
  Apollo.MutationResult<RegistrationSubmitInfoMutation>;
export type RegistrationSubmitInfoMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSubmitInfoMutation,
  RegistrationSubmitInfoMutationVariables
>;
export const RegistrationSubmitPasswordDocument = gql`
  mutation RegistrationSubmitPassword($token: String!, $password: String!) {
    RegistrationSubmitPassword(token: $token, password: $password) {
      name
      username
      token
    }
  }
`;
export type RegistrationSubmitPasswordMutationFn = Apollo.MutationFunction<
  RegistrationSubmitPasswordMutation,
  RegistrationSubmitPasswordMutationVariables
>;

/**
 * __useRegistrationSubmitPasswordMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitPasswordMutation, { data, loading, error }] = useRegistrationSubmitPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegistrationSubmitPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >(RegistrationSubmitPasswordDocument, options);
}
export type RegistrationSubmitPasswordMutationHookResult = ReturnType<
  typeof useRegistrationSubmitPasswordMutation
>;
export type RegistrationSubmitPasswordMutationResult =
  Apollo.MutationResult<RegistrationSubmitPasswordMutation>;
export type RegistrationSubmitPasswordMutationOptions =
  Apollo.BaseMutationOptions<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >;
export const RegistrationVerifyOtpDocument = gql`
  mutation RegistrationVerifyOTP($token: String!, $otpCode: String!) {
    RegistrationVerifyOTP(token: $token, otpCode: $otpCode) {
      name
      username
      token
    }
  }
`;
export type RegistrationVerifyOtpMutationFn = Apollo.MutationFunction<
  RegistrationVerifyOtpMutation,
  RegistrationVerifyOtpMutationVariables
>;

/**
 * __useRegistrationVerifyOtpMutation__
 *
 * To run a mutation, you first call `useRegistrationVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationVerifyOtpMutation, { data, loading, error }] = useRegistrationVerifyOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useRegistrationVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationVerifyOtpMutation,
    RegistrationVerifyOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationVerifyOtpMutation,
    RegistrationVerifyOtpMutationVariables
  >(RegistrationVerifyOtpDocument, options);
}
export type RegistrationVerifyOtpMutationHookResult = ReturnType<
  typeof useRegistrationVerifyOtpMutation
>;
export type RegistrationVerifyOtpMutationResult =
  Apollo.MutationResult<RegistrationVerifyOtpMutation>;
export type RegistrationVerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  RegistrationVerifyOtpMutation,
  RegistrationVerifyOtpMutationVariables
>;
export const UserCreateDocument = gql`
  mutation UserCreate($username: String!, $password: String!) {
    UserCreate(username: $username, password: $password) {
      userID
      username
    }
  }
`;
export type UserCreateMutationFn = Apollo.MutationFunction<
  UserCreateMutation,
  UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreateMutation,
    UserCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(
    UserCreateDocument,
    options,
  );
}
export type UserCreateMutationHookResult = ReturnType<
  typeof useUserCreateMutation
>;
export type UserCreateMutationResult =
  Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const UserEducationInfoCreateDocument = gql`
  mutation UserEducationInfoCreate($inp: UserEducationInfoInput!) {
    UserEducationInfoCreate(inp: $inp) {
      userID
      lastEducation
      institutionName
      educationStartYear
      educationEndYear
      workExperience
      jobDescription
      namePT
      workStartYear
      workEndYear
    }
  }
`;
export type UserEducationInfoCreateMutationFn = Apollo.MutationFunction<
  UserEducationInfoCreateMutation,
  UserEducationInfoCreateMutationVariables
>;

/**
 * __useUserEducationInfoCreateMutation__
 *
 * To run a mutation, you first call `useUserEducationInfoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEducationInfoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEducationInfoCreateMutation, { data, loading, error }] = useUserEducationInfoCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useUserEducationInfoCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserEducationInfoCreateMutation,
    UserEducationInfoCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserEducationInfoCreateMutation,
    UserEducationInfoCreateMutationVariables
  >(UserEducationInfoCreateDocument, options);
}
export type UserEducationInfoCreateMutationHookResult = ReturnType<
  typeof useUserEducationInfoCreateMutation
>;
export type UserEducationInfoCreateMutationResult =
  Apollo.MutationResult<UserEducationInfoCreateMutation>;
export type UserEducationInfoCreateMutationOptions = Apollo.BaseMutationOptions<
  UserEducationInfoCreateMutation,
  UserEducationInfoCreateMutationVariables
>;
export const UserEducationInfoUpdateDocument = gql`
  mutation UserEducationInfoUpdate($inp: UserEducationInfoUpdate!) {
    UserEducationInfoUpdate(inp: $inp) {
      userID
      lastEducation
      institutionName
      educationStartYear
      educationEndYear
      workExperience
      jobDescription
      namePT
      workStartYear
      workEndYear
    }
  }
`;
export type UserEducationInfoUpdateMutationFn = Apollo.MutationFunction<
  UserEducationInfoUpdateMutation,
  UserEducationInfoUpdateMutationVariables
>;

/**
 * __useUserEducationInfoUpdateMutation__
 *
 * To run a mutation, you first call `useUserEducationInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEducationInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEducationInfoUpdateMutation, { data, loading, error }] = useUserEducationInfoUpdateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useUserEducationInfoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserEducationInfoUpdateMutation,
    UserEducationInfoUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserEducationInfoUpdateMutation,
    UserEducationInfoUpdateMutationVariables
  >(UserEducationInfoUpdateDocument, options);
}
export type UserEducationInfoUpdateMutationHookResult = ReturnType<
  typeof useUserEducationInfoUpdateMutation
>;
export type UserEducationInfoUpdateMutationResult =
  Apollo.MutationResult<UserEducationInfoUpdateMutation>;
export type UserEducationInfoUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserEducationInfoUpdateMutation,
  UserEducationInfoUpdateMutationVariables
>;
export const UserFamilyInfoCreateDocument = gql`
  mutation UserFamilyInfoCreate($inp: UserFamilyInfoInput!) {
    UserFamilyInfoCreate(inp: $inp) {
      userID
      companyID
      famInfID
      name
      relationship
      birthday
      ktpNum
      jobTitle
    }
  }
`;
export type UserFamilyInfoCreateMutationFn = Apollo.MutationFunction<
  UserFamilyInfoCreateMutation,
  UserFamilyInfoCreateMutationVariables
>;

/**
 * __useUserFamilyInfoCreateMutation__
 *
 * To run a mutation, you first call `useUserFamilyInfoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFamilyInfoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFamilyInfoCreateMutation, { data, loading, error }] = useUserFamilyInfoCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useUserFamilyInfoCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserFamilyInfoCreateMutation,
    UserFamilyInfoCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserFamilyInfoCreateMutation,
    UserFamilyInfoCreateMutationVariables
  >(UserFamilyInfoCreateDocument, options);
}
export type UserFamilyInfoCreateMutationHookResult = ReturnType<
  typeof useUserFamilyInfoCreateMutation
>;
export type UserFamilyInfoCreateMutationResult =
  Apollo.MutationResult<UserFamilyInfoCreateMutation>;
export type UserFamilyInfoCreateMutationOptions = Apollo.BaseMutationOptions<
  UserFamilyInfoCreateMutation,
  UserFamilyInfoCreateMutationVariables
>;
export const UserFamilyInfoUpdateDocument = gql`
  mutation UserFamilyInfoUpdate($famInfID: ID!, $inp: UserFamilyInfoUpdate!) {
    UserFamilyInfoUpdate(famInfID: $famInfID, inp: $inp) {
      userID
      companyID
      famInfID
      name
      relationship
      birthday
      ktpNum
      jobTitle
    }
  }
`;
export type UserFamilyInfoUpdateMutationFn = Apollo.MutationFunction<
  UserFamilyInfoUpdateMutation,
  UserFamilyInfoUpdateMutationVariables
>;

/**
 * __useUserFamilyInfoUpdateMutation__
 *
 * To run a mutation, you first call `useUserFamilyInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFamilyInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFamilyInfoUpdateMutation, { data, loading, error }] = useUserFamilyInfoUpdateMutation({
 *   variables: {
 *      famInfID: // value for 'famInfID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useUserFamilyInfoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserFamilyInfoUpdateMutation,
    UserFamilyInfoUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserFamilyInfoUpdateMutation,
    UserFamilyInfoUpdateMutationVariables
  >(UserFamilyInfoUpdateDocument, options);
}
export type UserFamilyInfoUpdateMutationHookResult = ReturnType<
  typeof useUserFamilyInfoUpdateMutation
>;
export type UserFamilyInfoUpdateMutationResult =
  Apollo.MutationResult<UserFamilyInfoUpdateMutation>;
export type UserFamilyInfoUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserFamilyInfoUpdateMutation,
  UserFamilyInfoUpdateMutationVariables
>;
export const UserProfileCreateDocument = gql`
  mutation UserProfileCreate($inp: UserProfileUpdate!) {
    UserProfileCreate(inp: $inp)
  }
`;
export type UserProfileCreateMutationFn = Apollo.MutationFunction<
  UserProfileCreateMutation,
  UserProfileCreateMutationVariables
>;

/**
 * __useUserProfileCreateMutation__
 *
 * To run a mutation, you first call `useUserProfileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileCreateMutation, { data, loading, error }] = useUserProfileCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useUserProfileCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserProfileCreateMutation,
    UserProfileCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserProfileCreateMutation,
    UserProfileCreateMutationVariables
  >(UserProfileCreateDocument, options);
}
export type UserProfileCreateMutationHookResult = ReturnType<
  typeof useUserProfileCreateMutation
>;
export type UserProfileCreateMutationResult =
  Apollo.MutationResult<UserProfileCreateMutation>;
export type UserProfileCreateMutationOptions = Apollo.BaseMutationOptions<
  UserProfileCreateMutation,
  UserProfileCreateMutationVariables
>;
export const UserProfileUpdateDocument = gql`
  mutation UserProfileUpdate($userID: ID!, $update: UserProfileUpdate!) {
    UserProfileUpdate(userID: $userID, update: $update)
  }
`;
export type UserProfileUpdateMutationFn = Apollo.MutationFunction<
  UserProfileUpdateMutation,
  UserProfileUpdateMutationVariables
>;

/**
 * __useUserProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUserProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileUpdateMutation, { data, loading, error }] = useUserProfileUpdateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUserProfileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
  >(UserProfileUpdateDocument, options);
}
export type UserProfileUpdateMutationHookResult = ReturnType<
  typeof useUserProfileUpdateMutation
>;
export type UserProfileUpdateMutationResult =
  Apollo.MutationResult<UserProfileUpdateMutation>;
export type UserProfileUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserProfileUpdateMutation,
  UserProfileUpdateMutationVariables
>;
export const UserUpdatePasswordDocument = gql`
  mutation UserUpdatePassword($userID: String!, $newPassword: String!) {
    UserUpdatePassword(userID: $userID, newPassword: $newPassword)
  }
`;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >(UserUpdatePasswordDocument, options);
}
export type UserUpdatePasswordMutationHookResult = ReturnType<
  typeof useUserUpdatePasswordMutation
>;
export type UserUpdatePasswordMutationResult =
  Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;
export const AllowanceRequestFeeDocument = gql`
  query AllowanceRequestFee($userID: String!, $amount: Int!) {
    AllowanceRequestFee(userID: $userID, amount: $amount)
  }
`;

/**
 * __useAllowanceRequestFeeQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestFeeQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAllowanceRequestFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >(AllowanceRequestFeeDocument, options);
}
export function useAllowanceRequestFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >(AllowanceRequestFeeDocument, options);
}
export type AllowanceRequestFeeQueryHookResult = ReturnType<
  typeof useAllowanceRequestFeeQuery
>;
export type AllowanceRequestFeeLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestFeeLazyQuery
>;
export type AllowanceRequestFeeQueryResult = Apollo.QueryResult<
  AllowanceRequestFeeQuery,
  AllowanceRequestFeeQueryVariables
>;
export const AllowanceRequestMinimumDocument = gql`
  query AllowanceRequestMinimum($userID: String!) {
    AllowanceRequestMinimum(userID: $userID)
  }
`;

/**
 * __useAllowanceRequestMinimumQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestMinimumQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestMinimumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestMinimumQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAllowanceRequestMinimumQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >(AllowanceRequestMinimumDocument, options);
}
export function useAllowanceRequestMinimumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >(AllowanceRequestMinimumDocument, options);
}
export type AllowanceRequestMinimumQueryHookResult = ReturnType<
  typeof useAllowanceRequestMinimumQuery
>;
export type AllowanceRequestMinimumLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestMinimumLazyQuery
>;
export type AllowanceRequestMinimumQueryResult = Apollo.QueryResult<
  AllowanceRequestMinimumQuery,
  AllowanceRequestMinimumQueryVariables
>;
export const AllowanceRequestRetrieveChannelResponsesDocument = gql`
  query AllowanceRequestRetrieveChannelResponses($requestID: ID!) {
    AllowanceRequestRetrieveChannelResponses(requestID: $requestID)
  }
`;

/**
 * __useAllowanceRequestRetrieveChannelResponsesQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestRetrieveChannelResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestRetrieveChannelResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestRetrieveChannelResponsesQuery({
 *   variables: {
 *      requestID: // value for 'requestID'
 *   },
 * });
 */
export function useAllowanceRequestRetrieveChannelResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRequestRetrieveChannelResponsesQuery,
    AllowanceRequestRetrieveChannelResponsesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestRetrieveChannelResponsesQuery,
    AllowanceRequestRetrieveChannelResponsesQueryVariables
  >(AllowanceRequestRetrieveChannelResponsesDocument, options);
}
export function useAllowanceRequestRetrieveChannelResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestRetrieveChannelResponsesQuery,
    AllowanceRequestRetrieveChannelResponsesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestRetrieveChannelResponsesQuery,
    AllowanceRequestRetrieveChannelResponsesQueryVariables
  >(AllowanceRequestRetrieveChannelResponsesDocument, options);
}
export type AllowanceRequestRetrieveChannelResponsesQueryHookResult =
  ReturnType<typeof useAllowanceRequestRetrieveChannelResponsesQuery>;
export type AllowanceRequestRetrieveChannelResponsesLazyQueryHookResult =
  ReturnType<typeof useAllowanceRequestRetrieveChannelResponsesLazyQuery>;
export type AllowanceRequestRetrieveChannelResponsesQueryResult =
  Apollo.QueryResult<
    AllowanceRequestRetrieveChannelResponsesQuery,
    AllowanceRequestRetrieveChannelResponsesQueryVariables
  >;
export const AllowanceRequestRetrieveUnapprovedDocument = gql`
  query AllowanceRequestRetrieveUnapproved {
    AllowanceRequestRetrieveUnapproved {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;

/**
 * __useAllowanceRequestRetrieveUnapprovedQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestRetrieveUnapprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestRetrieveUnapprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestRetrieveUnapprovedQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowanceRequestRetrieveUnapprovedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >(AllowanceRequestRetrieveUnapprovedDocument, options);
}
export function useAllowanceRequestRetrieveUnapprovedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >(AllowanceRequestRetrieveUnapprovedDocument, options);
}
export type AllowanceRequestRetrieveUnapprovedQueryHookResult = ReturnType<
  typeof useAllowanceRequestRetrieveUnapprovedQuery
>;
export type AllowanceRequestRetrieveUnapprovedLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestRetrieveUnapprovedLazyQuery
>;
export type AllowanceRequestRetrieveUnapprovedQueryResult = Apollo.QueryResult<
  AllowanceRequestRetrieveUnapprovedQuery,
  AllowanceRequestRetrieveUnapprovedQueryVariables
>;
export const AllowanceRetrieveDocument = gql`
  query AllowanceRetrieve($userID: String!) {
    AllowanceRetrieve(userID: $userID)
  }
`;

/**
 * __useAllowanceRetrieveQuery__
 *
 * To run a query within a React component, call `useAllowanceRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAllowanceRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >(AllowanceRetrieveDocument, options);
}
export function useAllowanceRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >(AllowanceRetrieveDocument, options);
}
export type AllowanceRetrieveQueryHookResult = ReturnType<
  typeof useAllowanceRetrieveQuery
>;
export type AllowanceRetrieveLazyQueryHookResult = ReturnType<
  typeof useAllowanceRetrieveLazyQuery
>;
export type AllowanceRetrieveQueryResult = Apollo.QueryResult<
  AllowanceRetrieveQuery,
  AllowanceRetrieveQueryVariables
>;
export const AuthRoleRetrieveDocument = gql`
  query AuthRoleRetrieve {
    AuthRoleRetrieve {
      userID
      companyID
      memberID
      level
    }
  }
`;

/**
 * __useAuthRoleRetrieveQuery__
 *
 * To run a query within a React component, call `useAuthRoleRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRoleRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRoleRetrieveQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthRoleRetrieveQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthRoleRetrieveQuery, AuthRoleRetrieveQueryVariables>(
    AuthRoleRetrieveDocument,
    options,
  );
}
export function useAuthRoleRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >(AuthRoleRetrieveDocument, options);
}
export type AuthRoleRetrieveQueryHookResult = ReturnType<
  typeof useAuthRoleRetrieveQuery
>;
export type AuthRoleRetrieveLazyQueryHookResult = ReturnType<
  typeof useAuthRoleRetrieveLazyQuery
>;
export type AuthRoleRetrieveQueryResult = Apollo.QueryResult<
  AuthRoleRetrieveQuery,
  AuthRoleRetrieveQueryVariables
>;
export const BankAccountRetrieveDefaultDocument = gql`
  query BankAccountRetrieveDefault($userID: ID!) {
    BankAccountRetrieveDefault(userID: $userID) {
      bankKey
      bankCode
      accountNumber
      verificationStatus
    }
  }
`;

/**
 * __useBankAccountRetrieveDefaultQuery__
 *
 * To run a query within a React component, call `useBankAccountRetrieveDefaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountRetrieveDefaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountRetrieveDefaultQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useBankAccountRetrieveDefaultQuery(
  baseOptions: Apollo.QueryHookOptions<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >(BankAccountRetrieveDefaultDocument, options);
}
export function useBankAccountRetrieveDefaultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >(BankAccountRetrieveDefaultDocument, options);
}
export type BankAccountRetrieveDefaultQueryHookResult = ReturnType<
  typeof useBankAccountRetrieveDefaultQuery
>;
export type BankAccountRetrieveDefaultLazyQueryHookResult = ReturnType<
  typeof useBankAccountRetrieveDefaultLazyQuery
>;
export type BankAccountRetrieveDefaultQueryResult = Apollo.QueryResult<
  BankAccountRetrieveDefaultQuery,
  BankAccountRetrieveDefaultQueryVariables
>;
export const CompanyEmployeeRetrieveDocument = gql`
  query CompanyEmployeeRetrieve($companyID: ID!, $userID: ID!) {
    CompanyEmployeeRetrieve(companyID: $companyID, userID: $userID) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      lastName
      type
      contract
      employeeID
      email
      ktpUrl
      ktpNum
      npwpNum
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
      startDate
      endDate
      locationName
      locationCode
      customData
    }
  }
`;

/**
 * __useCompanyEmployeeRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyEmployeeRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEmployeeRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useCompanyEmployeeRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >(CompanyEmployeeRetrieveDocument, options);
}
export function useCompanyEmployeeRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >(CompanyEmployeeRetrieveDocument, options);
}
export type CompanyEmployeeRetrieveQueryHookResult = ReturnType<
  typeof useCompanyEmployeeRetrieveQuery
>;
export type CompanyEmployeeRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyEmployeeRetrieveLazyQuery
>;
export type CompanyEmployeeRetrieveQueryResult = Apollo.QueryResult<
  CompanyEmployeeRetrieveQuery,
  CompanyEmployeeRetrieveQueryVariables
>;
export const CompanyEmployeesRetrieveDocument = gql`
  query CompanyEmployeesRetrieve($companyID: ID!) {
    CompanyEmployeesRetrieve(companyID: $companyID) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      lastName
      type
      contract
      employeeID
      email
      ktpUrl
      ktpNum
      npwpNum
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
      startDate
      endDate
      locationName
      locationCode
      customData
    }
  }
`;

/**
 * __useCompanyEmployeesRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyEmployeesRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeesRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEmployeesRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyEmployeesRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >(CompanyEmployeesRetrieveDocument, options);
}
export function useCompanyEmployeesRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >(CompanyEmployeesRetrieveDocument, options);
}
export type CompanyEmployeesRetrieveQueryHookResult = ReturnType<
  typeof useCompanyEmployeesRetrieveQuery
>;
export type CompanyEmployeesRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyEmployeesRetrieveLazyQuery
>;
export type CompanyEmployeesRetrieveQueryResult = Apollo.QueryResult<
  CompanyEmployeesRetrieveQuery,
  CompanyEmployeesRetrieveQueryVariables
>;
export const CompanyInfoRetrieveDocument = gql`
  query CompanyInfoRetrieve($companyID: ID!) {
    CompanyInfoRetrieve(companyID: $companyID) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
      payrollEnabled
      attendanceEnabled
    }
  }
`;

/**
 * __useCompanyInfoRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyInfoRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInfoRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInfoRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyInfoRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >(CompanyInfoRetrieveDocument, options);
}
export function useCompanyInfoRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >(CompanyInfoRetrieveDocument, options);
}
export type CompanyInfoRetrieveQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveQuery
>;
export type CompanyInfoRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveLazyQuery
>;
export type CompanyInfoRetrieveQueryResult = Apollo.QueryResult<
  CompanyInfoRetrieveQuery,
  CompanyInfoRetrieveQueryVariables
>;
export const CompanyInfoRetrieveAllDocument = gql`
  query CompanyInfoRetrieveAll {
    CompanyInfoRetrieveAll {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
      payrollEnabled
      attendanceEnabled
    }
  }
`;

/**
 * __useCompanyInfoRetrieveAllQuery__
 *
 * To run a query within a React component, call `useCompanyInfoRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInfoRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInfoRetrieveAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyInfoRetrieveAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >(CompanyInfoRetrieveAllDocument, options);
}
export function useCompanyInfoRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >(CompanyInfoRetrieveAllDocument, options);
}
export type CompanyInfoRetrieveAllQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveAllQuery
>;
export type CompanyInfoRetrieveAllLazyQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveAllLazyQuery
>;
export type CompanyInfoRetrieveAllQueryResult = Apollo.QueryResult<
  CompanyInfoRetrieveAllQuery,
  CompanyInfoRetrieveAllQueryVariables
>;
export const CompanyMembershipRetrieveDocument = gql`
  query CompanyMembershipRetrieve($userID: ID!) {
    CompanyMembershipRetrieve(userID: $userID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
      employmentType
      contract
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
    }
  }
`;

/**
 * __useCompanyMembershipRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useCompanyMembershipRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >(CompanyMembershipRetrieveDocument, options);
}
export function useCompanyMembershipRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >(CompanyMembershipRetrieveDocument, options);
}
export type CompanyMembershipRetrieveQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveQuery
>;
export type CompanyMembershipRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveLazyQuery
>;
export type CompanyMembershipRetrieveQueryResult = Apollo.QueryResult<
  CompanyMembershipRetrieveQuery,
  CompanyMembershipRetrieveQueryVariables
>;
export const CompanyMembershipRetrieveAllDocument = gql`
  query CompanyMembershipRetrieveAll($companyID: ID!) {
    CompanyMembershipRetrieveAll(companyID: $companyID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
      employmentType
      contract
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
    }
  }
`;

/**
 * __useCompanyMembershipRetrieveAllQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipRetrieveAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyMembershipRetrieveAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >(CompanyMembershipRetrieveAllDocument, options);
}
export function useCompanyMembershipRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >(CompanyMembershipRetrieveAllDocument, options);
}
export type CompanyMembershipRetrieveAllQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveAllQuery
>;
export type CompanyMembershipRetrieveAllLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveAllLazyQuery
>;
export type CompanyMembershipRetrieveAllQueryResult = Apollo.QueryResult<
  CompanyMembershipRetrieveAllQuery,
  CompanyMembershipRetrieveAllQueryVariables
>;
export const CompanyMembershipsReadAllDocument = gql`
  query CompanyMembershipsReadAll {
    CompanyMembershipsReadAll {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
      employmentType
      contract
      payrollAllowed
      attendanceAllowed
      attendanceOnOff
      shiftManagementOnOff
    }
  }
`;

/**
 * __useCompanyMembershipsReadAllQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipsReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyMembershipsReadAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >(CompanyMembershipsReadAllDocument, options);
}
export function useCompanyMembershipsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >(CompanyMembershipsReadAllDocument, options);
}
export type CompanyMembershipsReadAllQueryHookResult = ReturnType<
  typeof useCompanyMembershipsReadAllQuery
>;
export type CompanyMembershipsReadAllLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipsReadAllLazyQuery
>;
export type CompanyMembershipsReadAllQueryResult = Apollo.QueryResult<
  CompanyMembershipsReadAllQuery,
  CompanyMembershipsReadAllQueryVariables
>;
export const DashboardsVisibleRetrieveDocument = gql`
  query DashboardsVisibleRetrieve($companyID: ID!) {
    DashboardsVisibleRetrieve(companyID: $companyID)
  }
`;

/**
 * __useDashboardsVisibleRetrieveQuery__
 *
 * To run a query within a React component, call `useDashboardsVisibleRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsVisibleRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsVisibleRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useDashboardsVisibleRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >(DashboardsVisibleRetrieveDocument, options);
}
export function useDashboardsVisibleRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >(DashboardsVisibleRetrieveDocument, options);
}
export type DashboardsVisibleRetrieveQueryHookResult = ReturnType<
  typeof useDashboardsVisibleRetrieveQuery
>;
export type DashboardsVisibleRetrieveLazyQueryHookResult = ReturnType<
  typeof useDashboardsVisibleRetrieveLazyQuery
>;
export type DashboardsVisibleRetrieveQueryResult = Apollo.QueryResult<
  DashboardsVisibleRetrieveQuery,
  DashboardsVisibleRetrieveQueryVariables
>;
export const FamilyInfoReadAllByCompanyDocument = gql`
  query FamilyInfoReadAllByCompany($companyID: ID!) {
    FamilyInfoReadAllByCompany(companyID: $companyID) {
      userID
      companyID
      famInfID
      name
      relationship
      birthday
      ktpNum
      jobTitle
    }
  }
`;

/**
 * __useFamilyInfoReadAllByCompanyQuery__
 *
 * To run a query within a React component, call `useFamilyInfoReadAllByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyInfoReadAllByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyInfoReadAllByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useFamilyInfoReadAllByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FamilyInfoReadAllByCompanyQuery,
    FamilyInfoReadAllByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FamilyInfoReadAllByCompanyQuery,
    FamilyInfoReadAllByCompanyQueryVariables
  >(FamilyInfoReadAllByCompanyDocument, options);
}
export function useFamilyInfoReadAllByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FamilyInfoReadAllByCompanyQuery,
    FamilyInfoReadAllByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FamilyInfoReadAllByCompanyQuery,
    FamilyInfoReadAllByCompanyQueryVariables
  >(FamilyInfoReadAllByCompanyDocument, options);
}
export type FamilyInfoReadAllByCompanyQueryHookResult = ReturnType<
  typeof useFamilyInfoReadAllByCompanyQuery
>;
export type FamilyInfoReadAllByCompanyLazyQueryHookResult = ReturnType<
  typeof useFamilyInfoReadAllByCompanyLazyQuery
>;
export type FamilyInfoReadAllByCompanyQueryResult = Apollo.QueryResult<
  FamilyInfoReadAllByCompanyQuery,
  FamilyInfoReadAllByCompanyQueryVariables
>;
export const FlipBalanceDocument = gql`
  query FlipBalance {
    FlipBalance
  }
`;

/**
 * __useFlipBalanceQuery__
 *
 * To run a query within a React component, call `useFlipBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlipBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlipBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlipBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FlipBalanceQuery,
    FlipBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlipBalanceQuery, FlipBalanceQueryVariables>(
    FlipBalanceDocument,
    options,
  );
}
export function useFlipBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlipBalanceQuery,
    FlipBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlipBalanceQuery, FlipBalanceQueryVariables>(
    FlipBalanceDocument,
    options,
  );
}
export type FlipBalanceQueryHookResult = ReturnType<typeof useFlipBalanceQuery>;
export type FlipBalanceLazyQueryHookResult = ReturnType<
  typeof useFlipBalanceLazyQuery
>;
export type FlipBalanceQueryResult = Apollo.QueryResult<
  FlipBalanceQuery,
  FlipBalanceQueryVariables
>;
export const FlipTransactionsJsonDocument = gql`
  query FlipTransactionsJSON {
    FlipTransactionsJSON
  }
`;

/**
 * __useFlipTransactionsJsonQuery__
 *
 * To run a query within a React component, call `useFlipTransactionsJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlipTransactionsJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlipTransactionsJsonQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlipTransactionsJsonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FlipTransactionsJsonQuery,
    FlipTransactionsJsonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FlipTransactionsJsonQuery,
    FlipTransactionsJsonQueryVariables
  >(FlipTransactionsJsonDocument, options);
}
export function useFlipTransactionsJsonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlipTransactionsJsonQuery,
    FlipTransactionsJsonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FlipTransactionsJsonQuery,
    FlipTransactionsJsonQueryVariables
  >(FlipTransactionsJsonDocument, options);
}
export type FlipTransactionsJsonQueryHookResult = ReturnType<
  typeof useFlipTransactionsJsonQuery
>;
export type FlipTransactionsJsonLazyQueryHookResult = ReturnType<
  typeof useFlipTransactionsJsonLazyQuery
>;
export type FlipTransactionsJsonQueryResult = Apollo.QueryResult<
  FlipTransactionsJsonQuery,
  FlipTransactionsJsonQueryVariables
>;
export const HelloWorldQDocument = gql`
  query HelloWorldQ {
    HelloWorldQ
  }
`;

/**
 * __useHelloWorldQQuery__
 *
 * To run a query within a React component, call `useHelloWorldQQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloWorldQQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloWorldQQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloWorldQQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HelloWorldQQuery,
    HelloWorldQQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HelloWorldQQuery, HelloWorldQQueryVariables>(
    HelloWorldQDocument,
    options,
  );
}
export function useHelloWorldQLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HelloWorldQQuery,
    HelloWorldQQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HelloWorldQQuery, HelloWorldQQueryVariables>(
    HelloWorldQDocument,
    options,
  );
}
export type HelloWorldQQueryHookResult = ReturnType<typeof useHelloWorldQQuery>;
export type HelloWorldQLazyQueryHookResult = ReturnType<
  typeof useHelloWorldQLazyQuery
>;
export type HelloWorldQQueryResult = Apollo.QueryResult<
  HelloWorldQQuery,
  HelloWorldQQueryVariables
>;
export const PayrollAdditionsReadAllDocument = gql`
  query PayrollAdditionsReadAll(
    $memberID: ID!
    $companyID: ID!
    $periodID: ID!
  ) {
    PayrollAdditionsReadAll(
      memberID: $memberID
      companyID: $companyID
      periodID: $periodID
    ) {
      memberID
      companyID
      periodID
      amount
      reason
      type
      date
    }
  }
`;

/**
 * __usePayrollAdditionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollAdditionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdditionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollAdditionsReadAllQuery({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      companyID: // value for 'companyID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollAdditionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >(PayrollAdditionsReadAllDocument, options);
}
export function usePayrollAdditionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >(PayrollAdditionsReadAllDocument, options);
}
export type PayrollAdditionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollAdditionsReadAllQuery
>;
export type PayrollAdditionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollAdditionsReadAllLazyQuery
>;
export type PayrollAdditionsReadAllQueryResult = Apollo.QueryResult<
  PayrollAdditionsReadAllQuery,
  PayrollAdditionsReadAllQueryVariables
>;
export const PayrollCompanyBalanceReadDocument = gql`
  query PayrollCompanyBalanceRead($companyID: ID!) {
    PayrollCompanyBalanceRead(companyID: $companyID) {
      companyID
      amount
      refillDate
    }
  }
`;

/**
 * __usePayrollCompanyBalanceReadQuery__
 *
 * To run a query within a React component, call `usePayrollCompanyBalanceReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyBalanceReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCompanyBalanceReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollCompanyBalanceReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollCompanyBalanceReadQuery,
    PayrollCompanyBalanceReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollCompanyBalanceReadQuery,
    PayrollCompanyBalanceReadQueryVariables
  >(PayrollCompanyBalanceReadDocument, options);
}
export function usePayrollCompanyBalanceReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollCompanyBalanceReadQuery,
    PayrollCompanyBalanceReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollCompanyBalanceReadQuery,
    PayrollCompanyBalanceReadQueryVariables
  >(PayrollCompanyBalanceReadDocument, options);
}
export type PayrollCompanyBalanceReadQueryHookResult = ReturnType<
  typeof usePayrollCompanyBalanceReadQuery
>;
export type PayrollCompanyBalanceReadLazyQueryHookResult = ReturnType<
  typeof usePayrollCompanyBalanceReadLazyQuery
>;
export type PayrollCompanyBalanceReadQueryResult = Apollo.QueryResult<
  PayrollCompanyBalanceReadQuery,
  PayrollCompanyBalanceReadQueryVariables
>;
export const PayrollCompanyPayslipsReadDocument = gql`
  query PayrollCompanyPayslipsRead($companyID: ID!, $periodID: ID!) {
    PayrollCompanyPayslipsRead(companyID: $companyID, periodID: $periodID) {
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      additions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      deductions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      amount
      startTime
      endTime
      createdAt
      disbursedAt
    }
  }
`;

/**
 * __usePayrollCompanyPayslipsReadQuery__
 *
 * To run a query within a React component, call `usePayrollCompanyPayslipsReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyPayslipsReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCompanyPayslipsReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollCompanyPayslipsReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollCompanyPayslipsReadQuery,
    PayrollCompanyPayslipsReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollCompanyPayslipsReadQuery,
    PayrollCompanyPayslipsReadQueryVariables
  >(PayrollCompanyPayslipsReadDocument, options);
}
export function usePayrollCompanyPayslipsReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollCompanyPayslipsReadQuery,
    PayrollCompanyPayslipsReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollCompanyPayslipsReadQuery,
    PayrollCompanyPayslipsReadQueryVariables
  >(PayrollCompanyPayslipsReadDocument, options);
}
export type PayrollCompanyPayslipsReadQueryHookResult = ReturnType<
  typeof usePayrollCompanyPayslipsReadQuery
>;
export type PayrollCompanyPayslipsReadLazyQueryHookResult = ReturnType<
  typeof usePayrollCompanyPayslipsReadLazyQuery
>;
export type PayrollCompanyPayslipsReadQueryResult = Apollo.QueryResult<
  PayrollCompanyPayslipsReadQuery,
  PayrollCompanyPayslipsReadQueryVariables
>;
export const PayrollDaysWorkedReadAllDocument = gql`
  query PayrollDaysWorkedReadAll($companyID: ID!) {
    PayrollDaysWorkedReadAll(companyID: $companyID) {
      memberID
      companyID
      periodID
      daysWorked
    }
  }
`;

/**
 * __usePayrollDaysWorkedReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollDaysWorkedReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollDaysWorkedReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollDaysWorkedReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollDaysWorkedReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >(PayrollDaysWorkedReadAllDocument, options);
}
export function usePayrollDaysWorkedReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >(PayrollDaysWorkedReadAllDocument, options);
}
export type PayrollDaysWorkedReadAllQueryHookResult = ReturnType<
  typeof usePayrollDaysWorkedReadAllQuery
>;
export type PayrollDaysWorkedReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollDaysWorkedReadAllLazyQuery
>;
export type PayrollDaysWorkedReadAllQueryResult = Apollo.QueryResult<
  PayrollDaysWorkedReadAllQuery,
  PayrollDaysWorkedReadAllQueryVariables
>;
export const PayrollDeductionsReadAllDocument = gql`
  query PayrollDeductionsReadAll(
    $memberID: ID!
    $companyID: ID!
    $periodID: ID!
  ) {
    PayrollDeductionsReadAll(
      memberID: $memberID
      companyID: $companyID
      periodID: $periodID
    ) {
      memberID
      companyID
      periodID
      amount
      reason
      type
      date
    }
  }
`;

/**
 * __usePayrollDeductionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollDeductionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollDeductionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollDeductionsReadAllQuery({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      companyID: // value for 'companyID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollDeductionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >(PayrollDeductionsReadAllDocument, options);
}
export function usePayrollDeductionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >(PayrollDeductionsReadAllDocument, options);
}
export type PayrollDeductionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollDeductionsReadAllQuery
>;
export type PayrollDeductionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollDeductionsReadAllLazyQuery
>;
export type PayrollDeductionsReadAllQueryResult = Apollo.QueryResult<
  PayrollDeductionsReadAllQuery,
  PayrollDeductionsReadAllQueryVariables
>;
export const PayrollEmployeePayslipsRangeReadDocument = gql`
  query PayrollEmployeePayslipsRangeRead(
    $companyID: ID!
    $memberID: ID!
    $startDate: String!
    $endDate: String!
  ) {
    PayrollEmployeePayslipsRangeRead(
      companyID: $companyID
      memberID: $memberID
      startDate: $startDate
      endDate: $endDate
    ) {
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      additions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      deductions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      amount
      startTime
      endTime
      createdAt
      disbursedAt
    }
  }
`;

/**
 * __usePayrollEmployeePayslipsRangeReadQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeePayslipsRangeReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeePayslipsRangeReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeePayslipsRangeReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePayrollEmployeePayslipsRangeReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeePayslipsRangeReadQuery,
    PayrollEmployeePayslipsRangeReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeePayslipsRangeReadQuery,
    PayrollEmployeePayslipsRangeReadQueryVariables
  >(PayrollEmployeePayslipsRangeReadDocument, options);
}
export function usePayrollEmployeePayslipsRangeReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeePayslipsRangeReadQuery,
    PayrollEmployeePayslipsRangeReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeePayslipsRangeReadQuery,
    PayrollEmployeePayslipsRangeReadQueryVariables
  >(PayrollEmployeePayslipsRangeReadDocument, options);
}
export type PayrollEmployeePayslipsRangeReadQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsRangeReadQuery
>;
export type PayrollEmployeePayslipsRangeReadLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsRangeReadLazyQuery
>;
export type PayrollEmployeePayslipsRangeReadQueryResult = Apollo.QueryResult<
  PayrollEmployeePayslipsRangeReadQuery,
  PayrollEmployeePayslipsRangeReadQueryVariables
>;
export const PayrollEmployeePayslipsReadDocument = gql`
  query PayrollEmployeePayslipsRead(
    $companyID: ID!
    $memberID: ID!
    $periodID: ID!
  ) {
    PayrollEmployeePayslipsRead(
      companyID: $companyID
      memberID: $memberID
      periodID: $periodID
    ) {
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      additions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      deductions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      amount
      startTime
      endTime
      createdAt
      disbursedAt
    }
  }
`;

/**
 * __usePayrollEmployeePayslipsReadQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeePayslipsReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeePayslipsReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeePayslipsReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollEmployeePayslipsReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeePayslipsReadQuery,
    PayrollEmployeePayslipsReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeePayslipsReadQuery,
    PayrollEmployeePayslipsReadQueryVariables
  >(PayrollEmployeePayslipsReadDocument, options);
}
export function usePayrollEmployeePayslipsReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeePayslipsReadQuery,
    PayrollEmployeePayslipsReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeePayslipsReadQuery,
    PayrollEmployeePayslipsReadQueryVariables
  >(PayrollEmployeePayslipsReadDocument, options);
}
export type PayrollEmployeePayslipsReadQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsReadQuery
>;
export type PayrollEmployeePayslipsReadLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsReadLazyQuery
>;
export type PayrollEmployeePayslipsReadQueryResult = Apollo.QueryResult<
  PayrollEmployeePayslipsReadQuery,
  PayrollEmployeePayslipsReadQueryVariables
>;
export const PayrollEmployeePayslipsReadAllDocument = gql`
  query PayrollEmployeePayslipsReadAll($companyID: ID!, $memberID: ID!) {
    PayrollEmployeePayslipsReadAll(companyID: $companyID, memberID: $memberID) {
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      additions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      deductions {
        memberID
        companyID
        periodID
        amount
        reason
        type
        date
      }
      amount
      startTime
      endTime
      createdAt
      disbursedAt
    }
  }
`;

/**
 * __usePayrollEmployeePayslipsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeePayslipsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeePayslipsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeePayslipsReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *   },
 * });
 */
export function usePayrollEmployeePayslipsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeePayslipsReadAllQuery,
    PayrollEmployeePayslipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeePayslipsReadAllQuery,
    PayrollEmployeePayslipsReadAllQueryVariables
  >(PayrollEmployeePayslipsReadAllDocument, options);
}
export function usePayrollEmployeePayslipsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeePayslipsReadAllQuery,
    PayrollEmployeePayslipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeePayslipsReadAllQuery,
    PayrollEmployeePayslipsReadAllQueryVariables
  >(PayrollEmployeePayslipsReadAllDocument, options);
}
export type PayrollEmployeePayslipsReadAllQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsReadAllQuery
>;
export type PayrollEmployeePayslipsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeePayslipsReadAllLazyQuery
>;
export type PayrollEmployeePayslipsReadAllQueryResult = Apollo.QueryResult<
  PayrollEmployeePayslipsReadAllQuery,
  PayrollEmployeePayslipsReadAllQueryVariables
>;
export const PayrollEmployeeReadDocument = gql`
  query PayrollEmployeeRead($companyID: ID!, $memberID: ID!) {
    PayrollEmployeeRead(companyID: $companyID, memberID: $memberID) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      onBehalfOf
      statusPTKP
      customData
    }
  }
`;

/**
 * __usePayrollEmployeeReadQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeeReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeeReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeeReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *   },
 * });
 */
export function usePayrollEmployeeReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeeReadQuery,
    PayrollEmployeeReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeeReadQuery,
    PayrollEmployeeReadQueryVariables
  >(PayrollEmployeeReadDocument, options);
}
export function usePayrollEmployeeReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeeReadQuery,
    PayrollEmployeeReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeeReadQuery,
    PayrollEmployeeReadQueryVariables
  >(PayrollEmployeeReadDocument, options);
}
export type PayrollEmployeeReadQueryHookResult = ReturnType<
  typeof usePayrollEmployeeReadQuery
>;
export type PayrollEmployeeReadLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeeReadLazyQuery
>;
export type PayrollEmployeeReadQueryResult = Apollo.QueryResult<
  PayrollEmployeeReadQuery,
  PayrollEmployeeReadQueryVariables
>;
export const PayrollEmployeesReadAllDocument = gql`
  query PayrollEmployeesReadAll($companyID: ID!) {
    PayrollEmployeesReadAll(companyID: $companyID) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      onBehalfOf
      statusPTKP
      customData
    }
  }
`;

/**
 * __usePayrollEmployeesReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeesReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeesReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeesReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollEmployeesReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >(PayrollEmployeesReadAllDocument, options);
}
export function usePayrollEmployeesReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >(PayrollEmployeesReadAllDocument, options);
}
export type PayrollEmployeesReadAllQueryHookResult = ReturnType<
  typeof usePayrollEmployeesReadAllQuery
>;
export type PayrollEmployeesReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeesReadAllLazyQuery
>;
export type PayrollEmployeesReadAllQueryResult = Apollo.QueryResult<
  PayrollEmployeesReadAllQuery,
  PayrollEmployeesReadAllQueryVariables
>;
export const PayrollFixedAdditionsReadAllDocument = gql`
  query PayrollFixedAdditionsReadAll($memberID: ID!, $companyID: ID!) {
    PayrollFixedAdditionsReadAll(memberID: $memberID, companyID: $companyID) {
      memberID
      companyID
      amount
      reason
      date
    }
  }
`;

/**
 * __usePayrollFixedAdditionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAdditionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAdditionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAdditionsReadAllQuery({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollFixedAdditionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollFixedAdditionsReadAllQuery,
    PayrollFixedAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollFixedAdditionsReadAllQuery,
    PayrollFixedAdditionsReadAllQueryVariables
  >(PayrollFixedAdditionsReadAllDocument, options);
}
export function usePayrollFixedAdditionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollFixedAdditionsReadAllQuery,
    PayrollFixedAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollFixedAdditionsReadAllQuery,
    PayrollFixedAdditionsReadAllQueryVariables
  >(PayrollFixedAdditionsReadAllDocument, options);
}
export type PayrollFixedAdditionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollFixedAdditionsReadAllQuery
>;
export type PayrollFixedAdditionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollFixedAdditionsReadAllLazyQuery
>;
export type PayrollFixedAdditionsReadAllQueryResult = Apollo.QueryResult<
  PayrollFixedAdditionsReadAllQuery,
  PayrollFixedAdditionsReadAllQueryVariables
>;
export const PayrollFixedDeductionsReadAllDocument = gql`
  query PayrollFixedDeductionsReadAll($memberID: ID!, $companyID: ID!) {
    PayrollFixedDeductionsReadAll(memberID: $memberID, companyID: $companyID) {
      memberID
      companyID
      amount
      reason
      date
    }
  }
`;

/**
 * __usePayrollFixedDeductionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollFixedDeductionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedDeductionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedDeductionsReadAllQuery({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollFixedDeductionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollFixedDeductionsReadAllQuery,
    PayrollFixedDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollFixedDeductionsReadAllQuery,
    PayrollFixedDeductionsReadAllQueryVariables
  >(PayrollFixedDeductionsReadAllDocument, options);
}
export function usePayrollFixedDeductionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollFixedDeductionsReadAllQuery,
    PayrollFixedDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollFixedDeductionsReadAllQuery,
    PayrollFixedDeductionsReadAllQueryVariables
  >(PayrollFixedDeductionsReadAllDocument, options);
}
export type PayrollFixedDeductionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollFixedDeductionsReadAllQuery
>;
export type PayrollFixedDeductionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollFixedDeductionsReadAllLazyQuery
>;
export type PayrollFixedDeductionsReadAllQueryResult = Apollo.QueryResult<
  PayrollFixedDeductionsReadAllQuery,
  PayrollFixedDeductionsReadAllQueryVariables
>;
export const PayrollLogActivityRetrieveAllDocument = gql`
  query PayrollLogActivityRetrieveAll($companyID: ID!) {
    PayrollLogActivityRetrieveAll(companyID: $companyID) {
      periodID
      status
    }
  }
`;

/**
 * __usePayrollLogActivityRetrieveAllQuery__
 *
 * To run a query within a React component, call `usePayrollLogActivityRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollLogActivityRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollLogActivityRetrieveAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollLogActivityRetrieveAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollLogActivityRetrieveAllQuery,
    PayrollLogActivityRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollLogActivityRetrieveAllQuery,
    PayrollLogActivityRetrieveAllQueryVariables
  >(PayrollLogActivityRetrieveAllDocument, options);
}
export function usePayrollLogActivityRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollLogActivityRetrieveAllQuery,
    PayrollLogActivityRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollLogActivityRetrieveAllQuery,
    PayrollLogActivityRetrieveAllQueryVariables
  >(PayrollLogActivityRetrieveAllDocument, options);
}
export type PayrollLogActivityRetrieveAllQueryHookResult = ReturnType<
  typeof usePayrollLogActivityRetrieveAllQuery
>;
export type PayrollLogActivityRetrieveAllLazyQueryHookResult = ReturnType<
  typeof usePayrollLogActivityRetrieveAllLazyQuery
>;
export type PayrollLogActivityRetrieveAllQueryResult = Apollo.QueryResult<
  PayrollLogActivityRetrieveAllQuery,
  PayrollLogActivityRetrieveAllQueryVariables
>;
export const PayrollPeriodDataReadDocument = gql`
  query PayrollPeriodDataRead($companyID: ID!) {
    PayrollPeriodDataRead(companyID: $companyID) {
      periodID
      startTime
      endTime
    }
  }
`;

/**
 * __usePayrollPeriodDataReadQuery__
 *
 * To run a query within a React component, call `usePayrollPeriodDataReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollPeriodDataReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollPeriodDataReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollPeriodDataReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >(PayrollPeriodDataReadDocument, options);
}
export function usePayrollPeriodDataReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >(PayrollPeriodDataReadDocument, options);
}
export type PayrollPeriodDataReadQueryHookResult = ReturnType<
  typeof usePayrollPeriodDataReadQuery
>;
export type PayrollPeriodDataReadLazyQueryHookResult = ReturnType<
  typeof usePayrollPeriodDataReadLazyQuery
>;
export type PayrollPeriodDataReadQueryResult = Apollo.QueryResult<
  PayrollPeriodDataReadQuery,
  PayrollPeriodDataReadQueryVariables
>;
export const PayrollPreviewTableDataByPeriodDocument = gql`
  query PayrollPreviewTableDataByPeriod($companyID: ID!, $periodID: ID!) {
    PayrollPreviewTableDataByPeriod(
      companyID: $companyID
      periodID: $periodID
    ) {
      companyID
      periodID
      totalEmployees
      totalPayroll
    }
  }
`;

/**
 * __usePayrollPreviewTableDataByPeriodQuery__
 *
 * To run a query within a React component, call `usePayrollPreviewTableDataByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollPreviewTableDataByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollPreviewTableDataByPeriodQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollPreviewTableDataByPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollPreviewTableDataByPeriodQuery,
    PayrollPreviewTableDataByPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollPreviewTableDataByPeriodQuery,
    PayrollPreviewTableDataByPeriodQueryVariables
  >(PayrollPreviewTableDataByPeriodDocument, options);
}
export function usePayrollPreviewTableDataByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollPreviewTableDataByPeriodQuery,
    PayrollPreviewTableDataByPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollPreviewTableDataByPeriodQuery,
    PayrollPreviewTableDataByPeriodQueryVariables
  >(PayrollPreviewTableDataByPeriodDocument, options);
}
export type PayrollPreviewTableDataByPeriodQueryHookResult = ReturnType<
  typeof usePayrollPreviewTableDataByPeriodQuery
>;
export type PayrollPreviewTableDataByPeriodLazyQueryHookResult = ReturnType<
  typeof usePayrollPreviewTableDataByPeriodLazyQuery
>;
export type PayrollPreviewTableDataByPeriodQueryResult = Apollo.QueryResult<
  PayrollPreviewTableDataByPeriodQuery,
  PayrollPreviewTableDataByPeriodQueryVariables
>;
export const PayrollTableDataByPeriodAllDocument = gql`
  query PayrollTableDataByPeriodAll($companyID: ID!, $periodID: ID!) {
    PayrollTableDataByPeriodAll(companyID: $companyID, periodID: $periodID) {
      memberID
      companyID
      periodID
      name
      taxTkRate
      taxKesRate
      taxTFRate
      salaryType
      salary
      customData
      daysWorked
      phoneNumber
      totalAdditions
      totalDeductions
      status
      bankCode
      accountNumber
      bankVerificationStatus
    }
  }
`;

/**
 * __usePayrollTableDataByPeriodAllQuery__
 *
 * To run a query within a React component, call `usePayrollTableDataByPeriodAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollTableDataByPeriodAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollTableDataByPeriodAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollTableDataByPeriodAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollTableDataByPeriodAllQuery,
    PayrollTableDataByPeriodAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollTableDataByPeriodAllQuery,
    PayrollTableDataByPeriodAllQueryVariables
  >(PayrollTableDataByPeriodAllDocument, options);
}
export function usePayrollTableDataByPeriodAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollTableDataByPeriodAllQuery,
    PayrollTableDataByPeriodAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollTableDataByPeriodAllQuery,
    PayrollTableDataByPeriodAllQueryVariables
  >(PayrollTableDataByPeriodAllDocument, options);
}
export type PayrollTableDataByPeriodAllQueryHookResult = ReturnType<
  typeof usePayrollTableDataByPeriodAllQuery
>;
export type PayrollTableDataByPeriodAllLazyQueryHookResult = ReturnType<
  typeof usePayrollTableDataByPeriodAllLazyQuery
>;
export type PayrollTableDataByPeriodAllQueryResult = Apollo.QueryResult<
  PayrollTableDataByPeriodAllQuery,
  PayrollTableDataByPeriodAllQueryVariables
>;
export const PayrollTopUpRetrieveDocument = gql`
  query PayrollTopUpRetrieve($companyID: ID!) {
    PayrollTopUpRetrieve(companyID: $companyID)
  }
`;

/**
 * __usePayrollTopUpRetrieveQuery__
 *
 * To run a query within a React component, call `usePayrollTopUpRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollTopUpRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollTopUpRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollTopUpRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >(PayrollTopUpRetrieveDocument, options);
}
export function usePayrollTopUpRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >(PayrollTopUpRetrieveDocument, options);
}
export type PayrollTopUpRetrieveQueryHookResult = ReturnType<
  typeof usePayrollTopUpRetrieveQuery
>;
export type PayrollTopUpRetrieveLazyQueryHookResult = ReturnType<
  typeof usePayrollTopUpRetrieveLazyQuery
>;
export type PayrollTopUpRetrieveQueryResult = Apollo.QueryResult<
  PayrollTopUpRetrieveQuery,
  PayrollTopUpRetrieveQueryVariables
>;
export const PeriodRetrieveDocument = gql`
  query PeriodRetrieve($periodID: ID!) {
    PeriodRetrieve(periodID: $periodID) {
      periodID
      companyID
      startTime
      endTime
    }
  }
`;

/**
 * __usePeriodRetrieveQuery__
 *
 * To run a query within a React component, call `usePeriodRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodRetrieveQuery({
 *   variables: {
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePeriodRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodRetrieveQuery,
    PeriodRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeriodRetrieveQuery, PeriodRetrieveQueryVariables>(
    PeriodRetrieveDocument,
    options,
  );
}
export function usePeriodRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodRetrieveQuery,
    PeriodRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeriodRetrieveQuery, PeriodRetrieveQueryVariables>(
    PeriodRetrieveDocument,
    options,
  );
}
export type PeriodRetrieveQueryHookResult = ReturnType<
  typeof usePeriodRetrieveQuery
>;
export type PeriodRetrieveLazyQueryHookResult = ReturnType<
  typeof usePeriodRetrieveLazyQuery
>;
export type PeriodRetrieveQueryResult = Apollo.QueryResult<
  PeriodRetrieveQuery,
  PeriodRetrieveQueryVariables
>;
export const PeriodsRetrieveAllDocument = gql`
  query PeriodsRetrieveAll($companyID: ID!) {
    PeriodsRetrieveAll(companyID: $companyID) {
      periodID
      companyID
      startTime
      endTime
    }
  }
`;

/**
 * __usePeriodsRetrieveAllQuery__
 *
 * To run a query within a React component, call `usePeriodsRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsRetrieveAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePeriodsRetrieveAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >(PeriodsRetrieveAllDocument, options);
}
export function usePeriodsRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >(PeriodsRetrieveAllDocument, options);
}
export type PeriodsRetrieveAllQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllQuery
>;
export type PeriodsRetrieveAllLazyQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllLazyQuery
>;
export type PeriodsRetrieveAllQueryResult = Apollo.QueryResult<
  PeriodsRetrieveAllQuery,
  PeriodsRetrieveAllQueryVariables
>;
export const PeriodsRetrieveAllWithKeyDocument = gql`
  query PeriodsRetrieveAllWithKey($companyID: ID!, $periodKey: String!) {
    PeriodsRetrieveAllWithKey(companyID: $companyID, periodKey: $periodKey) {
      periodID
      companyID
      startTime
      endTime
    }
  }
`;

/**
 * __usePeriodsRetrieveAllWithKeyQuery__
 *
 * To run a query within a React component, call `usePeriodsRetrieveAllWithKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsRetrieveAllWithKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsRetrieveAllWithKeyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      periodKey: // value for 'periodKey'
 *   },
 * });
 */
export function usePeriodsRetrieveAllWithKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodsRetrieveAllWithKeyQuery,
    PeriodsRetrieveAllWithKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PeriodsRetrieveAllWithKeyQuery,
    PeriodsRetrieveAllWithKeyQueryVariables
  >(PeriodsRetrieveAllWithKeyDocument, options);
}
export function usePeriodsRetrieveAllWithKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodsRetrieveAllWithKeyQuery,
    PeriodsRetrieveAllWithKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PeriodsRetrieveAllWithKeyQuery,
    PeriodsRetrieveAllWithKeyQueryVariables
  >(PeriodsRetrieveAllWithKeyDocument, options);
}
export type PeriodsRetrieveAllWithKeyQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllWithKeyQuery
>;
export type PeriodsRetrieveAllWithKeyLazyQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllWithKeyLazyQuery
>;
export type PeriodsRetrieveAllWithKeyQueryResult = Apollo.QueryResult<
  PeriodsRetrieveAllWithKeyQuery,
  PeriodsRetrieveAllWithKeyQueryVariables
>;
export const PhoneInvitationsRetrieveDocument = gql`
  query PhoneInvitationsRetrieve($companyID: ID!, $accepted: Boolean) {
    PhoneInvitationsRetrieve(companyID: $companyID, accepted: $accepted) {
      inviteID
      memberID
      companyID
      name
      phoneNumber
      monthlyLimit
      bankCode
      bankNumber
      accepted
      customData
    }
  }
`;

/**
 * __usePhoneInvitationsRetrieveQuery__
 *
 * To run a query within a React component, call `usePhoneInvitationsRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationsRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneInvitationsRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function usePhoneInvitationsRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >(PhoneInvitationsRetrieveDocument, options);
}
export function usePhoneInvitationsRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >(PhoneInvitationsRetrieveDocument, options);
}
export type PhoneInvitationsRetrieveQueryHookResult = ReturnType<
  typeof usePhoneInvitationsRetrieveQuery
>;
export type PhoneInvitationsRetrieveLazyQueryHookResult = ReturnType<
  typeof usePhoneInvitationsRetrieveLazyQuery
>;
export type PhoneInvitationsRetrieveQueryResult = Apollo.QueryResult<
  PhoneInvitationsRetrieveQuery,
  PhoneInvitationsRetrieveQueryVariables
>;
export const TransactionEventRetrieveByCompanyIdDocument = gql`
  query TransactionEventRetrieveByCompanyID($companyID: String!) {
    TransactionEventRetrieveByCompanyID(companyID: $companyID) {
      eventID
      companyID
      periodID
      memberID
      amount
      fee
      principal
      description
      status
      idempotencyKey
      typ
      allocatedAt
      createdAt
    }
  }
`;

/**
 * __useTransactionEventRetrieveByCompanyIdQuery__
 *
 * To run a query within a React component, call `useTransactionEventRetrieveByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionEventRetrieveByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionEventRetrieveByCompanyIdQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useTransactionEventRetrieveByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >(TransactionEventRetrieveByCompanyIdDocument, options);
}
export function useTransactionEventRetrieveByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >(TransactionEventRetrieveByCompanyIdDocument, options);
}
export type TransactionEventRetrieveByCompanyIdQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByCompanyIdQuery
>;
export type TransactionEventRetrieveByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByCompanyIdLazyQuery
>;
export type TransactionEventRetrieveByCompanyIdQueryResult = Apollo.QueryResult<
  TransactionEventRetrieveByCompanyIdQuery,
  TransactionEventRetrieveByCompanyIdQueryVariables
>;
export const TransactionEventRetrieveByUserIdDocument = gql`
  query TransactionEventRetrieveByUserID($userID: String!) {
    TransactionEventRetrieveByUserID(userID: $userID) {
      eventID
      companyID
      periodID
      memberID
      amount
      fee
      principal
      description
      status
      idempotencyKey
      typ
      allocatedAt
      createdAt
    }
  }
`;

/**
 * __useTransactionEventRetrieveByUserIdQuery__
 *
 * To run a query within a React component, call `useTransactionEventRetrieveByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionEventRetrieveByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionEventRetrieveByUserIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useTransactionEventRetrieveByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >(TransactionEventRetrieveByUserIdDocument, options);
}
export function useTransactionEventRetrieveByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >(TransactionEventRetrieveByUserIdDocument, options);
}
export type TransactionEventRetrieveByUserIdQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByUserIdQuery
>;
export type TransactionEventRetrieveByUserIdLazyQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByUserIdLazyQuery
>;
export type TransactionEventRetrieveByUserIdQueryResult = Apollo.QueryResult<
  TransactionEventRetrieveByUserIdQuery,
  TransactionEventRetrieveByUserIdQueryVariables
>;
export const UserEducationInfoRetrieveDocument = gql`
  query UserEducationInfoRetrieve($userID: ID!) {
    UserEducationInfoRetrieve(userID: $userID) {
      userID
      lastEducation
      institutionName
      educationStartYear
      educationEndYear
      workExperience
      jobDescription
      namePT
      workStartYear
      workEndYear
    }
  }
`;

/**
 * __useUserEducationInfoRetrieveQuery__
 *
 * To run a query within a React component, call `useUserEducationInfoRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEducationInfoRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEducationInfoRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserEducationInfoRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEducationInfoRetrieveQuery,
    UserEducationInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserEducationInfoRetrieveQuery,
    UserEducationInfoRetrieveQueryVariables
  >(UserEducationInfoRetrieveDocument, options);
}
export function useUserEducationInfoRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEducationInfoRetrieveQuery,
    UserEducationInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserEducationInfoRetrieveQuery,
    UserEducationInfoRetrieveQueryVariables
  >(UserEducationInfoRetrieveDocument, options);
}
export type UserEducationInfoRetrieveQueryHookResult = ReturnType<
  typeof useUserEducationInfoRetrieveQuery
>;
export type UserEducationInfoRetrieveLazyQueryHookResult = ReturnType<
  typeof useUserEducationInfoRetrieveLazyQuery
>;
export type UserEducationInfoRetrieveQueryResult = Apollo.QueryResult<
  UserEducationInfoRetrieveQuery,
  UserEducationInfoRetrieveQueryVariables
>;
export const UserFamilyInfoReadAllDocument = gql`
  query UserFamilyInfoReadAll($userID: ID!) {
    UserFamilyInfoReadAll(userID: $userID) {
      userID
      companyID
      famInfID
      name
      relationship
      birthday
      ktpNum
      jobTitle
    }
  }
`;

/**
 * __useUserFamilyInfoReadAllQuery__
 *
 * To run a query within a React component, call `useUserFamilyInfoReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFamilyInfoReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFamilyInfoReadAllQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserFamilyInfoReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFamilyInfoReadAllQuery,
    UserFamilyInfoReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserFamilyInfoReadAllQuery,
    UserFamilyInfoReadAllQueryVariables
  >(UserFamilyInfoReadAllDocument, options);
}
export function useUserFamilyInfoReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFamilyInfoReadAllQuery,
    UserFamilyInfoReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserFamilyInfoReadAllQuery,
    UserFamilyInfoReadAllQueryVariables
  >(UserFamilyInfoReadAllDocument, options);
}
export type UserFamilyInfoReadAllQueryHookResult = ReturnType<
  typeof useUserFamilyInfoReadAllQuery
>;
export type UserFamilyInfoReadAllLazyQueryHookResult = ReturnType<
  typeof useUserFamilyInfoReadAllLazyQuery
>;
export type UserFamilyInfoReadAllQueryResult = Apollo.QueryResult<
  UserFamilyInfoReadAllQuery,
  UserFamilyInfoReadAllQueryVariables
>;
export const UserFamilyInfoReadAllByCompanyDocument = gql`
  query UserFamilyInfoReadAllByCompany($companyID: ID!) {
    UserFamilyInfoReadAllByCompany(companyID: $companyID) {
      userID
      companyID
      famInfID
      name
      relationship
      birthday
      ktpNum
      jobTitle
    }
  }
`;

/**
 * __useUserFamilyInfoReadAllByCompanyQuery__
 *
 * To run a query within a React component, call `useUserFamilyInfoReadAllByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFamilyInfoReadAllByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFamilyInfoReadAllByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUserFamilyInfoReadAllByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFamilyInfoReadAllByCompanyQuery,
    UserFamilyInfoReadAllByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserFamilyInfoReadAllByCompanyQuery,
    UserFamilyInfoReadAllByCompanyQueryVariables
  >(UserFamilyInfoReadAllByCompanyDocument, options);
}
export function useUserFamilyInfoReadAllByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFamilyInfoReadAllByCompanyQuery,
    UserFamilyInfoReadAllByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserFamilyInfoReadAllByCompanyQuery,
    UserFamilyInfoReadAllByCompanyQueryVariables
  >(UserFamilyInfoReadAllByCompanyDocument, options);
}
export type UserFamilyInfoReadAllByCompanyQueryHookResult = ReturnType<
  typeof useUserFamilyInfoReadAllByCompanyQuery
>;
export type UserFamilyInfoReadAllByCompanyLazyQueryHookResult = ReturnType<
  typeof useUserFamilyInfoReadAllByCompanyLazyQuery
>;
export type UserFamilyInfoReadAllByCompanyQueryResult = Apollo.QueryResult<
  UserFamilyInfoReadAllByCompanyQuery,
  UserFamilyInfoReadAllByCompanyQueryVariables
>;
export const UserProfileRetrieveDocument = gql`
  query UserProfileRetrieve($userID: ID!) {
    UserProfileRetrieve(userID: $userID) {
      username
      ktpUrl
      npwpnum
      ktpNum
      name
      lastName
      email
    }
  }
`;

/**
 * __useUserProfileRetrieveQuery__
 *
 * To run a query within a React component, call `useUserProfileRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserProfileRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >(UserProfileRetrieveDocument, options);
}
export function useUserProfileRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >(UserProfileRetrieveDocument, options);
}
export type UserProfileRetrieveQueryHookResult = ReturnType<
  typeof useUserProfileRetrieveQuery
>;
export type UserProfileRetrieveLazyQueryHookResult = ReturnType<
  typeof useUserProfileRetrieveLazyQuery
>;
export type UserProfileRetrieveQueryResult = Apollo.QueryResult<
  UserProfileRetrieveQuery,
  UserProfileRetrieveQueryVariables
>;
export const UserRetrieveByUsernameDocument = gql`
  query UserRetrieveByUsername($username: String!) {
    UserRetrieveByUsername(username: $username) {
      userID
      username
    }
  }
`;

/**
 * __useUserRetrieveByUsernameQuery__
 *
 * To run a query within a React component, call `useUserRetrieveByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRetrieveByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRetrieveByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserRetrieveByUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >(UserRetrieveByUsernameDocument, options);
}
export function useUserRetrieveByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >(UserRetrieveByUsernameDocument, options);
}
export type UserRetrieveByUsernameQueryHookResult = ReturnType<
  typeof useUserRetrieveByUsernameQuery
>;
export type UserRetrieveByUsernameLazyQueryHookResult = ReturnType<
  typeof useUserRetrieveByUsernameLazyQuery
>;
export type UserRetrieveByUsernameQueryResult = Apollo.QueryResult<
  UserRetrieveByUsernameQuery,
  UserRetrieveByUsernameQueryVariables
>;
