import { Outstation } from "../_interfaces/outstation.interfaces";
import request from "../_network/request";
const outsourcingRecordUrl = "outsourcing_record";
const outsourcingUrl = "outsourcing";

const recordOutsource = (data: Outstation) => {
  return request({
    url: outsourcingRecordUrl,
    method: "POST",
    data: data,
  });
};

const getOutsourceOptions = () => {
  return request({
    url: outsourcingUrl,
    method: "GET",
  });
};

const getApprovalOutsourcing = () => {
  return request({
    url: `${outsourcingRecordUrl}/by_user`,
    method: "GET",
  });
};

export const OutsourcingServices = {
  recordOutsource,
  getOutsourceOptions,
  getApprovalOutsourcing,
};
