const ApprovalActionTypes = {
  GET_LEAVE_APPROVAL: "GET_LEAVE_APPROVAL",
  GET_LEAVE_APPROVAL_SUCCESS: "GET_LEAVE_APPROVAL_SUCCESS",
  GET_LEAVE_APPROVAL_FAILURE: "GET_LEAVE_APPROVAL_FAILURE",
  GET_OUTSOURCE_APPROVAL: "GET_OUTSOURCE_APPROVAL",
  GET_OUTSOURCE_APPROVAL_SUCCESS: "GET_OUTSOURCE_APPROVAL_SUCCESS",
  GET_OUTSOURCE_APPROVAL_FAILURE: "GET_OUTSOURCE_APPROVAL_FAILURE",
};

export default ApprovalActionTypes;
