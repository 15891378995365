import React, { useEffect, useState } from "react";
import { setHeader } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import QrReader from "react-qr-reader";
import "./scan-qr.style.scss";
import useQRParser, { ActionType } from "../../_helper/QRParser";
import {
  sendAttendance,
  sendClockOutAttendance,
} from "../../redux/clock-in/clockin.actions";

const ScanQR = () => {
  const dispatch = useAppDispatch();
  const [showScanner, setShowScanner] = useState<boolean>(true);
  const [, , QRData, fromPicture] = useQRParser();
  const { location } = useAppSelector((state) => state.clockin.masterData);

  useEffect(() => {
    dispatch(setHeader({ title: "Scan QR", hasBack: true }));
  }, []);

  useEffect(() => {
    if (QRData) {
      const currentLocation = location.filter(
        (item: any) => item.id === QRData.currentSchedule.locationId,
      )[0];
      if (QRData.actionType === ActionType.ClockIn) {
        dispatch(
          sendAttendance(
            {
              attendance_id: "",
              lat: currentLocation?.lat,
              long: currentLocation?.long,
              type: "clock_in",
              time: "123",
              file: "",
              desc: "Clockin using QR",
            },
            QRData.userId,
            QRData.currentSchedule.dataShiftId,
          ),
        );
      } else {
        dispatch(
          sendClockOutAttendance(
            {
              attendance_id: "",
              lat: currentLocation?.lat,
              long: currentLocation?.long,
              type: "clock_out",
              time: "",
              file: "",
              desc: "",
            },
            QRData.currentSchedule.dataShiftId,
            QRData.userId,
          ),
        );
      }
    }
  }, [QRData]);

  const handleScan = (e: string | null) => {
    if (e) fromPicture(e);
  };

  const handleError = (err: any) => {
    console.log(err);
  };

  return (
    <div className="scan-qr">
      {showScanner ? (
        <QrReader
          delay={1000}
          onScan={handleScan}
          onError={handleError}
          showViewFinder={true}
          style={{ width: "100%" }}
        />
      ) : (
        <div className="employee-data">employeeData</div>
      )}
    </div>
  );
};

export default ScanQR;
