import React, { FunctionComponent, useEffect } from "react";
import "./App.css";
import { Login } from "./pages/Login";
import { Redirect, Route, Switch } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Spinner } from "@blueprintjs/core";
import { useFullScreenHandle } from "react-full-screen";
import { useLogin } from "./data/login/api";
import { useAllowanceRetrieveQuery } from "./backend/app";
// import { Maintenance } from "./pages/Maintenance";

const App: FunctionComponent = () => {
  const [, loginAuth] = useLogin();
  const isLoggedIn = loginAuth?.userID != "" && loginAuth?.userID != undefined;
  const fullScreenHandle = useFullScreenHandle();
  useEffect(() => {
    fullScreenHandle.enter();
  }, []);

  const { loading: allowanceLoading } = useAllowanceRetrieveQuery({
    variables: { userID: loginAuth.userID },
  });
  return allowanceLoading ? (
    <div className={"spinner-container"}>
      <Spinner />
    </div>
  ) : (
    <Switch>
      {!isLoggedIn ? (
        <>
          <Route path="/login">
            <Login baseRoute={"/login"} loggedInRoute={"/dashboard"} />
          </Route>
          <Route path={"/"}>
            <Redirect to={"/login"} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/">
            <Dashboard baseUrl={"/dashboard"} />
          </Route>
        </>
      )}
    </Switch>
  );
};

export default App;
