import ApprovalActionTypes from "./approval.types";

interface ApprovalState {
  leaveList: [];
  outsourceList: [];
}

const INITIAL_STATE: ApprovalState = {
  leaveList: [],
  outsourceList: [],
};

const ApprovalReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ApprovalActionTypes.GET_LEAVE_APPROVAL:
      return {
        ...state,
      };
    case ApprovalActionTypes.GET_LEAVE_APPROVAL_SUCCESS:
      return {
        ...state,
        leaveList: action.payload,
      };
    case ApprovalActionTypes.GET_LEAVE_APPROVAL_FAILURE:
      return {
        ...state,
        leaveList: [],
      };
    case ApprovalActionTypes.GET_OUTSOURCE_APPROVAL:
      return {
        ...state,
      };
    case ApprovalActionTypes.GET_OUTSOURCE_APPROVAL_SUCCESS:
      return {
        ...state,
        outsourceList: action.payload,
      };
    case ApprovalActionTypes.GET_OUTSOURCE_APPROVAL_FAILURE:
      return {
        ...state,
        outsourceList: [],
      };
    default:
      return state;
  }
};

export default ApprovalReducer;
