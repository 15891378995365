import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import box_package from "../../../../assets/box_package.svg";
import list_checked from "../../../../assets/list_checked.svg";
import wallet from "../../../../assets/wallet.svg";
import user_checked from "../../../../assets/user_checked.svg";
import {
  useCompanyEmployeeRetrieveQuery,
  CompanyEmployee,
} from "../../../../backend/app";

import { IconNames } from "@blueprintjs/icons";
import { Link } from "react-router-dom";
import { PageComponent } from "../../lib";
import "./styles.scss";
import { Icon, Dialog } from "@blueprintjs/core";
import { useLogin, useLogout } from "../../../../data/login/api";

export const HomePage: FunctionComponent = () => {
  const [, loginAuth] = useLogin();
  const { data: companyProfileData, error: companyProfileError } =
    useCompanyEmployeeRetrieveQuery({
      variables: {
        userID: loginAuth.userID,
        companyID: loginAuth.companyID,
      },
    });

  const profile =
    companyProfileData?.CompanyEmployeeRetrieve || ({} as CompanyEmployee);
  const name = profile.name || "";

  const [isOpen, setIsOpen] = useState(false);
  const [permission, setPermission] = useState(false);
  const [text, setText] = useState<string>("");
  useEffect(() => {
    setPermission(profile.wageRequestAllowed || false);
  }, [companyProfileData]);

  const toggleCloseDialog = (type: boolean, text: string) => {
    setIsOpen(type);
    setText(text);
  };

  return (
    <>
      <Dialog isOpen={isOpen} className="dialog-forbidden">
        <div className="forbidden-box">
          <h2>Oops!</h2>
          <Icon icon="warning-sign" />
          <p>{text}</p>
          <button onClick={() => toggleCloseDialog(false, "")}>Tutup</button>
        </div>
      </Dialog>
      <div className="jumbotron"></div>
      <div className="homepage-wrapper">
        <div className="user">
          <div className="user-pict">
            <img
              src="https://i.kym-cdn.com/photos/images/newsfeed/000/110/672/1301700505625.jpg"
              alt=""
            />
          </div>
          <div className="bell-icon">
            <Icon icon="notifications" />
          </div>
        </div>
        <div className="name">
          <h2>Hi, {name}</h2>
        </div>
        <div className="carding-wrap">
          <Link
            to={permission ? "/dashboard/4" : "#"}
            onClick={() =>
              toggleCloseDialog(
                !permission,
                "Anda tidak memiliki izin halaman ini, hubungi admin Anda untuk informasi lebih lanjut.",
              )
            }
          >
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-between">
                  <div className="desc">
                    <strong>
                      <h3>Gaji On-Demand</h3>
                    </strong>
                    <small className="text-secondary">
                      Tarik gaji Anda hari ini.
                    </small>
                  </div>
                  <div className="icon-card">
                    <img src={wallet} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div
            className="card"
            onClick={() =>
              toggleCloseDialog(
                true,
                "Kontak gajiku untuk daftar fitur Koperasi Digital.",
              )
            }
          >
            <div className="card-body">
              <div className="d-flex justify-between">
                <div className="desc">
                  <strong>
                    <h3>Koperasi Digital</h3>
                  </strong>
                  <small className="text-secondary">
                    Lacak dan catat KPI Anda.
                  </small>
                </div>
                <div className="icon-card">
                  <img src={list_checked} />
                </div>
              </div>
            </div>
          </div>
          <Link
            to={
              profile.attendanceAllowed || loginAuth.level === "COMPANY_MANAGER"
                ? "/attendance/"
                : "#"
            }
            onClick={() =>
              toggleCloseDialog(
                !profile.attendanceAllowed ||
                  loginAuth.level === "COMPANY_MANAGER",
                "Kontak gajiku untuk daftar fitur Attendance.",
              )
            }
          >
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-between">
                  <div className="desc">
                    <strong>
                      <h3>Attendance</h3>
                    </strong>
                    <small className="text-secondary">
                      Absen masuk di jam Anda.
                    </small>
                  </div>
                  <div className="icon-card">
                    <img src={user_checked} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div
            className="card"
            onClick={() =>
              toggleCloseDialog(true, "Kontak gajiku untuk daftar fitur KPI.")
            }
          >
            <div className="card-body">
              <div className="d-flex justify-between">
                <div className="desc">
                  <strong>
                    <h3>KPI</h3>
                  </strong>
                  <small className="text-secondary">
                    Ajukan pinjaman perusahaan hari ini.
                  </small>
                </div>
                <div className="icon-card">
                  <img src={box_package} />
                </div>
              </div>
            </div>
          </div>
          <Link to={"/payslip/"}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-between">
                  <div className="desc">
                    <strong>
                      <h3>Payslips</h3>
                    </strong>
                    <small className="text-secondary">
                      Riwayat slip gaji anda.
                    </small>
                  </div>
                  <div className="icon-card">
                    <img src={wallet} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
