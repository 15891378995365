import AuthActionTypes from "./auth.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";

export const loginRedux = (data: any) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const logoutRedux = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};
