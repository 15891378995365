import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./index.css";
import { PageComponent } from "../../lib";
import {
  AnchorButton,
  Button,
  Dialog,
  Icon,
  Intent,
  Slider,
  Spinner,
} from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { useToaster } from "../../../../utils/toaster";
import { Carousel } from "react-responsive-carousel";
import { generateEnterKeyWatcher } from "../../../../utils/keys";
import { rupiahFormatter } from "../../../../utils/formatters";
import {
  AllowanceRetrieveDocument,
  TransactionEventRetrieveByUserIdDocument,
  useAllowanceRequestCreateMutation,
  useAllowanceRequestFeeLazyQuery,
  useAllowanceRetrieveLazyQuery,
  useCompanyEmployeeRetrieveQuery,
  useCompanyInfoRetrieveQuery,
} from "../../../../backend/app";
import { useLogin } from "../../../../data/login/api";
import { generateApiUrl } from "../../../../api/hook";
import Banner from "./banner.png";
import successIcon from "../../../../assets/list_checked.svg";

interface IRequestDialog {
  isOpen: boolean;
  onClose: () => void;
  setDescription: (description: string) => void;
  description: string;
  amount: number;
  onSubmit: () => void;
  disableConfirmButton: boolean;
}

const RequestDialog: FunctionComponent<IRequestDialog> = ({
  isOpen,
  onClose,
  setDescription,
  description,
  amount,
  onSubmit,
  disableConfirmButton,
}) => {
  const [, loginAuth] = useLogin();
  const userID = loginAuth.userID;
  const [reason, setReason] = useState<string>("");
  const [
    queryAllowanceRequestFee,
    { data: requestFee, loading: requestFeeLoading },
  ] = useAllowanceRequestFeeLazyQuery({ fetchPolicy: "no-cache" });
  let isDisabled = description.length < 5;
  if (disableConfirmButton === true) {
    isDisabled = true;
  }
  const onKeyPress = generateEnterKeyWatcher(onSubmit);
  const onSelectRadio = (e: any) => setReason(e.target.value);
  useEffect(() => {
    if (reason !== "Lainnya") setDescription(reason);
  }, [reason]);
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onOpening={() =>
        queryAllowanceRequestFee({ variables: { userID, amount } })
      }
      className="dialog-request"
    >
      <div className={"request-page-confirmation-container title"}>
        <div className="arrow-back">
          <Button onClick={onClose}>
            <Icon className="icon" icon="arrow-left" />
          </Button>
        </div>
        <div className={"confirmation-title"}>Tarik Gaji</div>
      </div>
      <div className={"request-page-confirmation-container body"}>
        <div className="step-wizard">
          <div className="step-wrap">
            <div className="step-first">
              <Icon className="icon" icon="tick" />
              <div className="step-title">
                <small>Pilih Tujuan</small>
              </div>
            </div>
            <div className="step-distance"></div>
            <div className="step-other">
              2
              <div className="step-title">
                <small>Konfirmasi</small>
              </div>
            </div>
          </div>
        </div>

        {!requestFeeLoading ? (
          <div className="confirmation-amount-group">
            <div className="justify-between">
              <div className="confirmation-principal">Nominal diajukan</div>
              <div className={"confirmation-amount"}>
                Rp. {rupiahFormatter(amount)}
              </div>
            </div>
            {!requestFeeLoading ? (
              <>
                <div className="justify-between">
                  <div className={"confirmation-fee"}>Biaya</div>
                  <div className={"confirmation-fee"}>
                    - Rp. {rupiahFormatter(requestFee?.AllowanceRequestFee)}
                  </div>
                </div>
                <hr className="dialog-hr" />
                <div className="justify-between">
                  <div className={"confirmation-principal subtitle"}>
                    Untukmu
                  </div>
                  <div className={"confirmation-principal"}>
                    Rp.{" "}
                    {rupiahFormatter(
                      amount - (requestFee?.AllowanceRequestFee || 0),
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <Spinner />
        )}
        <div className="reason-wrap">
          <input
            type="radio"
            name="reason"
            value="Kebutuhan sekolah anak"
            id="radio1"
            onChange={onSelectRadio}
          />
          <label className="reason-box" htmlFor="radio1">
            Kebutuhan sekolah anak
          </label>

          <input
            type="radio"
            name="reason"
            value="Kebutuhan rumah tangga"
            id="radio2"
            onChange={onSelectRadio}
          />
          <label className="reason-box" htmlFor="radio2">
            Kebutuhan rumah tangga
          </label>

          <input
            type="radio"
            name="reason"
            value="Kebutuhan kesehatan"
            id="radio3"
            onChange={onSelectRadio}
          />
          <label className="reason-box" htmlFor="radio3">
            Kebutuhan kesehatan
          </label>

          <input
            type="radio"
            name="reason"
            value="Kebutuhan traveling"
            id="radio4"
            onChange={onSelectRadio}
          />
          <label className="reason-box" htmlFor="radio4">
            Kebutuhan traveling
          </label>

          <input
            type="radio"
            name="reason"
            value="Lainnya"
            id="radio5"
            onChange={onSelectRadio}
          />
          <label className="reason-box" htmlFor="radio5">
            Lainnya
          </label>
        </div>
      </div>
      {reason === "Lainnya" && (
        <div className={"request-page-confirmation-container message-input"}>
          <textarea
            onKeyPress={onKeyPress}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Tidak ada satupun di atas? Silahkan tuliskan tujuan anda disini"
          ></textarea>
        </div>
      )}
      <div className={"request-page-confirmation-container footer"}>
        <Button
          className={
            isDisabled ? "confirm-btn disabled" : "confirm-btn confirm"
          }
          text="Lanjutkan"
          disabled={isDisabled}
          onClick={onSubmit}
        />
      </div>
    </Dialog>
  );
};

export const NoticeHeader: FunctionComponent<
  PropsWithChildren<{ color?: string; visibility?: VisibilityState }>
> = ({ children, color, visibility }) => {
  const chosenColor = color || "#fff0cbs";
  return (
    <div
      style={{
        margin: "0 5%",
        textAlign: "center",
        visibility: visibility,
      }}
    >
      <button
        style={{
          backgroundColor: chosenColor,
          border: "none",
          width: "90%",
          padding: "2%",
          borderRadius: "0.5rem",
          fontFamily: "Poppins",
          color: "var(--theme-gray)",
          fontSize: "1rem",
        }}
      >
        {children}
      </button>
    </div>
  );
};

export const DisconnectedWarning: FunctionComponent = () => {
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
  const visibility: VisibilityState = isDisconnected ? "visible" : "hidden";
  useEffect(() => {
    const recGoogleCheck = async () => {
      try {
        await fetch(generateApiUrl("heartbeat"), {
          mode: "cors",
          method: "POST",
        });
        setIsDisconnected(false);
      } catch (e: any) {
        setIsDisconnected(true);
      }
      setTimeout(recGoogleCheck, 60000);
    };
    recGoogleCheck();
  }, []);
  return (
    <NoticeHeader color={"#F5498B"} visibility={visibility}>
      Disconnected <Icon className="icon" icon="warning-sign" />
    </NoticeHeader>
  );
};

const AlowanceSlider: FunctionComponent<{
  amount: number;
  minimumRequestAmount: number;
  retrievedAvailableAllowance: number | undefined;
  setAmount: (value: ((prevState: number) => number) | number) => void;
}> = ({
  amount,
  minimumRequestAmount,
  retrievedAvailableAllowance,
  setAmount,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <div className="label">Jumlah penarikan yang bisa diterima (Rp)</div>
      <div className="input-field-amount">
        {isEditing ? (
          <input
            type="number"
            name="real"
            min={0}
            max={retrievedAvailableAllowance || 0}
            value={amount}
            onChange={(e) => {
              if (e.target.value === "") {
                return setAmount(0);
              } else {
                if (
                  parseInt(e.target.value) >= (retrievedAvailableAllowance || 0)
                ) {
                  return setAmount(retrievedAvailableAllowance || 0);
                } else return setAmount(parseInt(e.target.value));
              }
            }}
            onBlur={() => setIsEditing(false)}
            placeholder="RP."
          />
        ) : (
          <input
            type="text"
            name="temp"
            value={`Rp. ${rupiahFormatter(amount)}`}
            onFocus={() => setIsEditing(true)}
            placeholder="RP."
            readOnly
          />
        )}
      </div>
      <input
        className="slider"
        type="range"
        min={minimumRequestAmount}
        max={retrievedAvailableAllowance}
        step={10000}
        value={amount}
        onChange={(e) => {
          setAmount(parseInt(e.target.value));
        }}
      />
    </>
  );
};

const WarningLabel: FunctionComponent<{ message: string }> = ({ message }) => {
  if (message !== "") {
    return <div className="label">{message}</div>;
  }
  return null;
};

export const RequestPage: PageComponent = ({ onTopIconClickRef }) => {
  const [, loginAuth] = useLogin();
  const userID = loginAuth.userID;
  const [successOpen, setSuccessOpen] = useState(false);

  const successClose = (type: any) => {
    setSuccessOpen(type);
  };
  const { data: companyData } = useCompanyInfoRetrieveQuery({
    variables: { companyID: loginAuth.companyID },
    fetchPolicy: "cache-and-network",
  });
  const { data: employeeData } = useCompanyEmployeeRetrieveQuery({
    variables: {
      userID: loginAuth.userID,
      companyID: loginAuth.companyID,
    },
    fetchPolicy: "cache-and-network",
  });

  const minimumRequestAmount =
    companyData?.CompanyInfoRetrieve.minimumRequestAmount || 0;

  const [amount, setAmount] = useState<number>(minimumRequestAmount);
  const [description, setDescription] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [disButton, setDisButton] = useState<boolean>(false);
  const toaster = useToaster();

  const isWageRequestAllowed =
    companyData?.CompanyInfoRetrieve.wageRequestEnabled ||
    employeeData?.CompanyEmployeeRetrieve.wageRequestAllowed;

  const [retrieveAllowance, { data: allowanceAvailable }] =
    useAllowanceRetrieveLazyQuery({
      variables: { userID: loginAuth.userID },
      fetchPolicy: "cache-and-network",
    });

  const retrievedAvailableAllowance = allowanceAvailable?.AllowanceRetrieve;
  const hasEnoughAllowance =
    retrievedAvailableAllowance !== undefined &&
    retrievedAvailableAllowance >= amount;

  useEffect(() => {
    try {
      retrieveAllowance();
    } catch (e: any) {
      toaster?.current?.show({
        message: "can't fetch allowance",
        intent: Intent.WARNING,
      });
    }
  }, [retrieveAllowance, disButton]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTopIconClickRef.current = () => {
    retrieveAllowance();
  };

  const createAdvanceRequest = useCallback(async () => {
    setDisButton(false);
    setShowDialog(true);
  }, [setShowDialog]);

  const [submitRequest] = useAllowanceRequestCreateMutation();

  const submitAdvanceRequest = useCallback(async () => {
    try {
      setDisButton(true);
      await submitRequest({
        variables: { amount, userID, description },
        refetchQueries: [
          {
            query: AllowanceRetrieveDocument,
            variables: { userID: loginAuth.userID },
          },
          {
            query: TransactionEventRetrieveByUserIdDocument,
            variables: { userID: loginAuth.userID },
          },
        ],
      });
      setSuccessOpen(true);
      setDisButton(false);
      setDescription("");
      setShowDialog(false);
      toaster?.current?.show({
        message: "Successful Request",
        intent: Intent.SUCCESS,
      });
    } catch (e: any) {
      toaster?.current?.show({ message: e.message, intent: Intent.DANGER });
    }
  }, [
    setDescription,
    toaster,
    setShowDialog,
    description,
    loginAuth.userID,
    amount,
  ]);

  let warningMessage = "";
  if (!hasEnoughAllowance) {
    warningMessage = "tidak ada gaji yang bisa ditarik";
  }

  if (!isWageRequestAllowed) {
    warningMessage = "anda tidak memiliki akses untuk tarik gaji";
  }
  const imageClick = () => {
    const newWindow = window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSc8uPLx26Nw1n7uWtXIryWAAwNKaglADvsloBW3XXJKSRL-kw/viewform",
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="request-page-container">
      <RequestDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        amount={amount}
        description={description || ""}
        setDescription={setDescription}
        onSubmit={submitAdvanceRequest}
        disableConfirmButton={disButton}
      />
      <Dialog isOpen={successOpen} className="dialog-request">
        <div className={"request-page-confirmation-container title"}>
          <div className="arrow-back">
            <Link to={"/dashboard/0"} onClick={() => successClose(false)}>
              <Icon className="icon" icon="arrow-left" />
            </Link>
          </div>
          <div className={"confirmation-title"}>Tarik Gaji</div>
        </div>
        <div className="success-body">
          <div className="success-content">
            <h3>Berhasil</h3>
            <div className="success-img">
              <img src={successIcon} alt="icon" />
            </div>
            <p>Selamat!! Pengajuan penarikan gaji telah berhasil dilakukan</p>
            <small className="text-secondary">Harap tunggu beberapa saat</small>
            <div className="footer-content">
              <small className="text-secondary">
                Lihat status penarikan di riwayat
              </small>
              <Link to={"/dashboard/0"} onClick={() => successClose(false)}>
                <button>Kembali ke Beranda</button>
              </Link>
            </div>
          </div>
        </div>
      </Dialog>
      <DisconnectedWarning />
      <div className="request-card">
        <div className="container request-page-total-allowance">
          <div className="label">Jumlah penarikan gaji dimuka (Rp)</div>
          <div className="amount">
            <div className="value">
              Rp. {rupiahFormatter(retrievedAvailableAllowance)}
            </div>
          </div>
        </div>
        <div className="container request-page-withdrawal-amount">
          <AlowanceSlider
            amount={amount}
            minimumRequestAmount={minimumRequestAmount}
            retrievedAvailableAllowance={retrievedAvailableAllowance}
            setAmount={setAmount}
          />
          <WarningLabel message={warningMessage} />
        </div>
        <div className="container submit-button">
          <Button
            text="Tarik Gaji"
            disabled={!isWageRequestAllowed || !hasEnoughAllowance}
            onClick={createAdvanceRequest}
          />
        </div>
      </div>
    </div>
  );
};
