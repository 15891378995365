import axios, { AxiosResponse } from "axios";
import CryptoJS from "crypto-js";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  maxRedirects: 5,
});
const request = (options: any) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = function (error: Error) {
    return Promise.reject(error.message);
  };

  const str = sessionStorage.getItem("login") || "";
  const raw = str !== "" ? JSON.parse(str) : {};
  const token = JSON.stringify(raw["/login"]) ?? "";
  const encrypted: string = CryptoJS.AES.encrypt(
    token,
    process.env.REACT_APP_AES || "",
  ).toString();
  return client({
    ...options,
    headers: {
      "x-access-token": encrypted,
    },
  })
    .then(onSuccess)
    .catch(onError);
};

export default request;
