import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import { useRegistrationSubmitPasswordMutation } from "../../../../backend/app";
import { useLogin } from "../../../../data/login/api";
import Logo from "../../../../assets/logo.svg";

export const SetSecurityCode: FunctionComponent<RegistrationComponentProps> = ({
  setNextIsDisabled,
  registrationToken,
  onNextClickRef,
}) => {
  const [initialSecurityCode, setInitialSecurityCode] = useState<string>("");
  const [securityCode, setSecurityCode] = useState<string>("");

  const [login] = useLogin();

  useEffect(() => {
    setNextIsDisabled(
      securityCode === "" ||
        securityCode.length < 4 ||
        securityCode !== initialSecurityCode,
    );
  }, [securityCode, setNextIsDisabled]);

  const [registerSecurityCode] = useRegistrationSubmitPasswordMutation();

  onNextClickRef.current = useCallback(async () => {
    const loginAuthBackendResponse = await registerSecurityCode({
      variables: {
        password: securityCode,
        token: registrationToken,
      },
      fetchPolicy: "no-cache",
    });
    await login({
      username:
        loginAuthBackendResponse.data!.RegistrationSubmitPassword.username,
      password: securityCode,
    });
  }, [registerSecurityCode, registrationToken, securityCode]);

  return (
    <div className="hp">
      <div className="container logo">
        <img src={Logo} alt="gajiku-logo" />
        <div className="title">Gajiku</div>
      </div>
      <div className="header">
        <div>Harap Masukan Kata Sandi</div>
      </div>
      <div className="container password">
        <div className="password-field">
          <input
            type="password"
            value={initialSecurityCode}
            onChange={(e) => setInitialSecurityCode(e.target.value)}
            placeholder="Masukan Kata Sandi..."
          />
          <input
            type="password"
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
            placeholder="Masukan Kata Sandi Lagi..."
          />
        </div>
      </div>
    </div>
  );
};
