import { batch } from "react-redux";
import { ShiftInterface } from "../../_interfaces";
import { ShiftRequestService } from "../../_services/shiftrequest.service";
import GlobalActionTypes from "../global/global.types";
import ShiftRequestTypes from "./shiftrequest.types";

export const getShiftRequest = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const token = state.auth.userData.token;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ShiftRequestTypes.GET_ALL_SHIFT });
      });

      const options = await ShiftRequestService.getShiftRequest();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftRequestTypes.GET_ALL_SHIFT_SUCCESS,
          payload: options.data.rows,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftRequestTypes.GET_ALL_SHIFT_FAILURE,
        });
      });
    }
  };
};

export const createShiftRequest = ({
  payload,
}: {
  payload: ShiftInterface;
}) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    const { approval } = state.clockin.masterData;
    payload.approvalId = approval.filter(
      (item: any) => item.type === "Pending",
    )[0].id;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ShiftRequestTypes.SUBMIT_SHIFT_REQUEST });
      });

      const record = await ShiftRequestService.postShiftRequest(payload);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftRequestTypes.SUBMIT_SHIFT_REQUEST_SUCCESS,
          payload: record,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_SUCCESS,
          payload: {
            title: "Pengajuan Shift berhasil",
            desc: "Pengajuan Shift telah berhasil di lakukan",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftRequestTypes.SUBMIT_SHIFT_REQUEST_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};
