import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import { FileInput, Position } from "@blueprintjs/core";
import "./index.css";
import { DateInput } from "@blueprintjs/datetime";
import { useRegistrationSubmitInfoMutation } from "../../../../backend/app";
import Logo from "../../../../assets/logo.svg";

const dateFormatter = (d: Date) => {
  const year = d.getFullYear();
  const formattedMonth = `${d.getMonth() + 1 < 10 ? "0" : ""}${
    d.getMonth() + 1
  }`;
  const formattedDay = `${d.getDate() < 10 ? "0" : ""}${d.getDate()}`;

  return `${year}-${formattedMonth}-${formattedDay}`;
};
const dateParser = (d: string) => new Date(d);

export const UploadKTP: FunctionComponent<RegistrationComponentProps> = ({
  setNextIsDisabled,
  registrationToken,
  setRegistrationToken,
  onNextClickRef,
  invitationName,
}) => {
  const [name, setName] = useState<string>(invitationName || "");
  const [birthday, setBirthday] = useState<Date | undefined>(undefined);
  const [ktpFile, setKtpFile] = useState<File | undefined>();

  const formattedBirthday = birthday ? dateFormatter(birthday) : "";

  const fileMessage =
    ktpFile === undefined ? "Mengunggah foto" : "Foto sudah diunggah";

  const [submitInfo] = useRegistrationSubmitInfoMutation();

  useEffect(() => {
    setNextIsDisabled(name === "" || !ktpFile || birthday === undefined);
  }, [name, ktpFile, birthday, setNextIsDisabled]);

  onNextClickRef.current = useCallback(async () => {
    // Must use a direct fetch call for this once,
    // since it requires us to also attach a file upload.
    if (!ktpFile) {
      throw new Error("missing ktp file");
    }
    if (!formattedBirthday) {
      throw new Error("missing birthday");
    }
    const resp = await submitInfo({
      variables: {
        token: registrationToken,
        ktp: ktpFile,
        birthday: birthday!,
      },
      fetchPolicy: "no-cache",
    });
    setRegistrationToken(resp.data?.RegistrationSubmitInfo);
    return resp;
  }, [
    ktpFile,
    registrationToken,
    setRegistrationToken,
    name,
    formattedBirthday,
  ]);

  return (
    <div className="ktp">
      <div className="container logo">
        <img src={Logo} alt="gajiku-logo" />
        <div className="title">Gajiku</div>
      </div>
      <div className="header">
        <small>Harap masukan informasi KTP.</small>
      </div>
      <div className="body upload-ktp">
        Tolong ungguh foto KTP Anda.
        <FileInput
          text={fileMessage}
          onInputChange={(e: any) => {
            setKtpFile(e.target.files[0]);
          }}
        />
      </div>
      <div className="body full-name">
        Nama Lengkap
        {!invitationName ? (
          <input
            placeholder="Masukan Nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <div className={"fixed-data"}>{name}</div>
        )}
      </div>

      <div className="body full-name">
        Birthday
        <DateInput
          defaultValue={new Date("1999-01-01")}
          onChange={setBirthday}
          popoverProps={{ position: Position.BOTTOM }}
          parseDate={dateParser}
          formatDate={dateFormatter}
          minDate={new Date("1930-01-01")}
        />
      </div>
      {/*<div className="body ref-code">*/}
      {/*  Kode Refensi (Opsional)*/}
      {/*  <input placeholder="Masukan Kode" />*/}
      {/*</div>*/}
    </div>
  );
};
