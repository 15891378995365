import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from "react";

export interface IStoreState {
  [_: string]: any;
}

export interface IAction {
  type: any;
  [_: string]: any;
}

// Simplify type of returned context
export type GContext<T extends IStoreState> = [T, (value: IAction) => void];

// List of store providers to generate
const PROVIDERS = [] as FunctionComponent[];

// Add a provider to the list of providers
// Generates a new provider and returns a context function
// that can be used in each of the components
export const registerProvider: <T extends IStoreState>(
  reducer: Reducer<T, IAction>,
  initialState: T,
) => () => GContext<T> = (reducer, initialState) => {
  const NewStoreContext = createContext<GContext<typeof initialState>>([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  ]);
  const NewProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined);
    return (
      <NewStoreContext.Provider value={[state, dispatch]}>
        {children}
      </NewStoreContext.Provider>
    );
  };
  PROVIDERS.push(NewProvider);
  return () => useContext(NewStoreContext);
};

// Combines all the providers together using a single
// empty TopLevel
export const CombinedStoreProvider: FunctionComponent = ({ children }) => {
  const TopLevel: FunctionComponent = ({
    children,
  }: PropsWithChildren<any>) => <>{children}</>;

  const combinedComponent: FunctionComponent<PropsWithChildren<any>> =
    PROVIDERS.reduce(
      (all, provider) => {
        return ({ children }) => all({ children: provider({ children }) });
      },
      ({ children }) => TopLevel({ children }),
    );
  return combinedComponent({ children });
};

const generateSaveState =
  (namespace: string) => (state: { [_: string]: any }) => {
    sessionStorage.setItem(namespace, JSON.stringify(state));
    return state;
  };

const generateLoadState: (namespace: string) => () => any =
  (namespace: string) => () => {
    const foundRawState = sessionStorage.getItem(namespace);
    if (foundRawState) {
      return JSON.parse(foundRawState);
    } else {
      return {};
    }
  };

// Generates state managers to make it easy to save and load namespaced session data
export const generateStateManagers: (
  namespace: string,
) => [(state: IStoreState) => IStoreState, () => IStoreState] = (
  namespace: string,
) => {
  return [generateSaveState(namespace), generateLoadState(namespace)];
};

// This will combine all the registered providers to allow multiple contexts to have their data provider for
// Any stores that wish to provide data to the app must be registered using the `registerProvider` method
// and they will automatically be providing data to the system
