import { useState } from "react";
import CryptoJS from "crypto-js";
import { useAppDispatch } from "../redux/hooks";
import { showAlert } from "../redux/global/global.actions";

export enum ActionType {
  ClockIn = 0,
  ClockOut = 1,
}

interface CurrentSchedule {
  id: string;
  companyId: string;
  locationId: string;
  divisionId: string;
  startDate: Date;
  endDate: Date;
  dataShiftId: string;
  assignTo: string[];
  restrictLocation: boolean;
  backupGPS: boolean;
  createdAt: Date;
}

export interface QRData {
  userId: string;
  currentSchedule: CurrentSchedule;
  date: string;
  actionType: ActionType;
}

const useQRParser = () => {
  const dispatch = useAppDispatch();
  const [formattedText, setFormattedText] = useState<string>("");
  const [data, setData] = useState<QRData>();

  const enc = (raw: string): string =>
    CryptoJS.AES.encrypt(raw, process.env.REACT_APP_AES || "").toString();

  const dec = (encrypted: string): string =>
    CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_AES || "").toString(
      CryptoJS.enc.Utf8,
    );

  const fromText = (qrData: QRData) => {
    const encrypted = enc(JSON.stringify(qrData));
    setFormattedText(encrypted);
  };

  const fromPicture = (text: string) => {
    try {
      const currentDate = new Date().toLocaleDateString();
      const decrypted = dec(text);
      const extractText: QRData = JSON.parse(decrypted);
      if (currentDate !== extractText.date) throw "error";
      setData(extractText);
    } catch (error) {
      dispatch(showAlert("QR code is not valid!", "error"));
    }
  };

  return [formattedText, fromText, data, fromPicture] as const;
};

export default useQRParser;
