export const AllBankCodes: () => BankCode[] = () => {
  const bankCodes: BankCode[] = [];
  for (const b in BankCode) {
    if (isNaN(Number(b))) {
      bankCodes.push(b.toUpperCase() as BankCode);
    }
  }
  return bankCodes;
};

enum BankCode {
  Mandiri = "MANDIRI",
  Bri = "BRI",
  Bni = "BNI",
  Bca = "BCA",
  Bsm = "BSM",
  Cimb = "CIMB",
  Muamalat = "MUAMALAT",
  Danamon = "DANAMON",
  Permata = "PERMATA",
  Bii = "BII",
  Panin = "PANIN",
  Uob = "UOB",
  Ocbc = "OCBC",
  Citibank = "CITIBANK",
  Artha = "ARTHA",
  Tokyo = "TOKYO",
  Dbs = "DBS",
  StandardChartered = "STANDARD_CHARTERED",
  Capital = "CAPITAL",
  Anz = "ANZ",
  Boc = "BOC",
  BumiArta = "BUMI_ARTA",
  Hsbc = "HSBC",
  Rabobank = "RABOBANK",
  Mayapada = "MAYAPADA",
  Bjb = "BJB",
  Dki = "DKI",
  DaerahIstimewa = "DAERAH_ISTIMEWA",
  JawaTengah = "JAWA_TENGAH",
  JawaTimur = "JAWA_TIMUR",
  Jambi = "JAMBI",
  Sumut = "SUMUT",
  SumateraBarat = "SUMATERA_BARAT",
  RiauDanKepri = "RIAU_DAN_KEPRI",
  SumselDanBabel = "SUMSEL_DAN_BABEL",
  Lampung = "LAMPUNG",
  KalimantanSelatan = "KALIMANTAN_SELATAN",
  KalimantanBarat = "KALIMANTAN_BARAT",
  KalimantanTimur = "KALIMANTAN_TIMUR",
  KalimantanTengah = "KALIMANTAN_TENGAH",
  Sulselbar = "SULSELBAR",
  Sulut = "SULUT",
  NusaTenggaraBarat = "NUSA_TENGGARA_BARAT",
  Bali = "BALI",
  NusaTenggaraTimur = "NUSA_TENGGARA_TIMUR",
  Maluku = "MALUKU",
  Papua = "PAPUA",
  Bengkulu = "BENGKULU",
  Sulawesi = "SULAWESI",
  SulawesiTenggara = "SULAWESI_TENGGARA",
  NusantaraParahyangan = "NUSANTARA_PARAHYANGAN",
  India = "INDIA",
  MestikaDharma = "MESTIKA_DHARMA",
  Sinarmas = "SINARMAS",
  Maspion = "MASPION",
  Ganesha = "GANESHA",
  Icbc = "ICBC",
  QnbKesawan = "QNB_KESAWAN",
  Btn = "BTN",
  Woori = "WOORI",
  TabunganPensiunanNasional = "TABUNGAN_PENSIUNAN_NASIONAL",
  BriSyr = "BRI_SYR",
  BjbSyr = "BJB_SYR",
  Mega = "MEGA",
  Bukopin = "BUKOPIN",
  JasaJakarta = "JASA_JAKARTA",
  Hana = "HANA",
  MncInternasional = "MNC_INTERNASIONAL",
  Agroniaga = "AGRONIAGA",
  SbiIndonesia = "SBI_INDONESIA",
  Royal = "ROYAL",
  Nationalnobu = "NATIONALNOBU",
  MegaSyr = "MEGA_SYR",
  InaPerdana = "INA_PERDANA",
  SahabatSampoerna = "SAHABAT_SAMPOERNA",
  KesejahteraanEkonomi = "KESEJAHTERAAN_EKONOMI",
  BcaSyr = "BCA_SYR",
  Artos = "ARTOS",
  Mayora = "MAYORA",
  IndexSelindo = "INDEX_SELINDO",
  VictoriaInternasional = "VICTORIA_INTERNASIONAL",
  Agris = "AGRIS",
  Chinatrust = "CHINATRUST",
  Commonwealth = "COMMONWEALTH",
  VictoriaSyr = "VICTORIA_SYR",
  Banten = "BANTEN",
  Doku = "DOKU",
  Mutiara = "MUTIARA",
  PaninSyr = "PANIN_SYR",
}

export default BankCode;
