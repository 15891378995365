import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  AnchorButton,
  Button,
  Dialog,
  Icon,
  Intent,
  Slider,
  Spinner,
} from "@blueprintjs/core";

import { PageComponent } from "../../lib";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  Payslip,
  useCompanyEmployeeRetrieveQuery,
  usePayrollEmployeePayslipsReadAllQuery,
} from "../../../../backend/app";
import { useLogin } from "../../../../data/login/api";
import { rupiahFormatter } from "../../../../utils/formatters";

export const PayslipDetail: PageComponent = () => {
  const [, loginAuth] = useLogin();
  const { data: companyProfileData, error: companyProfileError } =
    useCompanyEmployeeRetrieveQuery({
      variables: {
        userID: loginAuth.userID,
        companyID: loginAuth.companyID,
      },
    });
  const { data: slipList, loading: isLoading } =
    usePayrollEmployeePayslipsReadAllQuery({
      variables: {
        companyID: companyProfileData?.CompanyEmployeeRetrieve.companyID || "",
        memberID: companyProfileData?.CompanyEmployeeRetrieve.memberID || "",
      },
      fetchPolicy: "no-cache",
    });

  const [detailData, setDetailData] = useState<Payslip | undefined>();

  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    const temp: Payslip | undefined =
      slipList?.PayrollEmployeePayslipsReadAll?.filter(
        (item: any) => item.periodID == params.id,
      )[0];
    setDetailData(temp);
    console.log("data", temp);
  }, [slipList]);
  return (
    <>
      <div className={"request-page-confirmation-container title"}>
        <div className="arrow-back">
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon className="icon" icon="arrow-left" />
          </Button>
        </div>
        <div className={"confirmation-title"}>Payslips Detail</div>
      </div>
      <div className="payslip-detail-page container">
        <div className="card">
          <div className="card-body">
            <small className="badge badge-success">Paid</small>
            <div className="flex title-slip mt-4">
              <strong>
                {detailData?.createdAt &&
                  new Date(detailData?.createdAt).toLocaleDateString("id-ID", {
                    day: "numeric",
                    year: "numeric",
                    month: "long",
                  })}
              </strong>
              <small className="badge badge-success">Salary slip</small>
            </div>
            <small className="text-secondary">Salary</small>
            <div className="flex mt-4">
              <strong>
                {detailData?.createdAt &&
                  new Date(detailData?.createdAt).toLocaleDateString("id-ID", {
                    month: "long",
                  })}
              </strong>
              <strong className="ml-auto">
                Rp. {rupiahFormatter(detailData?.amount)}
              </strong>
            </div>
            <div className="mt-4">
              <small className="text-secondary">Detail Salary</small>
              <div className="mt-2">
                <small className="text-secondary">Addition</small>
                {detailData?.additions?.map((item: any, idx: number) => (
                  <div className="flex mt-2" key={idx}>
                    <small className="text-secondary">{item.reason}</small>
                    <small className="text-secondary ml-auto line">
                      -----------
                    </small>
                    <strong className="ml-auto">
                      {rupiahFormatter(item.amount)}
                    </strong>
                  </div>
                ))}
                <small className="text-secondary mt-2">Deduction</small>
                {detailData?.deductions?.map((item: any, idx: number) => (
                  <div className="flex mt-2" key={idx}>
                    <small className="text-secondary">{item.reason}</small>
                    <small className="text-secondary ml-auto line">
                      -----------
                    </small>
                    <strong className="ml-auto">
                      Rp. {rupiahFormatter(item.amount)}
                    </strong>
                  </div>
                ))}
              </div>
              <div className="mt-4 flex">
                <strong>Total Salary</strong>
                <strong className="ml-auto">
                  Rp. {rupiahFormatter(detailData?.amount ?? 0)}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <button className="btn w-100 btn-success">Download</button>
      </div>
    </>
  );
};
