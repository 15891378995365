import React, { FunctionComponent, PropsWithChildren } from "react";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const host: string =
  process.env.REACT_APP_BACKEND_HOST || "http://localhost:8080";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: `${host}/v5/graphql`,
    credentials: "include",
  }),
});

export const GraphQLProvider: FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
