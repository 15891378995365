/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent, useEffect, useState } from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import Logo from "../../../../assets/logo.svg";

export const AgreeTnc: FunctionComponent<RegistrationComponentProps> = ({
  onNextClickRef,
  setNextIsDisabled,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isTncBahasa, setIsTncBahasa] = useState<boolean>(true);

  onNextClickRef.current = async () => {
    return { data: "ok" };
  };

  useEffect(() => {
    setNextIsDisabled(!isChecked);
  }, [isChecked, setNextIsDisabled]);

  const TncBahasa = () => {
    return (
      <div className="language">
        Selamat datang di Gajiku! <br />
        Syarat dan Ketentuan yang ditetapkan di bawah ini mengatur pemakaian
        jasa yang ditawarkan oleh PT Vara Technologies Inc terkait penggunaan
        situs www.gajikuapp.com dan aplikasi Gajiku. Pengguna disarankan membaca
        dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna
        di bawah hukum yang berlaku. Dengan mengunduh, memasang, menggunakan
        dan/atau menikmati layanan kami pada situs dan/atau Platform Gajiku,
        Pengguna telah membaca, memahami, mengetahui, menerima, dan menyetujui
        seluruh syarat-syarat, ketentuan-ketentuan dan seluruh informasi yang
        terdapat dalam situs dan Platform Gajiku ini. Syarat dan Ketentuan ini
        merupakan suatu perjanjian yang secara sah mengikat yang memuat mengenai
        tata cara dan persyaratan situs dan Platform Gajiku antara anda sebagai
        Pengguna dengan pengelola Platform Gajiku yaitu PT Vara Technologies
        Inc. Ketentuan penggunaan dan kebijakan privasi dapat diperbahurui oleh
        Gajiku dari waktu ke waktu untuk mengubah, menghapus dan/atau menerapkan
        syarat dan ketentuan baru. Penggunaan Platform Gajiku oleh Pengguna
        lebih lanjut akan dianggap sebagai persetujuan untuk tunduk kepada
        perubahan atas Syarat dan Ketentuan ini. KETENTUAN UMUM Gajiku adalah
        platform aplikasi layanan berbasis teknologi yang menyediakan fasilitas
        pembayaran gaji di muka bagi karyawan Pengguna. Gajiku dimiliki oleh PT
        Vara Technologies Inc. Platform Gajiku adalah aplikasi versi Android
        dengan nama “Gajiku” dan situs yang beralamat di www.gajikuapp.com.
        Untuk menggunakan Platform Gajiku diperlukan koneksi internet serta
        perangkat Android, dan segala biaya yang timbul atas penggunaan koneksi
        perangkat elektronik tersebut merupakan tanggung jawab Pengguna
        sepenuhnya. Pengguna adalah pihak yang menggunakan dan/atau menikmati
        layanan Gajiku, termasuk namun tidak terbatas pada perusahaan mitra dan
        karyawan. Gajiku berhak untuk tidak memproses registrasi, membatasi,
        menunda atau menghentikan Pengguna untuk menggunakan Platform Gajiku,
        apabila terdapat alasan-alasan bahwa Pengguna telah melakukan
        pelanggaran terhadap syarat dan ketentuan yang sebagaimana diatur dalam
        Syarat dan Ketentuan Pengguna ini dan/atau peraturan perundang-undangan
        yang berlaku. Syarat & Ketentuan adalah perjanjian antara Pengguna dan
        Gajiku yang berisikan seperangkat peraturan yang mengatur hak,
        kewajiban, tanggung jawab pengguna dan Gajiku, serta tata cara
        penggunaan sistem layanan Gajiku. KETENTUAN PENGGUNA Karyawan yang ingin
        menggunakan layanan Gajiku dan untuk mengakses Platform Gajiku harus
        terlebih dahulu melakukan pendaftaran yang disertai dengan pemberian
        informasi berupa nomor ponsel yang dimiliki oleh karyawan. Perusahaan
        yang telah bekerjasama dengan Gajiku dapat menggunakan layanan Gajiku
        dengan mendapatkan akses online dashbor Platform Gajiku untuk dapat
        melihat karyawan yang telah melakukan penarikan gaji diawal. Setelah
        mendaftarkan diri pada Platform Gajiku, Pengguna akan mendapatkan akun
        pribadi di Gajiku yang dapat diakses dengan One Time Password (OTP) yang
        akan dikirimkan ke nomor ponsel Pengguna yang telah terdaftar dan
        terverifikasi oleh Gajiku. Kata sandi atas akun Pengguna yang diberikan
        melalui One Time Password (OTP) wajib dijaga kerahasiaannya. Pengguna
        kemudian dapat mendaftarkan kode Personal Identification Number (PIN)
        untuk login selanjutnya. Segala perubahan data dan informasi Pengguna
        wajib diberitahukan kepada Gajiku. Informasi terkait dengan data pribadi
        yang diberikan oleh Pengguna kepada Gajiku hanya akan digunakan untuk
        memberikan pelayanan-pelayanan dalam Platform Gajiku dan untuk tujuan
        lain sebagaimana yang diatur dalam Syarat dan Ketentuan, kebijakan
        privasi yang telah disetujui oleh Pengguna serta sesuai dengan ketentuan
        perundang-undangan yang berlaku. Pengguna juga wajib memberikan
        informasi yang benar, akurat, lengkap, dan terbaru serta dapat
        melampirkan semua bukti identitas atau bukti lain yang diperlukan untuk
        pendaftaran Platform Gajiku. Dalam setiap perubahan informasi rekening
        bank Pengguna yang tercantum di dalam database Platform Gajiku, maka
        Gajiku akan melakukan verifikasi ganda, yang pertama adalah verifikasi
        kepada perusahaan Pengguna dan verifikasi melalui nomor ponsel yang
        telah terverifikasi dengan mengirimkan one-time password (OTP). Karyawan
        Pengguna wajib untuk memastikan bahwa nama yang terdapat pada rekening
        bank sesuai dengan nama yang diberikan oleh perusahaan Pengguna kepada
        Gajiku. Setiap perubahan informasi rekening bank akan mengakibatkan tes
        verifikasi lanjutan dan dapat menunda waktu proses. Dalam hal terjadi
        pengungkapan kata sandi ataupun akun Pengguna telah disusupi yang dapat
        mengakibatkan kerugian bagi Pengguna akibat penggunaan yang tidak sah
        (termasuk diantaranya peretasan akun Pengguna), tanpa kewenangan dan
        melawan hukum atas akun Pengguna yang terjadi bukan karena kesalahan
        dari Gajiku, maka transaksi penarikan gaji atas layanan yang dilakukan
        melalui Platform Gajiku dianggap sebagai transaksi yang sah, kecuali
        Pengguna telah memberitahukannya terlebih dahulu kepada pihak Gajiku
        mengenai hal tersebut. Pengguna wajib melaporkan kepada pihak Gajiku
        dalam hal terdapat penyalahgunaan akun Pengguna dalam Platform Gajiku,
        Pengguna bertanggung jawab atas setiap penggunaan akun Pengguna,
        meskipun akun tersebut telah disalahgunakan oleh orang lain. Pengguna
        tidak diperkenankan untuk membahayakan, memanipulasi, menyalahgunakan
        atau memodifikasi Platform Gajiku dengan cara apapun, pihak Gajiku
        berhak untuk menghentikan, menutup, membatalkan dan melarang Pengguna
        untuk menggunakan Platform Gajiku karena telah melanggar ketentuan
        Syarat dan Ketentuan ini. Pengguna telah setuju dan memahami akan
        menggunakan Platform Gajiku hanya untuk tujuan sebagaimana yang dimaksud
        dalam layanan, dan tidak diperbolehkan untuk menyalahgunakan atau
        menggunakan Platform Gajiku untuk aktivitas yang bertentangan dengan
        hukum, termasuk namun tidak terbatas pada tindakan pidana penggelapan
        maupun penipuan. Perusahaan Pengguna wajib secara untuk melakukan
        pembaharuan data karyawan secara tepat waktu, terhadap setiap perubahan
        gaji karyawan, pengunduran diri karyawan, dan/atau penambahan karyawan
        melalui Platform Gajiku. Dengan memberikan informasi data pribadi kepada
        Gajiku, Pengguna telah memahami dan setuju terhadap syarat dan ketentuan
        penggunaan Platform Gajiku. KEAMANAN AKUN Gajiku berkomitmen untuk
        melakukan segala upaya yang wajar untuk menjaga agar Platform Gajiku ini
        dapat berfungsi dengan baik, aman, nyaman dan berjalan lancar sesuai
        dengan peruntukannya, tapi Gajiku tidak dapat menjamin secara
        terus-menerus akses layanan yang kami berikan dapat selalu sempurna.
        Pengguna telah setuju dan mengetahui layanan yang diberikan oleh Gajiku
        kepada Pengguna adalah SEBAGAIMANA ADANYA dan SEBAGAIMANA TERSEDIA dalam
        arti Pengguna Platform Gajiku telah setuju bahwa pemanfaatan layanan
        Gajiku atas risiko Pengguna itu sendiri, dan Gajiku tidak dapat menjamin
        sebagai berikut: Gajiku tidak menjamin bahwa layanan yang diberikan akan
        bebas dari kesalahan dan/atau kecacatan saat beroperasi dengan kombinasi
        dengan perangkat keras, perangkat lunak, sistem atau data lainnya. Bahwa
        ketersediaan dan kehandalan, maupun server-server pendukung yang
        menyediakan Platform Gajiku akan terbebas dari virus dan komponen bahaya
        lainnya. Bahwa kualitas produk, fitur, dan informasi yang tersedia di
        Platform Gajiku akan memenuhi harapan dan kebutuhan Pengguna. Bahwa
        Pengguna mengetahui dan setuju bahwa penggunaan akses internet tidak
        selamanya menciptakan suatu yang aman dan pribadi, dan karenanya setiap
        pesan atau informasi yang Pengguna kirimkan atau gunakan dalam Platform
        Gajiku mungkin dapat diretas atau diambil oleh Pihak Ketiga yang tidak
        bertanggung jawab. KEBIJAKAN PRIVASI Kebijakan privasi sebagaimana
        diatur dalam Syarat dan Ketentuan ini akan menjelaskan bagaimana Gajiku
        mengelola, mengolah, memperoleh, mengumpulkan, menggunakan,
        mengungkapkan dan memproses, membuka akses, dan melindungi informasi dan
        data pribadi yang telah diberikan oleh Pengguna pada saat melakukan
        registrasi untuk menggunakan Platform Gajiku. Perlu diketahui bahwa data
        pribadi di sini tidak termasuk dalam data pribadi yang telah tersedia di
        domain publik. Pengguna Platform Gajiku perlu membaca kebijakan privasi
        ini dengan sepenuhnya sebelum melakukan pendaftaran dan/atau mengakses
        layanan Platform Gajiku, karena kebijakan privasi ini merupakan bagian
        dari Syarat dan Ketentuan penggunaan Platform Gajiku. Dengan menggunakan
        Platform Gajiku, Pengguna dianggap telah membaca dan menyetujui
        kebijakan privasi serta pemanfaatan data pribadi yang akan digunakan
        untuk keperluan pemberian layanan sesuai dengan kebijakan privasi ini.
        Registrasi Informasi yang diberikan mengenai identitas diri yang wajib
        diisi oleh Pengguna akun di Platform memuat antara lain: Nama lengkap
        sesuai dengan kartu identitas yang berlaku (Kartu Tanda Penduduk atau
        Paspor); Nomor Handphone yang akan dipergunakan untuk mengirimkan
        one-time password (OTP); dan Gaji pokok bulanan. Pengguna telah
        mengetahui dan menyetujui bahwa Gajiku dapat menerima informasi
        Pengguna, termasuk namun tidak terbatas pada lokasi/kordinat GPS (Jika
        Pengguna mengaktifkan fitur tersebut), alamat intenet protocol (ip)
        (Jika Pengguna menggunakan browser), system operasi, jenis browser,
        alamat platform. Pengguna menyetujui bahwa Gajiku akan melakukan
        verifikasi langsung kepada Pengguna, termasuk perusahaan tempat dimana
        karyawan bekerja mengenai data diri yang telah disampaikan oleh karyawan
        sebagai Pengguna melalui Platform Gajiku. Pengguna menjamin bahwa
        informasi yang diberikan adalah benar, akurat, dan terbaru. Dalam hal
        ternyata informasi yang diberikan tidak benar, maka Gajiku tidak
        bertanggung jawab atas segala akibat yang mungkin dapat timbul
        sehubungan dengan pemberian dan penggunaan informasi yang tidak benar
        tersebut. Penggunaan Data Pribadi Pengguna dengan ini memberikan
        persetujuan secara tegas kepada Gajiku untuk melakukan segala
        pemanfaatan data pribadi Pengguna untuk kegiatan bisnis dan operasional
        maupun keperluan lainnya yang dibutuhkan sesuai dengan tujuan
        diberikannya Data Pribadi tersebut, termasuk namun tidak terbatas untuk
        melakukan sebagai berikut: Gajiku menyimpan informasi pribadi Pengguna
        selama informasi tersebut terkait dengan tujuan-tujuan yang dibutuhkan
        untuk kepentingan pelayanan Gajiku. Kerahasiaan informasi pribadi yang
        Pengguna berikan tidak akan disebarluaskan kecuali apabila telah
        disetujui oleh Pengguna berdasarkan kebijakan privasi dalam Syarat dan
        Ketentuan atau diwajibkan oleh peraturan perundang-undangan yang
        berlaku. Bahwa dengan menggunakan Platform Gajiku, Pengguna menyetujui
        dan mengizinkan pihak ketiga tertentu untuk dapat menggunakan data
        pribadi Pengguna yang diperlukan sehubungan dengan pelayanan Gajiku,
        termasuk namun tidak terbatas pada: Perusahaan penyedia layanan terkait
        teknologi informasi (TI) seperti layanan infrastruktur, komputasi awan
        (cloud computing), perangkat lunak, pengembangan analisa “Big Data” dan
        “Machine Learning”, termasuk didalamnya layanan untuk melakukan
        “Cleansing Data”, “Data Insight”, “Credit Scoring” dan lain-lain. Pihak
        jasa penyedia pemrosesan transaksi pembayaran transfer dana dari Pihak
        Gajiku kepada Pengguna. Badan-badan atau lembaga Pemerintah (apabila
        dibutuhkan). Pihak-Pihak lainnya selama dilakukan sesuai dengan
        peraturan perundang-undangan yang berlaku. Upaya Pengamanan Gajiku
        senantiasa berkomitmen dam memberikan layanan terbaiknya dengan
        memastikan bahwa informasi yang Pengguna berikan kepada Gajiku aman dan
        tidak dapat digunakan oleh pihak-pihak yang tidak bertanggungjawab.
        Dengan menggunakan Platform Gajiku, Pengguna mengizinkan Gajiku untuk
        dapat melakukan segala tindakan pengamanan yang dianggap perlu untuk
        melindungi informasi Pengguna. Namun demikian, gajiku tidak dapat
        menjamin hal tersebut secara sempurna dan tidak bertanggung jawab atas
        segala kerugian, kehilangan data yang terjadi dari insiden di luar
        kendali Gajiku. Oleh karenanya Gajiku sangat menyarankan agar Pengguna
        tetap menjaga kerahasiaan kata sandi dan/atau tidak memberikan akses
        penggunaan Platform Gajiku kepada pihak manapun dan selalu melakukan
        pembaharuan terhadap Platform Gajiku. Pengguna dengan ini memberikan
        persetujuannya kepada Gajiku untuk dapat mengirimkan, menyimpan,
        menggunakan dan mengolah data pribadi pada server yang telah ditentukan
        oleh pihak Gajiku. Server tersebut dapat dikelola oleh Gajiku dengan
        bekerjasama dengan pihak ketiga lainnya. Meskipun demikian, Pihak Gajiku
        akan mengatur penggunaan dan pemanfaatan informasi dan data pribadi
        tersebut berdasarkan kebijakan privasi ini sesuai dengan ketentuan
        peraturan perundang-undangan yang berlaku. FITUR LAYANAN Gajiku adalah
        platform berbasis teknologi yang menawarkan fasilitas bagi karyawan
        Pengguna agar dapat mendapatkan gaji yang sudah dihasilkan sebelum waktu
        gajian dengan ketentuan bahwa gaji yang dapat ditarik oleh Pengguna
        adalah maksimal 15 (lima belas) hari kerja. Dengan menggunakan layanan
        Platform Gajiku, Pengguna dapat melakukan penarikan gaji kapan saja dan
        dimana saja tanpa harus menunggu waktu gajian tiba. Karyawan Pengguna
        telah mengetahui dan setuju bahwa perusahaan Pengguna akan melakukan
        pemotongan terhadap gaji yang akan diterima oleh karyawan Pengguna pada
        periode penggajian terhadap jumlah penarikan gaji diawal yang dilakukan
        oleh karyawan Pengguna melalui Platform Gajiku. Karyawan Pengguna telah
        setuju dan mengizinkan perusahaan Pengguna untuk melakukan pemotongan
        gaji pada periode waktu pembayaran gaji Pengguna atas penarikan gaji
        diawal yang dilakukan oleh karyawan Pengguna. Sesuai dengan standar
        operasional Gajiku, proses pencairan dana hanya memerlukan maksimal 1
        (satu) jam sejak adanya permintaan pencairan gaji dari karyawan
        Pengguna. Dengan melakukan penarikan gaji melalui Platform Gajiku,
        karyawan Pengguna telah setuju untuk dikenakan biaya transaksi yang
        ditetapkan oleh Gajiku atas jumlah penarikan gaji dimuka. Apabila
        karyawan Pengguna tidak setuju atas jumlah biaya transaksi yang
        dikenakan, karyawan Pengguna wajib untuk menghentikan pemakaian layanan
        Platform Gajiku. Gajiku akan menerbitkan laporan kepada perusahaan
        Pengguna dan/atau karyawan Pengguna sehubungan dengan penarikan gaji
        yang dilakukan oleh karyawan, yang diantaranya memuat rincian penarikan
        gaji di muka yang dilakukan oleh karyawan Pengguna. BIAYA TRANSAKSI
        Gajiku tidak akan membebankan biaya dan/atau bunga apapun kecuali biaya
        transaksi kepada karyawan Pengguna atas setiap penarikan gaji dimuka
        yang dilakukan oleh karyawan Pengguna. Biaya transaksi disini adalah
        adalah biaya yang dikenakan kepada karyawan Pengguna sebesar 3% (tiga
        persen) dari total jumlah gaji yang diambil dimuka untuk tujuan
        pemeliharaan sistem dan peningkatan layanan Gajiku. Contoh: Gaji = Rp.
        1.000.000,- dikurang biaya transaksi 3% = maka Gaji yang akan diterima
        karyawan Pengguna adalah sebesar Rp. 970.000,- Biaya transaksi yang
        dikenakan Gajiku bersifat tetap, berapapun gaji yang ditarik dimuka oleh
        karyawan Pengguna. Gajiku berhak sewaktu-waktu tanpa pemberitahuan
        sebelumnya untuk melakukan perubahan atas biaya transaksi yang terdapat
        pada Syarat dan Ketentuan ini. Dengan menggunakan Platform Gajiku,
        Pengguna dianggap memahami dan menyetujui Syarat dan Ketentuan atas
        biaya transaksi yang berlaku. PEMBATASAN TANGGUNG JAWAB Dalam melakukan
        penyediaan layanan, Gajiku akan melaksanakan dengan penuh kehati-hatian,
        namun demikian Gajiku tidak bertanggung jawab terhadap segala tindakan
        yang dilakukan oleh Pengguna sehubungan dengan pemanfaatan layanan
        Gajiku yang dilakukan dengan itikad buruk, kesalahan yang disengaja,
        kelalaian Pengguna. KLAIM Pihak Pengguna dapat melakukan pengaduan dan
        pemberitahuan melalui Platform Gajiku dengan mengisi form dalam aplikasi
        atau dapat dikirimkan melalui surat elektronik (e-mail) di
        nadia@gajikuapp.com, sejak diketahui ada tindakan-tindakan yang
        mecurigakan diluar dari aktifitas yang dilakukan oleh Pengguna dalam
        Platform Gajiku. Gajiku akan segera memproses pengaduan atau
        pemberitahuan dalam jangka waktu 3 (tiga) hari setelah pengaduan
        tersebut diterima oleh Gajiku dan akan segera melakukan konfirmasi
        kepada Pengguna. Dalam hal terdapat klaim penipuan sehubungan dengan
        pencairan gaji karyawan Perusahaan, Gajiku akan melakukan investigasi
        atas klaim tersebut berdasarkan prosedur operasi standar yang berlaku
        dalam internal Gajiku. Gajiku akan bertindak dengan penuh kehati-hatian
        dan ketekunan serta menggunakan keterampilan dan perhatian yang sama
        dalam menyediakan layanan, tetapi tidak akan bertanggung jawab atas
        tindakan apa pun yang diambil atau dihilangkan olehnya dalam pelaksanaan
        layanan yang diberikan di sini jika tidak ada itikad buruk, kesalahan
        yang disengaja, kelalaian yang parah atau pengabaian yang ceroboh
        terhadap tugas-tugasnya. Gajiku tidak bertanggung jawab terhadap klaim
        penipuan yang diajukan Pengguna sebagai akibat karena kelalaiannya
        sendiri dengan tidak menjaga keamanan akun Pengguna yang mengakibatkan
        penggunaan yang tidak sah. Pengguna dengan ini melepaskan tanggung jawab
        Gajiku terhadap ganti kerugian atas setiap klaim yang diakibatkan oleh
        kelalaian Pengguna. PAJAK Seluruh perpajakan yang mungkin timbul
        sehubungan dengan penggunaan layanan Platform Gajiku wajib ditanggung
        oleh masing-masing pihak sehubungan dengan peraturan perundang-undangan
        yang berlaku. HAK ATAS KEKAYAAN INTELEKTUAL Gajiku adalah pemilik
        tunggal atas nama, ikon, dan logo, serta segala hak kekayaan intelektual
        yang terkait dengan Situs, sistem, dan tampilan antarmuka, yang mana
        semua itu merupakan hak cipta dan merek dagang yang dilindungi oleh
        ketentuan perundang-undangan. Pengguna dilarang keras tanpa persetujuan
        tertulis dari Gajiku untuk menggunakan, memodifikasi, atau memasang
        nama, ikon, logo atau merek tersebut. Seluruh hak atas kekayaan
        intelektual yang terdapat dalam Platform Gajiku, termasuk namun tidak
        terbatas pada source code platform dilarang keras untuk dilakukan
        modifikasi, karya turunan, mengadaptasi, menduplikasi, menyalin,
        menjual, membuat ulang, meretas, menjual, dan/atau mengeksploitasi
        Platform Gajiku, penggunaan Platform Gajiku atas akses yang tidak sah,
        meluncurkan program otomatis atau script, atau segala program apapun
        yang mungkin menghambat operasi dan/atau kinerja Platform Gajiku, atau
        dengan cara apapun memperbanyak atau menghindari struktur navigasi atau
        presentasi dari Platform Gajiku atau isinya. Pengguna hanya
        diperbolehkan untuk menggunakan Platform Gajiku untuk kebutuhan pribadi
        non-komersial, tidak dapat dialihkan dan tidak disublisensikan. Gajiku
        memiliki hak sepenuhnya untuk melakukan segala tindakan hukum kepada
        Pengguna terhadap setiap pelanggaran Hak Kekayaan Intelektual terkait
        dengan Platform Gajiku. PEMBERITAHUAN Semua pemberitahuan atau
        permintaan informasi kepada dan/atau tentang Platform Gajiku akan
        diproses jika dibuat secara tertulis dan dikirimkan ke alamat Gajiku di
        Jl Mandor Demong No. 07 Kel. Mustikasari, Kec. Mustika Jaya Bekasi
        17157, atau dapat dikirimkan melalui surat elektronik (e-mail) di
        nadia@gajikuapp.com, atau dapat menghubungi layanan call center kami di
        +6281231747236. Setiap pemberitahuan yang diberikan oleh pihak Gajiku
        akan dianggap sudah diterima oleh Pengguna tersebut, apabila: Pihak
        Gajiku dapat menunjukkan bahwa pemberitahuan tersebut, baik secara fisik
        maupun elektronik telah dikirimkan kepada Pengguna tersebut; atau Pihak
        Gajiku telah melakukan pemberitahuan tersebut di Platform Gajiku yang
        dapat diakses oleh umum tanpa dikenakan biaya apapun. PENYELESAIAN
        SENGKETA Segala perselisihan dan perbedaan pendapat yang mungkin timbul
        dalam pelaksanaan Syarat dan Ketentuan ini akan diselesaikan secara
        musyawarah, dan apabila timbul persoalan yang tidak dapat diselesaikan
        secara musyawarah, dalam jangka waktu 30 (tiga puluh) hari setelah
        terjadinya perselisihan, maka perselisihan pengaduan Pengguna wajib
        diselesaikan terlebih dahulu melalui forum dengan Gajiku. Dalam hal
        penyelesaian pengaduan Pengguna tidak tercapai setelah 30 (tiga puluh)
        hari, maka Pengguna dan Gajiku sepakat untuk menyelesaikan sengketa
        tersebut melalui Pengadilan Negeri Jakarta Selatan. HUKUM YANG BERLAKU
        Syarat dan Ketentuan ini diatur dan ditafsirkan serta dilaksanakan
        berdasarkan hukum yang berlaku di Negara Republik Indonesia dan KEADAAN
        MEMAKSA Gajiku tidak akan bertanggung jawab atas keterlambatan atau
        kegagalan untuk melakukan kewajibannya berdasarkan Syarat dan Ketentuan
        ini, jika penundaan atau kegagalan tersebut diakibatkan oleh peristiwa
        atau keadaan di luar kendali yang wajar, termasuk namun tidak terbatas
        pada peristiwa-peristiwa kebakaran, bencana alam (seperti gempa bumi,
        banjir, angin topan, petir), pemogokan massal, huru-hara, peperangan,
        kerusakan server, adanya perubahan terhadap peraturan
        perundang-undangan, dan kondisi di bidang ekonomi, keuangan, kerusakan
        sistem transaksi pada Platform Gajiku, serta terganggunya sistem
        penyelesaian transaksi pada Platform Gajiku. LAIN-LAIN Pengguna dengan
        tegas menyetujui bahwa ketentuan Pasal 1266 Kitab Undang-Undang Hukum
        Perdata Indonesia yang mewajibkan adanya pengesahan atau persetujuan
        Pengadilan untuk dapat mengakhiri Syarat dan Ketentuan ini tidak
        berlaku. Gajiku tanpa pemberitahuan sebelumnya berhak untuk melakukan
        tindakan-tindakan yang diperlukan, apabila ditemukan adanya tindakan
        kecurangan/manipulasi, pencucian uang atau pelanggaran yang dilakukan
        oleh Pengguna dan setiap pelanggaran yang dilakukan oleh Pengguna akan
        diproses berdasarkan ketentuan hukum yang berlaku di Indonesia. Gajiku
        memiliki hak untuk menginvestigasi dan menuntut hak Gajiku atas setiap
        pelanggaran terhadap perjanjian Syarat dan Ketentuan ini sepanjang
        dimungkinkan dan diperkenankan oleh hukum yang berlaku di Indonesia.
        Dengan ini Pengguna mengakui dan menyetujui bahwa Gajiku memiliki hak
        untuk memonitor setiap akses penggunaan Platform Gajiku, untuk
        memastikan Pengguna mematuhi syarat dan ketentuan Penggunaan ini, serta
        mematuhi ketentuan peraturan perundang-undangan yang berlaku di
        Indonesia. Syarat dan Ketentuan ini dibuat dalam Bahasa Indonesia dan
        Bahasa Inggris. Bila terdapat inkonsistensi atau perbedaan interpretasi
        antara Bahasa Indonesia dan Bahasa Inggris, maka versi Bahasa Indonesia
        yang akan berlaku. Dengan ini, Pengguna Platform Gajiku menyatakan dan
        menjamin bahwa semua informasi yang diberikan oleh Pengguna kepada
        Gajiku adalah benar dan akurat, dalam hal Pengguna menyampaikan
        informasi yang tidak benar, maka Gajiku berhak untuk menghentikan,
        menutup akses Pengguna ke Platform Gajiku, menutup dan/atau membatalkan
        akun Pengguna dalam Platform Gajiku. Gajiku mencadangkan semua hak yang
        tidak secara tegas diberikan di sini. Gajiku dapat mengubah Syarat dan
        Ketentuan ini setiap saat dengan mengumumkan Syarat dan Ketentuan yang
        direvisi di Platform Gajiku. Penggunaan Platform Gajiku oleh Pengguna
        setelah perubahan tersebut diumumkan dianggap sebagai penerimaan
        Pengguna terhadap Syarat dan Ketentuan yang direvisi tersebut. Dalam hal
        Pengguna tidak setuju terhadap Syarat dan Ketentuan ini, harap tidak
        mengakses, menelusuri ataupun menggunakan Platform Gajiku. Syarat dan
        Ketentuan ini terakhir kali diubah: 4 November, 2020.
      </div>
    );
  };

  const TncEnglish = () => {
    return (
      <div className="language">
        Welcome to Gajiku! <br /> The Terms & Conditions as set out below govern
        the use of services offered by PT Vara Technologies Inc in connection
        with the use of the website www.gajikuapp.com and Gajiku application.
        User are advised to read carefully because it might impact the rights
        and obligations of User under the applicable laws. By downloading,
        installing, using and/or enjoying our service on the Gajiku Platform,
        User have read, understood, acknowledged, agreed, and accepted all
        terms, conditions, and all information of use Gajiku Platform. These
        Terms and Conditions constitute valid agreement as to the procedure and
        requirement for Gajiku Platform between User and the administrator of
        Gajiku Platform, namely PT Vara Technologies Inc. Term of use and
        privacy policy may be renewed by Platform Administrator from time to
        time, to modify, delete and/or apply new terms and conditions. Further
        use of Gajiku Platform by User will be considered as an agreement to be
        subject to changes to this Terms and Conditions. GENERAL CONDITIONS
        Gajiku is a technology-based service application platform that provides
        advance salary payment facilities for User employees. Gajiku is owned by
        PT Vara Technologies Inc. Gajiku platform is an application Android
        version under the name “Gajiku” and website address at
        www.gajikuapp.com. In order to use Gajiku Platform, an internet
        connection and an Android device is required, and all costs arising from
        using the electronic device connection are the sole responsibility of
        User. User is the party who uses and/or enjoys Gajiku services,
        including, but not limited to, the partner company and employee. Gajiku
        has the rights to not process, limit, suspend or terminate User to use
        Gajiku Platform, in the event there is a reason that User have violated
        this Terms and Condition and/or applicable laws. Terms & Conditions is
        an agreement between User and Gajiku which contains a set of rules
        governing the rights, obligations, responsibilities of User and Gajiku,
        as well as procedures for using the Gajiku service system. TERMS OF USER
        Employee who want to use Gajiku service and to access Gajiku Platform
        need to register, accompanied by submitting information in form of a
        cellphone number owned by the employee. Company that have collaborated
        with Gajiku can use Gajiku service by getting online access to the
        Gajiku Platform dashboard to be able to see employees who have made
        advance salary withdrawals. After registering in Gajiku Platform, User
        will receive a personal account in Gajiku accessible with One Time
        Password (OTP) that will sent to the registered User mobile phone number
        and verified by Gajiku. The password of User account provided by One
        Time Password (OTP) shall keep confidential. Users can then register a
        Personal Identification Number (PIN) code to login going forward. Any
        changes in of User data and information must be notified to Gajiku.
        Information relating personal data provided by User will only be used by
        Gajiku to render the service in Gajiku Platform and for other purpose in
        accordance with privacy policy that has been agreed by User and in
        accordance with the applicable laws. User must provide true, accurate,
        complete, and up to date information and agree to provide any proof
        identity or another proof as reasonably requested for registration of
        Gajiku Platform. In any changes to User's bank account information
        listed in Gajiku Platform database, Gajiku will take double
        verification, the first is verification to User company and verification
        through a verified mobile number by sending a one-time password (OTP).
        User employee must ensure that the name on the bank account is match
        with the name as provided by the User company to Gajiku. Any changes to
        the bank account information will result in a subsequent verification
        test and may delay the timings process. In the event that disclosure of
        passwords or User account has been compromised which can result in
        losses for User due to the unauthorized use (including hacking of User
        account), without authority and against the law on User account that
        occurs not because of Gajiku's fault, the salary withdrawal transaction
        services performed through Gajiku Platform is considered as valid
        transactions, unless User has notified Gajiku regarding this matter in
        advance. User must report to Gajiku for any whatsoever misuse account
        User in Gajiku Platform, User is responsible for the use of User
        account, even though the account has been misused by someone else. User
        are not permitted to endanger, manipulate, misuse or modify Gajiku
        Platform whatsoever, Gajiku shall have the right to close, cancel and
        prohibit User using the Gajiku Platform due to the violation of this
        Terms and Conditions. User has agreed and understand that User will use
        Gajiku Platform only for the purposes as referred to in the service, and
        is not allowed to misuse or use Gajiku Platform for activities that are
        against the law, including but not limited to criminal acts of
        embezzlement or fraud. User company is obliged to update employee data
        on time, for any change in employee salary, employee resignation, and/or
        additional employee through Gajiku Platform. By providing personal data
        information to Gajiku, User understands and agreed to Terms and
        Conditions of Gajiku Platform. ACCOUNT SECURITY Gajiku is committed to
        take all reasonable efforts to keep Gajiku Platform is able to function
        properly, safe, comfortably and smoothly as intended, but Gajiku not
        guarantee continuous access to the service we provide may always be
        perfect. User has agreed and acknowledged that the services provided by
        Gajiku to User are AS IS and AS AVAILABLE in the sense that Gajiku
        Platform User has agreed that the use of Gajiku services at User's own
        risk and Gajiku cannot guarantee as follows: Gajiku do not guarantee
        that the service provided will be free from error and/or defects when
        operating in combination with hardware, software, system or other data.
        Whereas the availability and reliability, as well as the supporting
        servers that provide by Gajiku Platform will be free from viruses and
        other dangerous components. Whereas quality of products, features and
        available information on the Gajiku Platform will fulfill User
        expectation and needs. Whereas User acknowledges and agrees that the use
        of internet access cannot continuously create something safe and
        private, and therefore any messages or information that User sent or
        used on Gajiku Platform may be hacked or taken by unauthorized Third
        Parties. PRIVACY POLICY The privacy policy as set out in this Terms and
        Conditions will explain how Gajiku manages, processes, obtains,
        collects, uses, discloses and processes, opens access, and protects
        information and personal data that has been provided by User when
        registering to use Gajiku Platform. Please note that the personal data
        is excluding the personal data that is already available in the public
        domain. Gajiku Platform User need to read this privacy policy thoroughly
        before registering and/or accessing Gajiku Platform service, because the
        privacy policy is part of Terms and Condition of Gajiku Platform. By
        using Gajiku Platform, User is deemed to have read and agreed to the
        privacy policy as well as using personal data to be used for the
        purposes of the rendering the service in accordance with this privacy
        policy. Registration Information provided regarding personal identity
        that must be filled in by account User on the Platform includes among
        other: Full name as printed in the valid Identity Card (ID card or
        Passport); Mobile phone number that will be used to send one-time
        password (OTP); and Monthly basic salary. User acknowledges and agrees
        that Gajiku can receive User information, including but not limited to
        GPS location/coordinates (if User activates this feature), internet
        protocol (IP) address (if User using a browser), operating system,
        browser type, platform address. User agrees that Gajiku will verify
        directly to User, including to company where User works in regard to the
        personal data that has been submitted by User through Gajiku Platform.
        User guarantee that the information provided is true, accurate, and up
        to date. In case that the information provided is incorrect, Gajiku is
        not responsible for all consequences that may arise in connection with
        the provision and use of such incorrect information. Use of Personal
        Data User hereby gives express consent to Gajiku to make all use of User
        personal data for business and operational activities as well as for
        other purposes required in accordance with the purpose for which
        Personal Data is provided, including but not limited to as follows:
        Gajiku will keep User personal information as long as the information is
        related to the purposes required for the Gajiku service interests.
        Confidentiality of personal information that user provides will not
        distributed unless agreed by User based privacy policy on this Terms and
        Conditions or permitted by applicable laws. That by using the Gajiku
        Platform, User agrees and allows certain third parties to use User
        Personal Data required in connection with Gajiku services, including but
        not limited to: Service provider company for information technology (IT)
        such as infrastructure service, cloud computing, software, analysis
        development of “Big Data” and “Machine Learning”, including “Data
        Cleansing”, “Data Insight”, “Credit Scoring” and others. Transaction
        processing service providers of funds transfer payments from Gajiku to
        Users. Government agencies or institutions (if needed), Other parties to
        the extent permitted by regulation and applicable laws. Security Measure
        Gajiku is committed to provide the best service by ensuring that User
        information provided to Gajiku is safe and cannot be used by
        unauthorized parties. By using Gajiku Platform, User allow Gajiku to be
        able to take all necessary security measures to protect User
        information. However, Gajiku cannot guarantee this perfectly and is not
        responsible for any loss, loss of data that occurs from incidents beyond
        control of Gajiku. Therefore, Gajiku strongly recommends that User keep
        passwords confidential to any party and always make updates to Gajiku
        Platform. User hereby gives consent to Gajiku to be able send, store,
        use and treat personal data on the server that has been determined by
        Gajiku. Server can be managed by Gajiku in collaboration with other
        third parties. However, Gajiku, will regulate the use and utilization of
        the data information will be governed by this privacy policy in
        accordance with the prevailing laws and regulations. SERVICE FEATURE
        Gajiku is a technology-based platform that offer facilities for its User
        employees to be able to get a salary earned before the payday period
        provided that the salary that can be withdrawn by User is a maximum of
        15 (fifteen) working days. By using Gajiku Platform services, User can
        make salary withdrawals anytime and anywhere without having to wait for
        payday. User employees have acknowledged and agreed that the User’s
        company will deduct the salary that will be received by User employees
        during the payroll period against the amount of advance salary
        withdrawals made by User employees through Gajiku Platform. User
        employees have agreed and allowed User’s company to make salary
        deductions on the payday for the advance salary withdrawal made by User
        employees. In accordance with the operational standard of Gajiku, the
        process of disbursing funds only required a maximum of 1 (one) hour
        after withdrawal of funds requested by User employees. By making a
        salary withdrawal through Gajiku Platform, User employees has agreed to
        be charged for transaction fee for the advance salary withdrawal. If
        User employee does not agree to the amount of transaction fee that being
        charged, User employee is required to stop using Gajiku Platform
        services. Gajiku will issue a report to User company and/or User
        employees in connection with the salary withdrawal made by employees,
        which includes details of advance salary withdrawal made by User
        employees. TRANSACTION FEE Gajiku will not charge any fees and/or
        interest except for the transaction fee to User employees for any
        advance salary withdrawals made by User employees. Transaction fee is a
        fee that is charged to User employee in the amount of 3% (three percent)
        of the total amount of advance salary withdrawn for the purpose of
        maintenance the system and improve Gajiku services. Example: Salary =
        Rp. 1.000.000,- reduce by transaction fee 3% = then the salary that will
        be received by User employee is Rp. 970.000 Transaction fee charged by
        Gajiku is flat, regardless of the advance salary withdrawn by User.
        Gajiku has the right at any time without prior notice to make changes to
        the transaction fee contained in this Terms and Conditions. By using the
        Gajiku Platform, User is deemed to understand and agree to the Terms and
        Conditions of the transaction fee that apply. LIMITATION OF LIABILITY In
        providing services, Gajiku will carry out prudence, however Gajiku is
        not responsible for all actions taken by User in connection with the use
        of Gajiku services which are carried out in bad faith, willful
        misconduct, user negligence. CLAIM User can make notifications and
        complaints through Gajiku Platform by filling out the form application
        or via electronic mail (e-mail) at nadia@gajikuapp.com, since it has
        been observed there have been suspicious actions outside of the
        activities carried out by User on the Gajiku Platform. Gajiku will
        immediately process the complaint or notification within period 3
        (three) days after the complaint is received by Gajiku and will
        immediately confirm to User. In the event a fraud claim occurs in
        connection with the disbursement of User employee salary, Gajiku will
        investigate such claim according to the applicable standard operating
        procedure in Gajiku internally. Gajiku will exercise due care and
        diligence and use the same skill and care in providing its services
        hereunder, but shall not be liable for any action taken or omitted by it
        in the performance of services rendered hereunder in the absence of its
        bad faith, willful misconduct, gross negligence or reckless disregard of
        its duties. Gajiku is not responsible for fraudulent claims submitted by
        User as a result of their own negligence by not maintaining their
        security of User account which results in unauthorized use. User hereby
        disclaims any liability of Gajiku for compensation for any claims caused
        by User negligence. TAXES All taxes that may arise in connection with
        the use of Gajiku Platform services shall be borne by each party in
        accordance with the prevailing laws. INTELLECTUAL PROPERTY RIGHTS Gajiku
        is the sole owner of the name, icon and logo, and all intellectual
        property rights related to the website, system, and interface are fully
        owned by Gajiku, which is copyright and trademark is protected by law.
        It is strictly forbidden without Gajiku's written consent to use,
        modify, or post the name, icon, logo or brand. All intellectual property
        rights contained in Gajiku Platform, including but not limited to the
        platform source code, are strictly prohibited from modifying, derivative
        works, adapting, duplicating, copying, selling, reproducing, hacking,
        selling, and/or exploiting Gajiku Platform, the use of Gajiku Platform
        for unauthorized access, launching automatic programs or scripts, or any
        program that may hinder the operation and/or performance of Gajiku
        Platform, or in any way reproduce or avoid the navigation structure or
        presentation of Gajiku Platform or its contents. User is only allowed to
        use Gajiku Platform for non-commercial personal needs, non-transferable,
        and non-sublicensable. Gajiku has the full right to take all legal
        actions against User for any violation of Intellectual Property Rights
        related to Gajiku Platform. NOTICE All notifications or requests for
        information to and/or about the Gajiku Platform will be processed if
        made in writing and sent to Gajiku's address on Jl Mandor Demong No. 07
        Kel. Mustikasari, Kec. Mustika Jaya Bekasi 17157, or can be sent via
        electronic mail (e-mail) at nadia@gajikuapp.com, or contact our call
        center at +6281231747236. Any notification given by Gajiku will be
        deemed to have been received by User, if: Gajiku can show that the
        notification, both physically and electronically has been sent to User;
        or; Gajiku has made the notification on Gajiku Platform which can be
        accessed by public without being charged for any fees. DISPUTE
        RESOLUTION All disputes and differences of opinion that may arise in the
        implementation of this Terms and Conditions will be resolved amicably,
        and if the dispute that arise that cannot be resolved amicably, within
        30 (thirty) days after the dispute, User complaint dispute must be
        resolved first through a forum with Gajiku. In the event the settlement
        of User complaints cannot be resolved after 30 (thirty) days, User and
        Gajiku agree to resolve the dispute through the South Jakarta District
        Court. GOVERNING LAW This Terms and Conditions shall be governed by and
        construed in accordance with the laws of the Republic of Indonesia.
        FORCE MAJEURE Gajiku shall not be liable for any delay or failure to
        perform any of its obligations under this Terms and Conditions, if the
        delay or failure results from events or circumstances beyond its
        reasonable control, including but not limited to, incidents of fire,
        natural disasters (such as earthquakes, floods, hurricanes, lightning),
        mass strikes, riots, wars, changes to laws and regulations, and
        conditions in the economic, financial sector, damage to Gajiku
        Platform's transaction system, and disruption of the transaction
        settlement system on Gajiku Platform. MISCELLANEOUS User expressly agree
        that the provisions of article 1266 of Indonesian Civil Code that
        requiring approval or authorization of the court to terminate the Terms
        and Conditions shall not apply. Gajiku without prior notice have the
        right, to take any actions required in the case of fraud/manipulation,
        money laundering or violated committed by User and any violation made by
        User money will be processed under the applicable laws in Indonesia.
        Gajiku has the right to investigate and claim the right of Gajiku for
        any violation of this Terms and Conditions as long as it is possible and
        permitted by the applicable laws in Indonesia. User hereby acknowledges
        and agrees that Gajiku has the right to monitor every access to Gajiku
        Platform, to ensure that User comply with this Terms and Conditions, and
        comply with the applicable laws and regulations in Indonesia. This Terms
        and Conditions are made in Indonesian and English. If there is any
        inconsistency or difference in interpretation between Indonesian and
        English, the Indonesian version shall prevail. Hereby, Gajiku Platform
        User represents and warrants that all information provided by User to
        Gajiku is correct and accurate, in the event that User submits incorrect
        information, then Gajiku has the right to suspend, close User access to
        Gajiku Platform, close and/or cancel User's account on Gajiku Platform.
        Gajiku reserves all rights that not expressly granted herein. Gajiku may
        change this Terms and Conditions at any time by posting the revised
        Terms and Conditions on the Gajiku Platform. The use of Gajiku Platform
        by User after the change is posted shall be deemed as User acceptance of
        the revised Terms and Conditions. If User disagree with any of this
        Terms and Conditions, please do not access, browse or use Gajiku
        Platform. Last update of this Terms and Conditions: 4th November, 2020.
      </div>
    );
  };

  return (
    <div className="hp">
      <div className="container logo">
        <img src={Logo} alt="gajiku-logo" />
        <div className="title">Gajiku</div>
      </div>
      <div className="header">
        <small>Silakan baca dan setuju dengan T&Cs di bawah.</small>
      </div>
      <div className="container language-switch">
        <button
          className="bahasa"
          onClick={() => {
            setIsTncBahasa(true);
          }}
        >
          Bahasa
        </button>
        <button
          className="english"
          onClick={() => {
            setIsTncBahasa(false);
          }}
        >
          English
        </button>
      </div>
      <div className="container tnc">
        {!!isTncBahasa && <TncBahasa />}
        {!isTncBahasa && <TncEnglish />}
      </div>
      <div className="agreement">
        <input
          type="checkbox"
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
        />
        <div className="message">Saya setuju dengan T&Cs tersebut.</div>
      </div>
    </div>
  );
};
